import React from "react";
import { Carousel } from "react-bootstrap";
import SlideImgDesk from "../../img/slide-01.jpg";
import SlideImgMobile from "../../img/slide-01-mobile.jpg";
function TopBanner() {
  return (
    <Carousel
      id="carouselExampleIndicators"
      className="banner-wrapper"
      controls={false}
      pause='hover'
    >
      <Carousel.Item>
        <img
          className="d-block w-100 banner-desktop"
          src={SlideImgDesk}
          alt="The Prithvi"
        />
        <img
          className="d-block w-100 banner-mobile h-auto"
          src={SlideImgMobile}
          alt="The Prithvi"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default TopBanner;

import React, { memo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { checkValidVideoUrl, typeMapper } from "../../lib/helper";
import { shortsUrl } from "../../Constants/Config";
import { PiSmileySadLight } from "react-icons/pi";

const ShortsSearch = memo(({ rows, filter }) => {
  return (
    <div className="mb-4">
      <div
        className={` ${filter.type == 0 ? "auto-cols-max grid-flow-col overflow-x-auto" : "grid-col-1 searchsm:grid-cols-2 searchmd:grid-cols-3 searchlg:grid-cols-4 searchxl:grid-cols-5 search2xl:grid-cols-6 search3xl:grid-cols-7"} grid w-full gap-3`}
      >
        {rows.map((data, _) => {
          return (
            <div className="group mt-2 w-full rounded-md" key={_}>
              <ShortsComponent data={data} isInAllCategory={filter.type == 0} />
            </div>
          );
        })}
      </div>
    </div>
  );
});

const ShortsComponent = ({ data, isInAllCategory }) => {
  const [paused, setPaused] = useState(false);
  const videoRef = useRef(null);
  const [isValidVideo, setIsValidVideo] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPaused(false);
    } else {
      videoRef.current.pause();
      setPaused(true);
    }
  };

  checkValidVideoUrl(shortsUrl + data?.VideoUrl).then((isValid) => {
    setIsValidVideo(isValid);
    if (isValid) {
      console.log("Valid video URL");
      // Do something if the video URL is valid
    } else {
      console.log("Invalid video URL");
      // Handle the case where the video URL is invalid
    }
  });

  return (
    <div
      className={`${isInAllCategory ? " h-auto w-[200px]" : " h-full w-full"} aspect-shorts`}
    >
      {data?.VideoUrl && isValidVideo ? (
        <video
          ref={videoRef}
          src={`${shortsUrl}${data?.VideoUrl}`}
          //   autoPlay
          //   muted={muted}
          controls
          className="h-auto w-full rounded-lg"
          onClick={togglePlayPause}
        ></video>
      ) : (
        // there is no video url and this needs to be handled #pending
        <div className="flex h-full w-full items-center justify-center rounded-lg bg-zinc-100">
          <div className="flex flex-col items-center justify-center space-x-2">
            <PiSmileySadLight className="mb-4 block h-8 w-8" />
            <span className="text-[3vw] searchsm:text-xs">Aw, Snap!</span>
            <span className="text-wrap text-[3vw] searchsm:text-xs">
              This Content is not available
            </span>
          </div>
        </div>
      )}
      {/* <button onClick={() => setMuted((ref) => !ref)}>mute me</button> */}
    </div>
  );
};

export default ShortsSearch;
{
  /* <video src="https://prithvi.initstore.com/assets/Videos/ShortVideos/472.mp4? timeStamp=May  9 2024  8:16PM" autoplay="" class="h-auto w-full rounded-lg"></video> */
}
{
  /* <video src="https://prithvi.initstore.com/assets/Videos/ShortVideos/470.mp4? timeStamp=May  9 2024  8:15PM" autoplay="" class="h-auto w-full rounded-lg"></video> */
}

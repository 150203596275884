import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { MdClose, MdVolumeOff, MdVolumeUp } from "react-icons/md";
import { shortsUrl } from "../../Constants/Config";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
import { IoIosPlayCircle } from "react-icons/io";

const ShortsDemo = () => {
  const [paused, setPaused] = useState(false);
  let shorts = useSelector((state) => state.ShortsReducer.shorts) || [];
  const [current, setCurrent] = useState({});
  const [currentStatusId, setCurrentStatusIndex] = useState({
    index: 0,
    Id: shorts[0]?.Id,
  });
  const navigate = useNavigate();
  const handleBackButtonPress = () => {
    navigate("/user-home");
  };
  useHardwareBackButton(0, handleBackButtonPress);
  const [muted, setMuted] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPaused(false)
    } else {
      videoRef.current.pause();
      setPaused(true)
    }
  };

  useEffect(() => {
    if (shorts.length > 0) {
      setCurrentStatusIndex((ref) => ({
        ...ref,
        index: 0,
        Id: shorts[0].Id,
      }));
      setCurrent(shorts[0] ?? {});
    }
  }, [shorts]);

  useEffect(() => {
    setCurrent(shorts[currentStatusId.index]);
  }, [currentStatusId]);

  const previousShorts = () => {
    if (currentStatusId.index === 0) return;
    setCurrentStatusIndex((ref) => ({
      ...ref,
      index: ref.index - 1,
      Id: shorts[ref.index - 1].Id,
    }));
    setPaused(false)
    // setCurrent(shorts[currentStatusId.index - 1]);
  };

  const nextShorts = () => {
    if (currentStatusId.index === shorts.length - 1) {
      navigate("/user-home");
      return;
    }
    setCurrentStatusIndex((ref) => ({
      ...ref,
      index: ref.index + 1,
      Id: shorts[ref.index + 1].Id,
    }));
    setPaused(false)
    // setCurrent(shorts[currentStatusId.index + 1]);
  };

  const toggleMute = () => {
    setMuted((ref) => !ref);
  };

  const StatusBar = useCallback(() => {
    return (
      <div className="flex w-full space-x-1  py-2">
        {shorts.length > 0 ? (
          shorts?.map((el, _) => {
            console.log("current status id => ", currentStatusId.Id, el.Id);
            return (
              <div
                key={el.Id}
                onClick={() =>
                  setCurrentStatusIndex((ref) => ({
                    index: _,
                    Id: el.Id,
                  }))
                }
                className={twMerge(
                  `h-2.5 w-full rounded-full transition-all`,
                  `${
                    currentStatusId.Id === el.Id
                      ? "flex-1 bg-brown"
                      : "max-w-5 flex-auto cursor-pointer bg-black"
                  }`
                )}
              ></div>
            );
          })
        ) : (
          <div
            key={0}
            onClick={() => setCurrentStatusIndex({ index: 0, Id: 0 })}
            className={twMerge(
              `h-2.5 w-full min-w-[50px] rounded-full transition-all`,
              `${
                currentStatusId.Id == 0
                  ? "flex-auto bg-brown"
                  : "flex-1 cursor-pointer bg-black"
              }`
            )}
          ></div>
        )}
      </div>
    );
  }, [nextShorts, previousShorts, shorts]);

  return (
    <div className="mx-auto flex h-dvh w-full flex-col items-center sm:max-w-[420px]">
      {StatusBar()}
      <div className="relative mb-1 w-full flex-auto rounded-md bg-zinc-700/20">
          
          {paused ? <IoIosPlayCircle className="h-28 w-28 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-darkBrown rounded-full bg-white" /> : null}
          
        <div
          className={`absolute left-2 top-2 z-50 cursor-pointer rounded-full bg-darkBrown`}
          onClick={() => navigate("/user-home")}
        >
          <MdClose className={`h-8 w-8 text-white`} />
        </div>
        <div
          className={`absolute left-0 top-0 z-40 h-full p-10`}
          onClick={() => previousShorts()}
        ></div>
        <div className="flex h-full w-full items-center justify-center ">
          {current?.VideoUrl ? (
            <video
              ref={videoRef}
              src={`${shortsUrl}${current?.VideoUrl}`}
              autoPlay
              muted={muted}
              className="h-dvh w-auto bg-contain"
              onClick={togglePlayPause}
              onEnded={nextShorts}
            ></video>
          ) : (
            // there is no video url and this needs to be handled #pending
            <span>Something went wrong!!!</span>
          )}
        </div>
        {/* Mute Button */}
        <div
          className={`absolute right-2 top-2 z-50 cursor-pointer rounded-full bg-darkBrown`}
          onClick={toggleMute}
        >
          {muted ? (
            <MdVolumeOff className={`h-8 w-8 text-white`}/>
          ) : (
            <MdVolumeUp className={`h-8 w-8 text-white`} />
          )}
        </div>
        <div
          className={`absolute right-0 top-0 z-40 h-full p-10`}
          onClick={() => nextShorts()}
        ></div>
      </div>
    </div>
  );
};

export default ShortsDemo;

import React from "react";
import privacySvg from "../../img/privacy_policy.svg";
import { useNavigate } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const Privacy = () => {
  const redirect = useNavigate();
  const handleBackButtonPress = () => {
    redirect("/user-home");
  };
  useHardwareBackButton(0, handleBackButtonPress);
  return (
    <div className="flex h-screen flex-col">
      <div className="relative w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="container relative mx-auto flex h-screen flex-1  overflow-hidden px-4 pb-14 md:pb-0">
        <div
          className={
            "sticky top-[60px] hidden w-full space-y-1 pt-5 md:block md:max-w-[250px] lg:max-w-[300px]"
          }
        >
          <h1 className="mb-4 text-xl font-semibold lg:text-2xl">
            Privacy Policy
          </h1>
          <a className="block list-none " href={"#Interpretation"}>
            Interpretation
          </a>
          <a className="block list-none " href={"#Definitions"}>
            Definitions
          </a>
          <a
            className="block list-none "
            href={"#Collecting_and_Using_Your_Personal_Data"}
          >
            Collecting and Using Your Personal Data
          </a>
          <a className="block list-none " href={"#Types_of_Data_Collected"}>
            Types of Data Collected
          </a>
          <a className="block list-none " href={"#Personal_Data"}>
            Personal Data
          </a>
          <a className="block list-none " href={"#Usage_Data"}>
            Usage Data
          </a>
          <a className="block list-none " href={"#Use_of_Your_Personal_Data"}>
            Use of Your Personal Data
          </a>
          <a
            className="block list-none "
            href={"#Retention_of_Your_Personal_Data"}
          >
            Retention of Your Personal Data
          </a>
          <a
            className="block list-none "
            href={"#Transfer_of_Your_Personal_Data"}
          >
            Transfer of Your Personal Data
          </a>
          <a className="block list-none " href={"#Delete_Your_Personal_Data"}>
            Delete Your Personal Data
          </a>
          <a
            className="block list-none "
            href={"#Disclosure_of_Your_Personal_Data"}
          >
            Disclosure of Your Personal Data
          </a>
          <a className="block list-none " href={"#Business_Transactions"}>
            Business Transactions
          </a>
          <a className="block list-none " href={"#Law_enforcement"}>
            Law enforcement
          </a>
          <a className="block list-none " href={"#Other_legal_requirements"}>
            Other legal requirements
          </a>
          <a
            className="block list-none "
            href={"#Security_of_Your_Personal_Data"}
          >
            Security of Your Personal Data
          </a>
          <a className="block list-none " href={"#Childrens_Privacy"}>
            Children's Privacy
          </a>

          <a className="block list-none " href={"#Links_to_Other_Websites"}>
            Links to Other Websites
          </a>
          <a
            className="block list-none "
            href={"#Changes_to_this_Privacy_Policy"}
          >
            Changes to this Privacy Policy
          </a>
          <a className="block list-none " href={"#Contact_Us"}>
            Contact Us
          </a>
        </div>
        <div className="w-full overflow-y-auto overscroll-none scrollbar-hide">
          <img
            src={privacySvg}
            alt={"terms and conditions"}
            className="mx-auto h-auto w-full max-w-[600px]"
          />
          <div className="mb-8">
            <h1
              id={"Interpretation"}
              className="mb-3 text-4xl font-semibold tracking-tight"
            >
              Interpretation
            </h1>
            <p className="leading-7 tracking-wide">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
          </div>

          <div className="mb-8">
            <h1
              id={"Definitions"}
              className="mb-3 text-4xl font-semibold tracking-tight"
            >
              Definitions
            </h1>
            <p className="mb-10 leading-7 tracking-wide">
              For the purposes of this Privacy Policy:
            </p>
            <ul className="ml-1 space-y-2">
              <li className={"list-disc"}>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </li>
              <li className={"list-disc"}>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </li>
              <li className={"list-disc"}>
                <strong>Application</strong> refers to Prithvi, the software
                program provided by the Company.
              </li>
              <li className={"list-disc"}>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Athulyam,
                Health camp, Gudalur.
              </li>
              <li className={"list-disc"}>
                <strong>Country</strong> refers to: Tamil Nadu, India
              </li>
              <li className={"list-disc"}>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li className={"list-disc"}>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </li>
              <li className={"list-disc"}>
                <strong>Service</strong> refers to the Application.
              </li>
              <li className={"list-disc"}>
                <strong>Service Provider</strong>
                means any natural or legal person who processes the data on
                behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service,
                to provide the Service on behalf of the Company, to perform
                services related to the Service or to assist the Company in
                analyzing how the Service is used.
              </li>
              <li className={"list-disc"}>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
              <li className={"list-disc"}>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <h1
              id={"Collecting_and_Using_Your_Personal_Data"}
              className="mb-3 text-4xl font-semibold tracking-tight"
            >
              Collecting and Using Your Personal Data
            </h1>
            <h1
              id={"Types_of_Data_Collected"}
              className="mb-3 text-2xl font-semibold tracking-tight"
            >
              Types of Data Collected
            </h1>
            <h1
              id={"Personal_Data"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Personal Data
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul className="ml-1 space-y-2">
              <li className={"list-none"}>
                <strong>Email address</strong>
              </li>
              <li className={"list-none"}>
                <strong>First name and last name</strong>
              </li>
              <li className={"list-none"}>
                <strong>Phone number</strong>
              </li>
              <li className={"list-none"}>
                <strong>Usage Data</strong>
              </li>
            </ul>

            <h1
              id={"Usage_Data"}
              className="mb-10 text-xl font-semibold tracking-tight"
            >
              Usage Data
            </h1>
            <p className="mb-3 leading-7 tracking-wide">
              Usage Data is collected automatically when using the Service.
            </p>
            <p className="mb-3 leading-7 tracking-wide">
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>

            <p className="mb-3 leading-7 tracking-wide">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>

            <p className="mb-10 leading-7 tracking-wide">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>

            <h1
              id={"Use_of_Your_Personal_Data"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Use of Your Personal Data
            </h1>

            <p className="mb-10 leading-7 tracking-wide">
              The Company may use Personal Data for the following purposes:
            </p>
            <ul className="ml-1 space-y-2 ">
              <li className={"list-disc"}>
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </li>
              <li className={"list-disc"}>
                <strong>To manage Your Account</strong>: to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </li>
              <li className={"list-disc"}>
                <strong>For the performance of a contract</strong>: the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </li>

              <li className={"list-disc"}>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </li>

              <li className={"list-disc"}>
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </li>

              <li className={"list-disc"}>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </li>

              <li className={"list-disc"}>
                <strong>For business transfers:</strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </li>

              <li className={"list-disc"}>
                <strong>For other purposes:</strong> We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </li>
            </ul>

            <p className="mb-10 leading-7 tracking-wide">
              We may share Your personal information in the following
              situations:
            </p>

            <ul className="ml-1 space-y-2 ">
              <li className={"list-disc"}>
                <strong>With Service Providers:</strong> We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You.
              </li>
              <li className={"list-disc"}>
                <strong>For business transfers:</strong> We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of Our business to another
                company.
              </li>
              <li className={"list-disc"}>
                <strong>With Affiliates:</strong> We may share Your information
                with Our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include Our
                parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under
                common control with Us.
              </li>
              <li className={"list-disc"}>
                <strong>With business partners:</strong> We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </li>
              <li className={"list-disc"}>
                <strong>With other users:</strong> when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside.
              </li>
              <li className={"list-disc"}>
                <strong>With Your consent:</strong> We may disclose Your
                personal information for any other purpose with Your consent.
              </li>
            </ul>

            <h1
              id={"Retention_of_Your_Personal_Data"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Retention of Your Personal Data
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>

            <p className="mb-10 leading-7 tracking-wide">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>

            <h1
              id={"Transfer_of_Your_Personal_Data"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Transfer of Your Personal Data
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>

            <p className="mb-3 leading-7 tracking-wide">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p className="mb-10 leading-7 tracking-wide">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>

            <h1
              id={"Delete_Your_Personal_Data"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Delete Your Personal Data
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </p>

            <p className="mb-3 leading-7 tracking-wide">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </p>

            <p className="mb-3 leading-7 tracking-wide">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </p>

            <p className="mb-10 leading-7 tracking-wide">
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>

            <h1
              id={"Disclosure_of_Your_Personal_Data"}
              className="mb-3 text-2xl font-semibold tracking-tight"
            >
              Disclosure of Your Personal Data
            </h1>

            <h1
              id={"Business_Transactions"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Business Transactions
            </h1>

            <p className="mb-10 leading-7 tracking-wide">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>

            <h1
              id={"Law_enforcement"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Law enforcement
            </h1>

            <p className="mb-10 leading-7 tracking-wide">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>

            <h1
              id={"Other_legal_requirements"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Other legal requirements
            </h1>

            <p className="mb-10 leading-7 tracking-wide">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>

            <ul className="ml-1 space-y-2 ">
              <li className={"list-disc"}>Comply with a legal obligation</li>
              <li className={"list-disc"}>
                Protect and defend the rights or property of the Company
              </li>
              <li className={"list-disc"}>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li className={"list-disc"}>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li className={"list-disc"}>Protect against legal liability</li>
            </ul>

            <h1
              id={"Security_of_Your_Personal_Data"}
              className="mb-3 text-xl font-semibold tracking-tight"
            >
              Security of Your Personal Data
            </h1>

            <p className="mb-10 leading-7 tracking-wide">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
          </div>
          <div className="mb-8">
            <h1
              id={"Childrens_Privacy"}
              className="mb-3 text-2xl font-semibold tracking-tight"
            >
              Children's Privacy
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </p>
            <p className="mb-10 leading-7 tracking-wide">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
          </div>
          <div className="mb-8">
            <h1
              id={"Links_to_Other_Websites"}
              className="mb-3 text-2xl font-semibold tracking-tight"
            >
              Links to Other Websites
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p className="mb-10 leading-7 tracking-wide">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </div>
          <div className="mb-8">
            <h1
              id={"Changes_to_this_Privacy_Policy"}
              className="mb-3 text-2xl font-semibold tracking-tight"
            >
              Changes to this Privacy Policy
            </h1>

            <p className="mb-3 leading-7 tracking-wide">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="mb-3 leading-7 tracking-wide">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </p>
            <p className="mb-10 leading-7 tracking-wide">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>

          <div className="mb-8">
            <h1
              id={"Contact_Us"}
              className="mb-3 text-2xl font-semibold tracking-tight"
            >
              Contact Us
            </h1>

            <p className="mb-10 leading-7 tracking-wide">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>

            <ul className="ml-1 space-y-2 ">
              <li className={"list-disc"}>By email: psathulyam@gmail.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sticky bottom-0">
        <CombinedFooter />
      </div>
    </div>
  );
};

export default Privacy;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSession, getSession } from "../../lib/session";
import {
  CheckMultiLogin,
  CheckMultiLoginError,
  GetUserError,
  LogoutMultiUser,
} from "../../Redux/Authentication/action";
import DesktopScreen from "./DesktopScreen";
import MobileHomeScreen from "./MobileHomeScreen";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div>{isMobile ? <MobileHomeScreen /> : <DesktopScreen />}</div>;
}

export default HomePage;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../Constants/Config";
import toast from "react-hot-toast";
import { ImSpinner2 } from "react-icons/im";
import { useNavigate, useSearchParams } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import { zodResolver } from "@hookform/resolvers/zod";
import { contactFormSchema } from "../../lib/schema";
import { twMerge as tw } from "tailwind-merge";
import { MdOutlineErrorOutline } from "react-icons/md";
import { getSession } from "../../lib/session";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const Contactus = () => {
  const [spinner, setSpinner] = useState(false);
  const redirect = useNavigate();
  const handleBackButtonPress=()=>{
    redirect('/user-home')
  }
  useHardwareBackButton(0,handleBackButtonPress) 
  const session = getSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      name: session.name ?? "",
      email: session.email ?? "",
      message: "",
      purpose:searchParams.get("Purpose") ? searchParams.get("Purpose") : ""
    },
  });

  const onSubmit = async (data) => {
    // console.log("data => ", data);
    setSpinner(true);

    try {
      const res = fetch(`${ApiUrl}ContactUs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          purpose: data.purpose,
          Name: data.name,
          Email: data.email,
          Sub: data.message,
          UserId:session.id
        }),
      });

      toast.promise(
        res,
        {
          loading: "Sending your message...",
          success: (succData) => {
            console.log('succData => ', succData)
            if (succData !== null) return "Someone will reach back to you...";
            return "Again....!";
          },

          error: (err) => `Something went wrong!`,
        },
        {
          style: {
            minWidth: "250px",
          },
        },
      );

      const isSent = await res;
      // console.log("contact => ", isSent);

      if (isSent.ok) {
        if(window){
          window.history.pushState({}, '', '/Contact-Us');
          searchParams.delete('Purpose')
        }
        reset({
          purpose: '',
          message:''
        });
      }
    } catch (error) {
      console.log("error caught => ", error);
      toast.error("Something went wrong!!!");
    } finally {
      setSpinner(false);
    }
  };
  const purposeOptions = [
    { value: "Enquiry", label: "Enquiry" },
    { value: "Feedback", label: "Feedback" },
    { value:"Suggestions", label: "Suggestions" },
    { value: "Answer for Puzzle", label: "Answer for Puzzle" },
    { value: "Purchase Premium", label: "Purchase Premium" },
  ];
  return (
    <div className="flex h-full min-h-screen flex-col">
      <div className="w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="mb-12 flex flex-1 items-center justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="min-w-screen mx-auto w-full space-y-3 rounded-md p-4 shadow-md sm:max-w-[600px]"
        >
          <div>
            <h1 className="text-center text-4xl font-bold capitalize">
              contact us
            </h1>
          </div>
          {/* Dropdown for purpose */}
          <div className="flex flex-col items-start space-y-2 font-medium">
            <label htmlFor="purpose">Purpose</label>
            <select
              id="purpose"
              className="w-full rounded-md border p-2"
              {...register("purpose")}
            >
              <option value='' disabled selected>
                Select Purpose
              </option>
              {purposeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col items-start space-y-2 font-medium">
            <label htmlFor="name">Name</label>

            <div className="w-full">
              <div className="relative h-full">
                <input
                  type="text"
                  id="name"
                  className={tw(
                    `titext !w-full`,
                    errors.name ? "titext-error" : "",
                  )}
                  placeholder="John Deo"
                  {...register("name")}
                />
                {errors.name && (
                  <MdOutlineErrorOutline
                    className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                  />
                )}
              </div>
              {errors.name && (
                <span className="text-sm font-medium text-red-500">
                  {errors.name.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start space-y-2 font-medium">
            <label htmlFor="email">E-Mail</label>

            <div className="w-full">
              <div className="relative h-full">
                <input
                  type="text"
                  id="email"
                  className={tw(
                    `titext !w-full`,
                    errors.email ? "titext-error" : "",
                  )}
                  placeholder="example@domain.com"
                  {...register("email")}
                />
                {errors.email && (
                  <MdOutlineErrorOutline
                    className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                  />
                )}
              </div>
              {errors.email && (
                <span className="text-sm font-medium text-red-500">
                  {errors.email.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start space-y-2 font-medium">
            <label htmlFor="message">Message</label>

            <div className="w-full">
              <div className="relative h-full">
                <textarea
                  rows={5}
                  id="message"
                  className={tw(
                    `titext !w-full`,
                    errors.message ? "titext-error" : "",
                  )}
                  placeholder="How can we help?"
                  {...register("message")}
                />
                {errors.message && (
                  <MdOutlineErrorOutline
                    className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                  />
                )}
              </div>
              {errors.message && (
                <span className="text-sm font-medium text-red-500">
                  {errors.message.message}
                </span>
              )}
            </div>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="default-btn flex items-center space-x-2"
            >
              <span>{spinner ? "Sending..." : "Send Message"}</span>
              <ImSpinner2
                className={`${spinner ? "block animate-spin" : "hidden"}`}
              />
            </button>
          </div>
        </form>
      </div>
      <CombinedFooter />
    </div>
  );
};

export default Contactus;

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DrugImgUrl } from "../../Constants/Config";
import { GetDrugWithId } from "../../Redux/UserHome/action";
import CombinedFooter from "../Footer/CombinedFooter";

function SingleDrug() {
  const navigate = useNavigate();
  const { Id } = useParams();
  const dispatch = useDispatch();
  const SingleDrugData = useSelector(
    (state) => state.UserHomeReducer.GetDrugWithId,
  );
  useEffect(() => {
    if (Id) {
      dispatch(
        GetDrugWithId({
          DrugId: Id,
          Dummy: 5,
        }),
      );
    }
  }, [Id]);
  return (
    <div className="EditModal">
      <div className="Article-Modal ContentDisplayPage">
        <div className="home-slider">
          <div className="page-header-con">
            <button className="backbtn" onClick={() => navigate("/drug")}>
              <i className="fas fa-chevron-left"></i>
            </button>
            {/* <button className="menubtn">
            <i className="fa-regular fa-copy"></i>
            </button> */}
          </div>
        </div>
        <h2 className="page-heading">{SingleDrugData?.Heading}</h2>
        <div className="DisplayImage container">
          <img
            className="d-block "
            alt="The Prithvi"
            src={DrugImgUrl + SingleDrugData?.ImageUrl}
          />
        </div>
        <div className="service-single container">
          <p
            dangerouslySetInnerHTML={{
              __html: SingleDrugData?.Description,
            }}
            className="service-single-ptag"
          />
        </div>
      </div>
      <CombinedFooter />
    </div>
  );
}

export default SingleDrug;

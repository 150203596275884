import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
	GetCountryError,
	GetCountrySuccess,
	GetStateError,
	GetStateSuccess,
	GetUserError,
	GetUserProfileError,
	GetUserProfileSuccess,
	GetUserSuccess,
	RegisterUserError,
	RegisterUserSuccess,
	actionTypes,
	RestrictMultiLoginSuccess,
	RestrictMultiLoginError,
	CheckMultiLoginSuccess,
	CheckMultiLoginError,
	LogoutMultiUserSuccess,
	LogoutMultiUserError,
} from './action';
import AuthenticationService from '../../Services/AuthenticationService';
import { startLoader, stopLoader } from '../Common/Loader/action';
import { startSpinner, stopSpinner } from '../Common/spinner/action';
function* GetUsers({ payload }) {
	try {
		yield put(startSpinner());
		const data = yield call(AuthenticationService.GetUsers, payload);
		yield put(GetUserSuccess(data));
	} catch (error) {
		yield put(GetUserError(error));
		console.log(error);
	} finally {
		yield put(stopSpinner());
	}
}

function* RegisterUser({ payload }) {
	try {
		yield put(startSpinner());
		const data = yield call(AuthenticationService.RegisterUser, payload);
		yield put(RegisterUserSuccess(data));
	} catch (error) {
		yield put(RegisterUserError(error));
	} finally {
		yield put(stopSpinner());
	}
}

function* GetStates({ payload }) {
	try {
		yield put(startSpinner());
		const data = yield call(AuthenticationService.GetState, payload);
		yield put(GetStateSuccess(data));
	} catch (error) {
		yield put(GetStateError(error));
	} finally {
		yield put(stopSpinner());
	}
}

function* GetCountries({ payload }) {
	try {
		yield put(startSpinner());
		const data = yield call(AuthenticationService.GetCountry, payload);
		yield put(GetCountrySuccess(data));
	} catch (error) {
		yield put(GetCountryError(error));
	} finally {
		yield put(stopSpinner());
	}
}

function* GetUserProfile({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(AuthenticationService.GetUserProfile, payload);
		yield put(GetUserProfileSuccess(data));
	} catch (error) {
		yield put(GetUserProfileError(error));
	} finally {
		yield put(stopLoader());
	}
}

function* RestrictMultiLogin({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(AuthenticationService.RestrictMultiLogin, payload);
		yield put(RestrictMultiLoginSuccess(data));
	} catch (error) {
		yield put(RestrictMultiLoginError(error));
	} finally {
		yield put(stopLoader());
	}
}

function* CheckMultiLogin({ payload }) {
	try {
		const data = yield call(AuthenticationService.CheckMultiLogin, payload);
		yield put(CheckMultiLoginSuccess(data));
	} catch (error) {
		yield put(CheckMultiLoginError(error));
	} 
}

function* LogoutMultiUser({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(AuthenticationService.LogoutMultiUser, payload);
		yield put(LogoutMultiUserSuccess(data));
	} catch (error) {
		yield put(LogoutMultiUserError(error));
	} finally {
		yield put(stopLoader());
	}
}



export default function* rootSaga() {
	yield all([takeEvery(actionTypes.GET_USER, GetUsers)]);
	yield all([takeEvery(actionTypes.POST_USER, RegisterUser)]);
	yield all([takeEvery(actionTypes.GET_STATE, GetStates)]);
	yield all([takeEvery(actionTypes.GET_COUNTRY, GetCountries)]);
	yield all([takeEvery(actionTypes.GET_USER_PROFILE, GetUserProfile)]);
	yield all([takeEvery(actionTypes.RESTRICT_MULTI_LOGIN, RestrictMultiLogin)]);
	yield all([takeEvery(actionTypes.CHECK_MULTI_LOGIN, CheckMultiLogin)]);
	yield all([takeEvery(actionTypes.LOGOUT_MULTI_USER, LogoutMultiUser)]);
}

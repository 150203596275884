// ** Redux Imports
import { combineReducers } from 'redux';
import UserHomeReducer from '../UserHome/reducer';
import Common from '../Common/commonReducer';
import { AuthenticationReducer } from '../Authentication/reducer';
import { ChatReducer } from '../Chat/reducer';
import { ShortsReducer } from '../shorts/reducer';
const rootReducer = combineReducers({
	UserHomeReducer,
	AuthenticationReducer,
	Common,
	ChatReducer,
	ShortsReducer,
});

export default rootReducer;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { MdWorkspacePremium } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ArticleImgUrl } from "../../Constants/Config";
import { getSession } from "../../lib/session";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
function ImageSlide(props) {
  const session = getSession();
  const navigate = useNavigate();
  const settings = {
    centerMode: false,
    centerPadding: "0px",
    slidesToShow: 4,
    arrows: false,
    dots: true,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };

  //Show Image modal
  const [index, setIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const filteredArticles = session.isPremiumUser
    ? props.ImageData
    : props.ImageData?.filter((data) => data.IsPremium !== 1);

  const images = filteredArticles?.map((data) => ({
    src: ArticleImgUrl + data.ImageUrl,
    Id: data.Id,
    Heading: data.Heading,
    Description: data.Description,
  }));

  const handleClose = () => {
    setIsOpen(false);
    setIndex(-1);
  };

  const handleClick = (i) => {
    setIsOpen(true);
    setIndex(i);
  };

  const ImageClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      const imageIndex = images?.findIndex((image) => image.Id === Data.Id);
      handleClick(imageIndex);
    } else {
      toast.error("Please purchase a package");
    }
  };
  
  const ReplyButtonClick=()=>{
    navigate(`/Contact-Us?Purpose=${"Answer for Puzzle"}`)
  }
  return (
    <>
      {props.ImageData?.length > 0 && (
        <div className="image-slider-wrap">
          <div className="container">
            <div className="row" style={{ clear: "both", display: "flex" }}>
              <div
                className="col-lg-10 col-md-8 col-sm-12"
                onClick={() => navigate(`/Group/${props.GroupData.Id}/Type/3`)}
              >
                <h2 className="Group-Heading">{props.GroupData.Heading}</h2>
              </div>
              {props.ImageData?.length > 4 && (
                <div className="col-lg-2 col-md-4 ">
                  <button
                    className="viewall-btn"
                    onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/3`)
                    }
                  >
                    View All
                  </button>
                </div>
              )}
            </div>
            <Slider {...settings}>
              {props.ImageData?.map((data, index) => (
                <button
                  className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                  key={index}
                  onClick={() => ImageClick(data)}
                  style={{ position: "relative" }}
                >
                  <span className="premium-icon-span">
                    <img
                      src={ArticleImgUrl + data.ImageUrl}
                      alt="prithvi"
                      style={{ height: "200px", width: "100%" }}
                    />
                    {data.Description && <h6>{data.Heading}</h6>}
                  </span>
                  <div className="videocontent">
                    {data.Description ? (
                      <p
                        className="line-clamp-5"
                        style={{ textAlign: "justify" }}
                      >
                        {data.Description}
                      </p>
                    ) : (
                      <h5>{data.Heading}</h5>
                    )}
                  </div>
                  <div className="reply-btn" onClick={ReplyButtonClick}>
                    <button>Reply</button>
                  </div>
                </button>
              ))}
            </Slider>
          </div>
        </div>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={images[index].src}
          nextSrc={images[(index + 1) % images.length].src}
          prevSrc={images[(index + images.length - 1) % images.length].src}
          onCloseRequest={handleClose}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 2000,
            },
            closeButton: {
              left: "10px",
              right: "initial",
            },
          }}
          imageTitle={images[index].Heading}
          imageCaption={images[index].Description}
        />
      )}
    </>
  );
}

export default ImageSlide;

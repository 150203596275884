import React from "react";
import PrithviFooterImg from "../../img/prithvi-footer.jpg";
function FooterTopWrapper() {
  return (
    <div className="footer-top-wrapper" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <img src={PrithviFooterImg} alt="Prithvi Logo" />
          </div>
          <div className="col-lg-3 col-md-3 footerlinks">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="/Forum">Forum</a>
              </li>
              <li>
                <a href="/Chat">AskGuru</a>
              </li>
              <li>
                <a href="/Packages">Premium</a>
              </li>
              <li>
                <a href="/Drug">Drug Database</a>
              </li>
              <li>
                <a href="/legal/terms">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="/legal/privacy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 footerlinks">
            <h4>Services</h4>
            <ul>
              <li>
                <a href="/Type/1">Articles</a>
              </li>
              <li>
                <a href="/Type/2">Videos</a>
              </li>
              <li>
                <a href="/Type/3">Puzzles</a>
              </li>
              <li>
                <a href="/Type/4">EBook</a>
              </li>
              <li>
                <a href="/Type/5">Posters</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3">
            <h4>Get in Touch</h4>
            <p>Address Line1, Address Line2, Location, Country 000000</p>
            <h6>
              <i className="fas fa-phone" /> <a href="">+9876543210</a>
            </h6>
            <h6>
              <i className="far fa-envelope" /> <a href="">info@yourmail.com</a>
            </h6>
            {/* #to #be #reviewed */}
            {/* <ul className="socialmedia-links">
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterTopWrapper;

// ** Redux Imports
import { combineReducers } from 'redux';

import Loader from './Loader/reducer';
import spinner from './spinner/reducer';
// import pickLocation from './pickLo/cation/reducer'

const commonReducer = combineReducers({
	Loader,
	spinner,
	// pickLocation
});

export default commonReducer;

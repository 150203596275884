import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GuruPng from "../../img/guru.png";
import { htmlToPlainText } from "../../lib/helper";
import { getSession, mutateSession } from "../../lib/session";
import {
  getAskguruUsers,
  getMessagesForAskguru,
  getMessagesForUsers,
  sendMessage,
} from "../../Redux/Chat/action";
import { Close } from "@mui/icons-material";
import toast from "react-hot-toast";
import { ApiUrl, GuruImageUrl } from "../../Constants/Config";
import { useSearchParams } from "react-router-dom";
function GuruList(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const session = getSession();
  const askgurus = useSelector((state) => state.ChatReducer.askguruUsers);
  const chatinfo = useSelector((state) => state.ChatReducer.createChat);

  const UnContactedAskgurus = askgurus?.filter(
    (objA) => !props.UseMessages?.some((objB) => objA.Id === objB.ReceipientId),
  );
  const ContactedAskgurus = askgurus?.filter((objA) =>
    props.UseMessages?.some((objB) => objA.Id === objB.ReceipientId),
  );
  useEffect(() => {
    dispatch(getAskguruUsers({ Id: session.id }));
  }, []);
  useEffect(() => {
    if (chatinfo && chatinfo.length > 0 && searchParams.get("message")) {
      dispatch(
        sendMessage({
          chatid: chatinfo[0].Id,
          message: searchParams.get("message"),
          sendby: session.id,
        }),
      );
    }
  }, [chatinfo, dispatch, session.id]);

  const handleCreateChat = async (id, UserName, isContacted) => {
    // dispatch(createChat({ uid: session.id, gid: id }));
    if (session.chats && session.chats > 0) {
      const response = await fetch(
        `${ApiUrl}Chat?uid=${session.id}&gid=${id}`,
        {
          method: "POST",
        },
      ).then((res) => {
        return res.json();
      });

      // console.log("response from creating a chat => ", response);
      if (response[0]?.ERROR === 0 && response[0]?.Id) {
        if (isContacted === "uncontacted" && !searchParams.get("message")) {
          dispatch(
            sendMessage({
              chatid: response[0]?.Id,
              message: "Start!",
              sendby: session.id,
            }),
          );
        }

        props.setSelectedProfile({
          Id: response[0]?.Id,
          UserName: UserName,
        });
      }

      if (response[0]?.ERROR !== 0) {
        toast.error("Cannot create a chat...");
        return;
      }

      // api endpoint to be updated #pendingChange
      if (searchParams.get("message")) {
        const createdMessage = await fetch(
          `${ApiUrl}ChatMessage?chatid=${response[0]?.Id}&message=${searchParams.get("message")}&sendby=${session.id}`,
          {
            method: "POST",
          },
        );
        // console.log("createdMessage => ", createdMessage);
        if (window) {
          window.history.pushState({}, "", "/chat");
          searchParams.delete("message");
        }
      }
    } else {
      toast.error(
        "You cannot create more chats. Purchase another premium plan?",
      );
    }
    // api endpoint to be updated #pendingChange
    const res = await fetch(`${ApiUrl}Users?User=${session.id}`).then((res) =>
      res.json(),
    );
    console.log("res => ", res);
    mutateSession({ chats: res[0]?.RemainingChats });

    console.log("response from getting chat nos => ", res);

    closeContactsList();
  };

  const closeContactsList = () => {
    if (session.isGuru) {
      dispatch(getMessagesForAskguru({ guruid: session?.guruId }));
    } else {
      dispatch(getMessagesForUsers({ userid: session?.id }));
    }

    props.HandleGuruListClose();
  };
  return (
    <Modal show={props.IsGuruList} onHide={props.HandleGuruListClose}>
      <Modal.Body>
        <section>
          <div className="flex items-center justify-between px-4 py-3">
            <h1 className="font-semibold capitalize text-zinc-500 ">
              Choose Guru
            </h1>
            <button onClick={() => props.HandleGuruListClose()}>
              <Close />
            </button>
          </div>
          <div className="divide-y">
            <h1 className="text-center text-xl font-semibold">Uncontacted</h1>
            {UnContactedAskgurus.length ? (
              UnContactedAskgurus?.map((chat) => (
                <div
                  className="grid grid-cols-[3rem_1fr] items-center gap-3 px-3 py-3 hover:bg-gray-200"
                  key={chat.Id}
                  onClick={() =>
                    handleCreateChat(chat.Id, chat.Name, "uncontacted")
                  }
                >
                  <img
                    className="h-12 w-12 rounded-full bg-gray-800 object-cover"
                    src={chat.ImageUrl ? GuruImageUrl + chat.ImageUrl : GuruPng}
                    alt={GuruPng}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                  <div>
                    <h4 className="flex justify-between text-base font-medium text-gray-800">
                      <span>{chat.Name}</span>
                    </h4>

                    <p className="flex justify-between text-sm text-black/60">
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          maxWidth: "100%",
                        }}
                      >
                        {htmlToPlainText(chat.Description)}
                      </span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex h-full w-full flex-col items-center justify-center p-3 font-sans font-semibold ">
                <div className="flex-auto">No gurus found!</div>
              </div>
            )}
          </div>
          <div className="divide-y">
            <h1 className="text-center text-xl font-semibold">Contacted</h1>
            {ContactedAskgurus.length ? (
              ContactedAskgurus?.map((chat) => (
                <div
                  className="grid grid-cols-[3rem_1fr] items-center gap-3 px-3 py-3 hover:bg-gray-200"
                  key={chat.Id}
                  onClick={() =>
                    handleCreateChat(chat.Id, chat.Name, "contacted")
                  }
                >
                  <img
                    className="h-12 w-12 rounded-full bg-gray-800 object-cover"
                    src={chat.ImageUrl ? GuruImageUrl + chat.ImageUrl : GuruPng}
                    alt={GuruPng}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                  <div>
                    <h4 className="flex justify-between text-base font-medium text-gray-800">
                      <span>{chat.Name}</span>
                    </h4>

                    <p className="flex justify-between text-sm text-black/60">
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          maxWidth: "100%",
                        }}
                      >
                        {htmlToPlainText(chat.Description)}
                      </span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex h-full w-full flex-col items-center justify-center p-3 font-sans font-semibold ">
                <div className="flex-auto">No gurus found!</div>
              </div>
            )}
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default GuruList;

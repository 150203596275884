import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { WebinarImgUrl } from "../../Constants/Config";
import CombinedFooter from "../Footer/CombinedFooter";
import "../Style/ArticleModal.css";
import "../Style/WebinarDispalyModal.css";
function WebinarDisplayModal(props) {
  const BackbtnClick = () => {
    props.CloseWebinarModal();
  };
  const LinkClick = (linkToRedirect) => {
    // Open the link in a new tab
    window.open(linkToRedirect, "_blank");
  };
  //copy text
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    const inputField = document.getElementById("credentialInput");

    if (inputField) {
      inputField.select();
      document.execCommand("copy");
      setIsCopied(true);

      // Reset the copy state after a brief delay
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="copied-tooltip" {...props}>
      Copied!
    </Tooltip>
  );
  return (
    <Modal show={props.ShowWebinarModal} fullscreen className="EditModal">
      <Modal.Body style={{ padding: "0px" }}>
        <div
          className="Article-Modal"
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div style={{ flex: "1 1 0%" }}>
            <div className="home-slider">
              <div className="page-header-con">
                <button className="backbtn" onClick={BackbtnClick}>
                  <i className="fas fa-chevron-left"></i>
                </button>
              </div>
            </div>
            <h2 className="page-heading">{props.SelectedWebinarData.Name}</h2>
            <div className="DisplayImage container">
              <img
                className="d-block "
                src={WebinarImgUrl + props.SelectedWebinarData.Image}
                alt="The Prithvi"
              />
            </div>
            {props.SelectedWebinarData?.Credential && (
              <div className="d-flex justify-content-center container mt-3">
                <div className="copy-to-clipboard-container">
                  <input
                    id="credentialInput"
                    type="text"
                    value={props.SelectedWebinarData.Credential}
                    readOnly
                    placeholder="Enter text to copy"
                  />
                  <OverlayTrigger
                    placement="right"
                    overlay={renderTooltip}
                    show={isCopied}
                  >
                    <button onClick={handleCopyClick}>
                      <i className="fa-solid fa-copy"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-center container mt-3">
              <button
                className="Join-button-webinar"
                onClick={() => LinkClick(props.SelectedWebinarData.Link)}
              >
                Join/Register
              </button>
            </div>

            <div
              className="service-single container"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: props.SelectedWebinarData.Description,
                }}
                className="service-single-ptag"
              />
            </div>
          </div>
          <CombinedFooter />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WebinarDisplayModal;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EbookPdfUrl } from '../../Constants/Config';

const SingleBookPdf = () => {
  const { Id } = useParams();
  const navigate = useNavigate();
  const [PdfSrc, setPdfSrc] = useState(null);

  useEffect(() => {
    if (Id?.length > 0) {
      const Pdf = EbookPdfUrl + Id;
      // console.log('pdf src', Pdf);
      setPdfSrc(Pdf);
    }
    return () => {
      setPdfSrc(null);
    };
  }, [Id]);

  return (
    <div>
      <div className="Article-Modal home-slider ">
        <div className="page-header-con">
          <button className="backbtn" onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-left"></i>
          </button>
        </div>
      </div>
       {PdfSrc && (
        <div>
          {/* Add any additional styling or wrapper elements if needed */}
          <iframe
            title="PDF Viewer"
            src={PdfSrc}
            width="100%"
            height="650px"
            style={{ border: "none",padding:'5px' }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default SingleBookPdf;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { FormattedDate2 } from "../../Constants/FormattedDate";
import LeafRight from "../../img/leaf-right.svg";
import RegisterIcon from "../../img/register-icon.png";
import { GetTwoForum } from "../../Redux/UserHome/action";
import { useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { AddForum } from "../../Pages/Forums/ForumPage";
import { getSession } from "../../lib/session";
import { htmlToPlainText } from "../../lib/helper";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
function Forum() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = getSession();
  const ForumData = useSelector((state) => state.UserHomeReducer.GetTwoForum);
  const ForumInsertResponse = useSelector(
    (state) => state.UserHomeReducer.CreateForum,
  );

  useEffect(() => {
    dispatch(GetTwoForum());
  }, [ForumInsertResponse]);

  // Trim Description
  const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "..."; // Truncate and add ellipsis
    }
  };

  const stripHtmlTags = (html, maxLength) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const plainText = doc.body.textContent || "";
    return trimText(plainText, maxLength);
  };
  const [AddNewForum, setAddNewForum] = useState(false);
  return (
    <>
      <div className="register-forum-wrap">
        {/* <img src={LeafRight} className="leaf-right" alt="leaf" /> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="premium-content">
                <img src={RegisterIcon} alt="register icon" />
                <h4>
                  Register for
                  <br />
                  Premium Contents
                </h4>
                <p>
                  Lorem ipsum dolor sitamet consectetur adipiscing elitphasellus
                  augue.
                </p>
                <button onClick={() => navigate("/Packages")}>
                  Register Now!
                </button>
              </div>
            </div>
            <div className="col-lg-8 forum-styles">
              <div className="row">
                <div className="col-lg-8">
                  <h2>Forum</h2>
                </div>
                <div className="col-lg-4">
                  <div className="forum-btns">
                    <button
                      className="addnew"
                      onClick={() => setAddNewForum(true)}
                    >
                      Add New
                    </button>{" "}
                    <button
                      className="viewall"
                      onClick={() => navigate("/forum")}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
             <div className="forumlist-space" style={{ display: 'flex', justifyContent: 'center', alignItems: ForumData?.length?'start': 'center', flexWrap: 'wrap', width: '100%', height: ForumData?.length?'fit-content': '250px'}}>
             {ForumData?.length>0?
              ForumData?.map((data, index) => (
                  <div
                  onClick={() => navigate(`/forum/${data.Id}`)}
                  key={data.Id}
                  style={{
                    flex: '1 1 0%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    borderRadius: '0.375rem',
                    background: '#eef7f3',
                    cursor: 'pointer',
                    color: '#18181b',
                    padding:'0px',
                    minHeight:'250px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      justifyContent: 'space-between',
                      background: '#ddece5',
                      padding: '0.5rem 1rem',
                    }}
                  >
                    <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem', fontWeight: '600' }}>
                      {data.Heading}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem',fontSize:'14px' }}>
                      <div style={{ whiteSpace: 'nowrap', padding: '0.125rem 0.25rem' }}>
                        {FormattedDate2(data.SysTime)}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', minWidth: '0', flex: '1', gap: '0.25rem', 
                      padding: '0.5rem 1rem', }}>
                    <div style={{ minWidth: '0', flex: 'auto' }}>
                      <p className="line-clamp-8"
                        style={{ textAlign: "justify" }}>
                          <Markdown remarkPlugins={[remarkGfm]}>{htmlToPlainText(data.Description)}</Markdown>
                        
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      background: '#ddece5',
                      padding: '0.5rem 1rem',
                    }}
                  >
                    <p style={{ marginBottom: '0.5rem', fontSize: '0.875rem', fontWeight: '500' }}>
                      {data.Name ?? "Guest User"}
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <i className="fas fa-user" />
                        <div>{data.TotalUsers}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <i className="far fa-comment" />
                        <div>{data.TotalComments}</div>
                      </div>
                    </div>
                  </div>
                </div>
                
                )):
                <p
                style={{
                  textAlign: "center",
                  color: "gray",
                  fontSize: "18px",
                }}
              >
                No data available.
              </p> }
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddForum
        dispatch={dispatch}
        session={session}
        open={AddNewForum}
        setOpen={setAddNewForum}
      />
    </>
  );
}

export default Forum;

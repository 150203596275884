import { sessionKey as tokenKey } from '../Constants/Config';

export const roleTypes = {
    admin: 'admin',
    guru: 'guru',
    user: 'user'
};

export const getSession = (key = tokenKey) => {
    const session = localStorage.getItem(key);
    return JSON.parse(session);
};

export const clearSession = (key = tokenKey) => {
    localStorage.removeItem(key);
};

export const setSession = (key = tokenKey, data) => {
    localStorage.setItem(key, JSON.stringify({ ...data }));
};

export const mutateSession = (data) => {
    const session = JSON.parse(localStorage.getItem(tokenKey));
    let updatedSession = {
        ...session,
        ...data
    };

    console.log('updatedsession');
    localStorage.setItem(tokenKey, JSON.stringify(updatedSession));
};

export const getUserType = (key = tokenKey) => {
    const rawSession = localStorage.getItem(key);
    const session = JSON.parse(rawSession);

    if (!session || !session.role) {
        return null;
    }

    return session.role;
};

export const isPremiumUser = (key = tokenKey) => {
    const rawSession = localStorage.getItem(key);
    const session = JSON.parse(rawSession);

    if (!session || !session.isPremiumUser) {
        return null;
    }

    return session.isPremiumUser;
};

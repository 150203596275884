import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { twMerge as tw } from 'tailwind-merge';
import { registerSchema } from '../../lib/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { MdOutlineAccessTime, MdOutlineErrorOutline } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	GetCountry,
	GetState,
	RegisterUser,
} from '../../Redux/Authentication/action';
import toast from 'react-hot-toast';
import { ImSpinner2 } from 'react-icons/im';
import states from '../../Constants/states.json';
import countries from '../../Constants/countries.json';
import { getValue } from '@testing-library/user-event/dist/utils';
import { ApiUrl } from '../../Constants/Config';

const RegisterForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		dispatch(GetState());
		dispatch(GetCountry());
	}, []);

	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
		control,
		reset,
	} = useForm({
		resolver: zodResolver(registerSchema),
		defaultValues: {
			Name: '',
			Mobile: '',
			Email: '',
			Institution: '',
			regNo: '',
			DrOrStudent: '',
			Country: 'India',
			State: '',
		},
	});

	const countryChange = useWatch({
		control,
		name: 'Country',
	});

	const States = useCallback(() => {
		console.log('countryChange => ', countryChange);
		console.log(
			'available states => ',
			states.filter(
				(el) =>
					el.country_id ==
					countries.find((el) => el.name === countryChange).id,
			),
		);

		return (
			<div className="flex w-full flex-1 flex-col space-y-2">
				<label htmlFor="state">State</label>
				<div>
					<div className="relative h-full">
						<select
							id="state"
							className={tw(
								`titext`,
								errors.State ? 'titext-error' : '',
							)}
							{...register('State')}
						>
							<option value="" disabled selected>
								Select a state
							</option>
							{states && states.length > 0 ? (
								states
									.filter(
										(el) =>
											el.country_id ==
											countries.find(
												(el) =>
													el.name === countryChange,
											)?.id,
									)
									.map((el, _) => {
										return (
											<option
												className={`p-3`}
												key={el.id}
												value={el.name}
											>
												{el.name}
											</option>
										);
									})
							) : (
								<option value={'karnataka'}>
									default state
								</option>
							)}
						</select>
					</div>
					{errors.State && (
						<span className="text-sm font-medium text-red-500">
							{errors.State.message}
						</span>
					)}
				</div>
			</div>
		);
	}, [countryChange]);

	const onSubmit = async (data) => {
		console.log('data => ', { ...data, Id: 0, Status: 0 });
		let parsedData = { ...data, Id: 0, Status: 0 };

		try {
			setSpinner(true);
			const response = await fetch(`${ApiUrl}/Users`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(parsedData),
			}).then((res) => {
				return res.json();
			});

			if (response && response?.length > 0 && response[0].Error == 111) {
				toast.error('User Already Exists! Try logging in!!!');
				setTimeout(() => {
					navigate(`/?email=${getValues('Email')}`);
				}, 3000);
			} else if (response && response?.length > 0 && response[0].Id) {
				console.log('response => ', response);
				toast.success(
					`You'll recieve a mail when your account is verified.`,
					{
						icon: (
							<MdOutlineAccessTime className="h-8 w-8 text-yellow-300" />
						),
					},
				);
				reset()
			}
		} catch (error) {
			toast.error('Something went wrong!!!');
		} finally {
			setSpinner(false);
		}

		// let parsedData = {
		// 	...data,
		// 	Id: 0,
		// 	Name: data.name,
		// 	Email: data.email,
		// 	Mobile: data.phone,
		// 	Status: 0,
		// 	DrOrStudent: data.userType,
		// 	RegistrationNumber: data.regNo,
		// 	Institution: data.instituition,
		// 	Country: data.country,
		// 	State: data.state,
		// };
	};

	return (
		<div className="mx-auto flex h-full w-full max-w-7xl flex-1 flex-col items-center justify-start px-4 py-10 md:justify-center">
			<h1 className="text-3xl font-bold">Create A User</h1>
			<form
				className="mx-auto mt-5 w-full space-y-2 md:space-y-5"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="name">Name</label>
						<div>
							<div className="relative h-full">
								<input
									type="text"
									id="name"
									className={tw(
										`titext`,
										errors.Name ? 'titext-error' : '',
									)}
									placeholder="Name"
									{...register('Name')}
								/>
								{errors.Name && (
									<MdOutlineErrorOutline
										className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
									/>
								)}
							</div>
							{errors.Name && (
								<span className="text-sm font-medium text-red-500">
									{errors.Name.message}
								</span>
							)}
						</div>
					</div>
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="phone">Phone</label>
						<div>
							<div className="relative h-full">
								<input
									type="text"
									id="phone"
									className={tw(
										`titext`,
										errors.Mobile ? 'titext-error' : '',
									)}
									placeholder="Phone"
									{...register('Mobile')}
								/>
								{errors.Mobile && (
									<MdOutlineErrorOutline
										className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
									/>
								)}
							</div>
							{errors.Mobile && (
								<span className="text-sm font-medium text-red-500">
									{errors.Mobile.message}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="email">Email</label>
						<div>
							<div className="relative h-full">
								<input
									type="text"
									id="email"
									className={tw(
										`titext`,
										errors.Email ? 'titext-error' : '',
									)}
									placeholder="example@domain.com"
									{...register('Email')}
								/>
								{errors.Email && (
									<MdOutlineErrorOutline
										className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
									/>
								)}
							</div>
							{errors.Email && (
								<span className="text-sm font-medium text-red-500">
									{errors.Email.message}
								</span>
							)}
						</div>
					</div>
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="regNo">Registration Number</label>
						<div>
							<div className="relative h-full">
								<input
									type="text"
									id="regNo"
									className={tw(
										`titext`,
										errors.RegistrationNumber
											? 'titext-error'
											: '',
									)}
									placeholder="12345"
									{...register('RegistrationNumber')}
								/>
								{errors.RegistrationNumber && (
									<MdOutlineErrorOutline
										className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
									/>
								)}
							</div>
							{errors.RegistrationNumber && (
								<span className="text-sm font-medium text-red-500">
									{errors.RegistrationNumber.message}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="instituition">Institution</label>
						<div>
							<div className="relative h-full">
								<input
									type="text"
									id="instituition"
									className={tw(
										`titext`,
										errors.Institution
											? 'titext-error'
											: '',
									)}
									placeholder="Institution"
									{...register('Institution')}
								/>
								{errors.Institution && (
									<MdOutlineErrorOutline
										className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
									/>
								)}
							</div>
							{errors.Institution && (
								<span className="text-sm font-medium text-red-500">
									{errors.Institution.message}
								</span>
							)}
						</div>
					</div>
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="userType">User Type</label>
						<div>
							<div className="relative h-full">
								<select
									id="userType"
									{...register('DrOrStudent')}
									className={tw(
										`titext`,
										errors.DrOrStudent
											? 'titext-error'
											: '',
									)}
								>
									<option value="" disabled selected>
										Select an option
									</option>
									<option value={'Vaidya'}>Vaidya</option>
									<option value={'Practitioner'}>
										Practitioner
									</option>
									<option value={'Student'}>Student</option>
								</select>
							</div>
							{errors.DrOrStudent && (
								<span className="text-sm font-medium text-red-500">
									{errors.DrOrStudent.message}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="mx-auto flex w-full max-w-5xl flex-col items-center gap-2 md:flex-row md:gap-x-10">
					<div className="flex w-full flex-1 flex-col space-y-2">
						<label htmlFor="country">Country</label>
						<div>
							<div className="relative h-full">
								<select
									id="country"
									className={tw(
										`titext`,
										errors.Country ? 'titext-error' : '',
									)}
									{...register('Country')}
								>
									<option value="" disabled selected>
										Select a country
									</option>
									{countries && countries.length > 0 ? (
										countries.map((el, _) => {
											return (
												<option
													className={`p-3`}
													key={el.id}
													value={el.name}
												>
													{el.name}
												</option>
											);
										})
									) : (
										<option value={'India'}>India</option>
									)}
								</select>
							</div>
							{errors.Country && (
								<span className="text-sm font-medium text-red-500">
									{errors.Country.message}
								</span>
							)}
						</div>
					</div>
					<States />
				</div>
				<div className="mt-4 flex w-full flex-1 flex-col space-y-2">
					<button
						type="submit"
						disabled={spinner}
						className="mx-auto flex w-fit items-center space-x-2 rounded-md border bg-brown p-2 px-4 font-medium capitalize tracking-wide text-white transition-all hover:bg-darkBrown"
					>
						<span>{spinner ? 'Registering...' : 'Sign Up'}</span>
						<ImSpinner2
							className={`${spinner ? 'block animate-spin' : 'hidden'}`}
						/>
					</button>
				</div>
			</form>
			<div className="mt-3">
				Already A User? <Link to={'/'}>Login</Link>
			</div>
		</div>
	);
};

export default RegisterForm;

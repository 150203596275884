import React, { useEffect, useState } from "react";
import { FormattedDate2 } from "../../Constants/FormattedDate";
import "../../Components/Style/CommentModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetForumMessages,
  GetSingleForum,
  InsertForumMessages,
} from "../../Redux/UserHome/action";
import { Timelines } from "../../Components/Forum/Timeline";
import { useNavigate, useParams } from "react-router-dom";
import { getSession } from "../../lib/session";
import toast from "react-hot-toast";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { htmlToPlainText } from "../../lib/helper";
function CommentModal() {
  const navigate = useNavigate();
  const session = getSession();
  const ForumData = useSelector(
    (state) => state.UserHomeReducer.GetSingleForum,
  );
  const CommentData = useSelector(
    (state) => state.UserHomeReducer.GetForumMessages,
  );
  const CommentInsertResponse = useSelector(
    (state) => state.UserHomeReducer.InsertForumMessages,
  );
 

  const { Id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (Id) {
      dispatch(
        GetSingleForum({
          forumId: Id,
        }),
      );
    }
  }, [Id,CommentInsertResponse]);

  useEffect(() => {
    if (Id) {
      dispatch(
        GetForumMessages({
          forumId: Id,
        }),
      );
      setComment("")
    }
  }, [Id, CommentInsertResponse]);

  //Comment Insert
  const [Comment, setComment] = useState("");
  const InsertComment = () => {
    if (!session) {
      toast.error("You need to login to comment forum!");
      return;
    }

    if (Comment.length > 0) {
      dispatch(
        InsertForumMessages({
          ForumId: Id,
          Comment: Comment,
          UserId: session.id,
        }),
      );
    } else {
      alert("Please enter Comment!");
    }
  };
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      InsertComment();
    }
  };
  return (
    <div className="CommentModal ContentDisplayPage" >
      <div className="Article-Modal home-slider ">
        <div className="page-header-con">
          <button className="backbtn" onClick={() => navigate('/forum')}>
            <i className="fas fa-chevron-left"></i>
          </button>
        </div>
      
   
      </div>
      <div className=" container mb-3 mt-3">
        <div className="row height d-flex justify-content-center align-items-center">
          <div className="col-md-12">
            <div className="card">
              <div
                className="d-flex flex-row p-3"
                style={{ background: "rgb(238 247 243)" }}
              >
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex align-items-center flex-row ">
                      <span
                        className="me-2"
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {ForumData?.Heading}{" "}
                      </span>
                    </div>
                    <small>{ForumData?.Name}</small>
                  </div>
                  {/* <p
                    className="comment-text mb-2 text-justify"
                    style={{ fontSize: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: ForumData?.Description,
                    }}
                  /> */}
                  <Markdown remarkPlugins={[remarkGfm]}>{htmlToPlainText(ForumData?.Description)}</Markdown>
                  <div className="d-flex user-feed flex-row ">
                    <span className="wish">
                      <i className="fas fa-user me-2" />
                      {ForumData?.TotalUsers}
                    </span>
                    <span className="wish ms-4">
                      <i className="far fa-comment me-2" />
                      {ForumData?.TotalComments}
                    </span>
                    {/* <span className="wish ms-4">
                      <i className="fa-solid fa-share me-2" />0
                    </span> */}
                    <small className="ml-auto">{FormattedDate2(ForumData?.SysTime)}</small>
                  </div>
                </div>
              </div>
              
              <div className="col-md-12" id="fbcomment">
                    <div className="box_comment col-md-11">
                      <textarea
                        className="commentar"
                        placeholder="Add a comment..."
                        defaultValue={""}
                        value={Comment}
                        onChange={(e)=>setComment(e.target.value)}
                      />
                      <div className="box_post">
                        <div className="pull-right">
                          <button type="button" onClick={InsertComment}>Post</button>
                        </div>
                      </div>
                    </div>
              </div>
              <div className="mt-2">
                {CommentData && CommentData.length > 0 ? (
                  <Timelines CommentData={CommentData} />
                ) : (
                  <p style={{ textAlign: "center" }}>No comments available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CombinedFooter/>
    </div>
  );
}

export default CommentModal;

import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { chatTime } from "../../lib/helper";
import { getSession } from "../../lib/session";
import {
  getMessagesForAskguru,
  getMessagesForUsers,
  UpdateIsread,
} from "../../Redux/Chat/action";
import GuruPng from '../../img/guru.png'
import UserIcon from '../../img/UserIcon.png'
import SingleChat from "./SingleChat";
import GuruList from "./GuruList";
import sessionStorage from "redux-persist/es/storage/session";
import { getTimeAgoString } from "../../Constants/FormattedDate";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
import { GuruImageUrl } from "../../Constants/Config";

function SampleChat() {
  const navigate = useNavigate();
  const handleBackButtonPress=()=>{
  if (IsGuruList||selectedProfile!==null) {
    setIsGuruList(false)
    setSelectedProfile(null)
  } else {
    navigate('/user-home')
  }   
  }
  
  useHardwareBackButton(0,handleBackButtonPress) 
  const dispatch = useDispatch();
  const session = getSession();
  const ChatInsertResponse = useSelector((state) => state.ChatReducer.sendMessage);
  const IsReadResponse = useSelector((state) => state.ChatReducer.UpdateIsRead);
  const [searchParams, setSearchParams] = useSearchParams(); // Destructuring the returned value of useSearchParams
  const UseMessages = useSelector((state) =>
    session.isGuru
      ? state.ChatReducer.askguruMessages
      : state.ChatReducer.userMessages,
  );
  useEffect(() => {
    if (session.isGuru) {
      dispatch(getMessagesForAskguru({ guruid: session?.guruId }));
    } else {
      dispatch(getMessagesForUsers({ userid: session?.id }));
    }
  }, [ChatInsertResponse,IsReadResponse]);

  const [selectedProfile, setSelectedProfile] = useState(null);

  // GuruList Modal
  const [IsGuruList, setIsGuruList] = useState(true)
  const HandleGuruListOpen=()=>{
    setIsGuruList(true)
  }
  const HandleGuruListClose=()=>{
    setIsGuruList(false)
  }

  //Ask guru from user-home
  useEffect(() => {
    if(searchParams.get("newChat")){
       setIsGuruList(true)
    }else{
      setIsGuruList(false)
    }
  }, [searchParams])
  
  //Chat selection
  const ChatClick=(data)=>{
    dispatch(UpdateIsread({
      ChatMessageId:data.Id,
      SenderId:session?.id
    }))   
    setSelectedProfile(data)
  }

  return (
    <>
    {!session.isGuru&&<GuruList IsGuruList={IsGuruList} HandleGuruListClose={HandleGuruListClose} setSelectedProfile={setSelectedProfile} UseMessages={UseMessages} />}
    {selectedProfile === null?<>
      <header className="sticky top-0 bg-primaryTailwind text-white">
        <div className="relative flex items-center gap-4 p-4">
          <button onClick={() => navigate("/user-home")}>
            <FaHome className="h-6 w-6" />
          </button>
          <h2 className="mr-auto text-xl font-semibold tracking-wide text-white">
            AskGuru
          </h2>
        </div>
      </header>
      <section className="divide-y ">
        {UseMessages?.length ? (
          UseMessages?.map((data) => (
            <div
              className="grid grid-cols-[3rem_1fr] items-center gap-3 px-3 py-3 hover:bg-gray-200 cursor-pointer"
              key={data.Id}
              onClick={()=>ChatClick(data)}
            >
              {session.isGuru?<img
                className="h-12 w-12 rounded-full bg-gray-800 object-cover"
                src={UserIcon}
                alt="Prithvi"
              />:<img
              className="h-12 w-12 rounded-full bg-gray-800 object-cover"
              src={data.ImageUrl ? (GuruImageUrl + data.ImageUrl) : GuruPng}
              alt="Prithvi"
            />}
              <div>
                <h4 className="flex justify-between text-base font-medium text-gray-800">
                  <span>{data.UserName}</span>

                  <span
                    className={`text-xs ${
                      data.UnreadMessageCount
                        ? "text-green-500"
                        : "text-black/50"
                    }`}
                  >
                    {getTimeAgoString (data.lastmsgTime)}
                  </span>
                </h4>


                <p className="flex justify-between text-sm text-black/60">
                  <span className="line-clamp-1  w-[95%]">
                    {data.latestmessage}
                  </span>
                  {data.UnreadMessageCount > 0 && (
                    <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-green-500 pt-0.5 text-xs text-white">
                      {data.UnreadMessageCount}
                    </span>
                  )}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center p-3 font-sans font-semibold ">
            <div
              className="flex-auto"
            >
              No Chats Yet! Start A New Conversation.
            </div>
          </div>
        )}

        {!session.isGuru&&<button
          className="fixed bottom-6 right-5 rounded-full bg-primaryTailwind p-3 sm:p-4"
          onClick={HandleGuruListOpen}
        >
          <IoMdAdd className="text-2xl text-white" />
        </button>}
      </section>
      </>:<SingleChat selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile}/>}
    </>
  );
}

export default SampleChat;

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';

const SpinnerComponent = ({ showLoader }) => {
	const [displayLoader, toggleDisplayLoader] = useState(false);
	const isLoading = useSelector((state) => state.Common.Loader.loader);

	useEffect(() => {
		if (showLoader) {
			toggleDisplayLoader(true);
		} else if (isLoading === true) {
			toggleDisplayLoader(true);
		} else if (isLoading === false) {
			toggleDisplayLoader(false);
		}
	}, [showLoader, isLoading]);
	return (
		<Modal
			show={displayLoader}
			size="sm"
			backdrop="static"
			keyboard={false}
			centered
		>
			<ModalBody className="d-flex flex-column align-items-center">
				<Spinner animation="border" style={{ color: '#ad7b42' }} />
				<span style={{ marginTop: '10px' }}>Loading...</span>
			</ModalBody>
		</Modal>
	);
};

export default SpinnerComponent;

import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
	actionTypes,
	createChatError,
	createChatSuccess,
	getAskguruUsersSuccess,
	getMessagesError,
	getMessagesForAskguruError,
	getMessagesForAskguruSuccess,
	getMessagesForUsersError,
	getMessagesForUsersSuccess,
	getMessagesSuccess,
	sendMessageError,
	sendMessageSuccess,
	UpdateIsreadError,
	UpdateIsreadSuccess,
} from './action';
import { startSpinner, stopSpinner } from '../Common/spinner/action';
import ChatService from '../../Services/ChatService';
import { startLoader, stopLoader } from '../Common/Loader/action';

function* GetMessagesForAskguru({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.GetMessagesForAskguru, payload);
		yield put(getMessagesForAskguruSuccess(data));
	} catch (error) {
		yield put(getMessagesForAskguruError(error));
		console.log(error);
	} finally {
		yield put(stopLoader());
	}
}

function* GetMessagesForUsers({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.GetMessagesForUsers, payload);
		yield put(getMessagesForUsersSuccess(data));
	} catch (error) {
		yield put(getMessagesForUsersError(error));
		console.log(error);
	} finally {
		 yield put(stopLoader());
	}
}

function* CreateChat({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.CreateChat, payload);
		yield put(createChatSuccess(data));
	} catch (error) {
		yield put(createChatError(error));
		console.log(error);
	} finally {
		 yield put(stopLoader());
	}
}

function* GetMessages({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.GetMessages, payload);
		yield put(getMessagesSuccess(data));
	} catch (error) {
		yield put(getMessagesError(error));
		console.log(error);
	} finally {
		 yield put(stopLoader());
	}
}

function* SendMessage({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.SendMessage, payload);
		yield put(sendMessageSuccess(data));
	} catch (error) {
		yield put(sendMessageError(error));
		console.log(error);
	} finally {
		 yield put(stopLoader());
	}
}

function* GetAskguruUsers({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.GetAskguruUsers, payload);
		yield put(getAskguruUsersSuccess(data));
	} catch (error) {
		yield put(getAskguruUsersSuccess(error));
		console.error(error);
	} finally {
		 yield put(stopLoader());
	}
}

function* UpdateIsRead({ payload }) {
	try {
		yield put(startLoader());
		const data = yield call(ChatService.UpdateIsRead, payload);
		yield put(UpdateIsreadSuccess(data));
	} catch (error) {
		yield put(UpdateIsreadError(error));
		console.error(error);
	} finally {
		 yield put(stopLoader());
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actionTypes.GET_ASKGURU_MESSAGE, GetMessagesForAskguru),
		takeEvery(actionTypes.GET_USER_MESSAGE, GetMessagesForUsers),
		takeEvery(actionTypes.CREATE_CHAT, CreateChat),
		takeEvery(actionTypes.GET_MESSAGES, GetMessages),
		takeEvery(actionTypes.SEND_MESSAGE, SendMessage),
		takeEvery(actionTypes.ASKGURU_USERS, GetAskguruUsers),
		takeEvery(actionTypes.UPDATE_ISREAD, UpdateIsRead),
		
	]);
}


import React from "react";
import BannerSlideImg from "../../img/banner-slider.png";
import { Carousel } from "react-bootstrap";
import { ArticleImgUrl } from "../../Constants/Config";
function BannerSlide(props) {
  return (
    <>
      {props.BannerData?.length > 0 && (
        <div className="banner-imageslide">
          <div className="container">
            <Carousel
              id="carouselExampleIndicators"
              fade={true}
              interval={5000}
              controls={true}
              indicators={false}
              pause="hover"
            >
              {props.BannerData?.map((data, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 "
                    src={ArticleImgUrl + data.ImageUrl}
                    alt="The Prithvi"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}

export default BannerSlide;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {GetGroupDataByType } from "../../Redux/UserHome/action";
import "../Css/AllGroup.css";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
import Articles from "../../Components/UserHome/Articles";
import VideoSlide from "../../Components/UserHome/VideoSlide";
import ImageSlide from "../../Components/UserHome/ImageSlide";
import BannerSlide from "../../Components/UserHome/BannerSlide";
import AudioSlide from "../../Components/UserHome/AudioSlide";
import Ebooks from '../../Components/UserHome/Ebooks';
function AllTypeCategorized() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [VideoModal, setVideoModal] = useState(false)
  const [AudioModal, setAudioModal] = useState(false)
  const [OpenImage, setOpenImage] = useState(false)
  const handleBackButtonPress=()=>{
    if (VideoModal||AudioModal||OpenImage) {
      setVideoModal(false)
      setAudioModal(false)
      setOpenImage(false)
    } else {
      navigate('/user-home')
    }
    
  }
  useHardwareBackButton(0,handleBackButtonPress)  
  const { Id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [GroupType, setGroupType] = useState(null);

  const GroupDataByType = useSelector(
    (state) => state.UserHomeReducer.GroupDataByType,
  );

  useEffect(() => {
    if (Id == 1) {
      setGroupType({
        Label: "Articles",
        Value: 1,
      });
    } else if (Id == 2) {
      setGroupType({ Label: "Videos", Value: 2 });
    } else if (Id == 3) {
      setGroupType({ Label: "Puzzles", Value: 3 });
    } else if (Id == 6) {
      setGroupType({
        Label: "Ebooks",
        Value: 6,
      });
    } else if (Id == 4) {
      setGroupType({ Label: "Posters", Value: 4 });
    }else if (Id == 8) {
      setGroupType({ Label: "Audios", Value: 8 });
    }
  }, [Id]);

  useEffect(() => {
    if (GroupType) {
      setSearchTerm('')

      dispatch(
        GetGroupDataByType({
          type: GroupType.Value,
          dummy:1
        }),
      );

      // Update the URL using window.history.pushState
      const newUrl = `/Type/${GroupType.Value}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [GroupType]);

  const filteredGroupData = GroupDataByType && GroupDataByType.map(group => {
    // Map over each group and filter item.Data
    const filteredData = group.Data.filter(item => {
      // Filter based on Heading or Keyword
      return (
        item.Heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.Keyword.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    // Return the group with filtered data
    return {...group, Data: filteredData};
  });

  

const OrderedPrincipleGroup = GroupType && filteredGroupData?.map(item => {
    const { GroupId, GroupName, Data } = item; 
    const GroupData = {
        Id:GroupId,
        Heading: GroupName
    };
    if (GroupType.Value ==1) {
        const ArticleData = Data;
        return (
            <Articles
                key={item.Id}
                GroupData={GroupData}
                ArticleData={ArticleData}
            />
        );
    } else if (GroupType.Value ==2) {
        const VideoData = Data;
        return (
            <VideoSlide
                key={item.Id}
                GroupData={GroupData}
                VideoData={VideoData}
            />
        );
    } else if (GroupType.Value ==3) {
        const ImageData = Data;
        return (
            <ImageSlide
                key={item.Id}
                GroupData={GroupData}
                ImageData={ImageData}
            />
        );
    } else if (GroupType.Value ==4) {
        const BannerData = Data;
        return (
            <BannerSlide
                key={item.Id}
                GroupData={GroupData}
                BannerData={BannerData}
            />
        );
    } else if (GroupType.Value ==6) {
        const EbookData = Data;
        return (
            <Ebooks
                key={item.Id}
                GroupData={GroupData}
                EbookData={EbookData}
            />
        );
    } else if (GroupType.Value ==8) {
        const AudioData = Data;
        return (
            <AudioSlide
                key={item.Id}
                GroupData={GroupData}
                AudioData={AudioData}
            />
        );
    } else {
        return null;
    }
});

  return (
    <div className="ContentDisplayPage">
      <div className="Article-Modal" style={{flex:'1 1 0%'}}>
        <div className=" home-slider ">
          <div className="page-header-con">
            <button className="backbtn" onClick={() => navigate("/user-home")}>
              <i className="fas fa-chevron-left"></i>
            </button>
           
          </div>
        </div>

        <h2 className="page-heading">{GroupType?.Label}</h2>
        <div className="container">
          <div className="row justify-content-center mt-3 ">
            <div className="search-container">
              <div
                className="row w-100"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-4"
                  style={{ paddingLeft: "0px !important" }}
                >
                  <select
                    style={{ borderRight: "none",appearance:'auto',paddingRight:'0px' }}
                    name="search-options"
                    id="search-select"
                    value={GroupType?.Value}
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value); // Convert to integer
                      switch (selectedValue) {
                        case 1:
                          setGroupType({ Label: "Articles", Value: 1 });
                          break;
                        case 2:
                          setGroupType({ Label: "Videos", Value: 2 });
                          break;
                        case 3:
                          setGroupType({ Label: "Puzzles", Value: 3 });
                          break;
                        case 6:
                          setGroupType({ Label: "Ebooks", Value: 6 });
                          break;
                        case 4:
                          setGroupType({ Label: "Posters", Value: 4 });
                          break;
                        case 8:
                          setGroupType({ Label: "Audios", Value: 8 });
                          break;
                        default:
                          // Handle default case
                          break;
                      }
                    }}
                  >
                    <option value="1">Articles</option>
                    <option value="2">Videos</option>
                    <option value="3">Puzzles</option>
                    <option value="6">Ebooks</option>
                    <option value="4">Posters</option>
                    <option value="8">Audios</option>
                  </select>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-6 col-6">
                  <input
                    style={{
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",

                    }}
                    type="text"
                    placeholder={`Search ${GroupType?.Label}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {/* <div className="col-xl-2 col-lg-2 col-md-3 col-2"><button><i className="fas fa-search"></i> <div>Search</div></button></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{paddingBottom:'80px'}}>
            {OrderedPrincipleGroup}
        </div>
      </div>

      <CombinedFooter />
    </div>
  );
}

export default AllTypeCategorized;

import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { EbookImgUrl, EbookPdfUrl } from "../../Constants/Config";
import { getSession } from "../../lib/session";

function AllEbooks(props) {
  const navigate = useNavigate();
  const session = getSession();
  const IsMobile = window.innerWidth <= 768;
  // console.log("props.filteredArticles", props.filteredArticles);
  
  const handlePdfClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    const isMobile = IsMobile;
    const url = isMobile ? EbookPdfUrl + Data.PdfSecretKey : `/Ebook/${Data.PdfSecretKey}`;
    const canAccessPdf = isPremiumUser || Data.IsPremium !== 1;

    if (!canAccessPdf) {
        toast.error("Please purchase a package");
        return;
    }

    if (isMobile) {
        window.open(url, "_blank");
    } else {
        navigate(url);
    }
}


  return (
    <div className="row trendingvideos">
      {props.filteredArticles &&
        props.filteredArticles.length > 0 &&
        props.filteredArticles.map((data, i) => (
          <div className="video-item col-lg-3 col-md-3 col-sm-4 col-12" key={i}>
            <button
              style={{ padding: "5px", cursor: "pointer" }}
              className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
              onClick={() => handlePdfClick(data)}
            >
              <span className="premium-icon-span">
                <img
                  src={EbookImgUrl + data.ThumbImg}
                  alt="peacegate"
                  style={{ height: "300px", width: "100%" }}
                />
              </span>
              <div
                className="videocontent"
                style={{
                  padding: "0px",
                  transitionDuration: "0s",
                  height: "80px",
                }}
              >
                <h6>{data.Heading}</h6>
              </div>
            </button>
          </div>
        ))}
    </div>
  );
}

export default AllEbooks;

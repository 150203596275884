import React, { Fragment, memo, useState } from 'react';
import {
    checkValidImageUrl,
    checkValidVideoUrl,
    dummyLoadingRows,
    extractShortsVideoId,
    extractSrcFromHtmlInput,
    extractYoutubeVideoId,
    getVimeoVideoId,
    IdentifyVideoService,
    typeMapper
} from '../../lib/helper';
import { IoMdArrowDropright } from 'react-icons/io';
import { VideoThumbUrl, VideoUrl } from '../../Constants/Config';
import VideoDisplayModal from '../../Components/Modals/VideoDisplayModal';
import toast from 'react-hot-toast';
import { PiSmileySadLight } from 'react-icons/pi';
import { FcRemoveImage } from 'react-icons/fc';
import { IoPlayOutline } from 'react-icons/io5';
const VideosSearch = memo(({ rows }) => {
    return (
        <div className="mb-4 ">
            <div className="grid-col-1 grid w-full gap-3 searchmd:grid-cols-2 searchlg:grid-cols-3 searchxl:grid-cols-4 search2xl:grid-cols-5 search3xl:grid-cols-6">
                {rows.map((data, _) => {
                    let src = VideoUrl + data.VideoUrl;
                    if (data.VideoType === 1) {
                        const linkType = IdentifyVideoService(data.VideoUrl);
                        if (linkType === 'Vimeo') {
                            src = `https://player.vimeo.com/video/${getVimeoVideoId(data.VideoUrl)}?autoplay=1&loop=1&autopause=0`;
                        } else if (linkType === 'YouTube') {
                            src = `https://www.youtube.com/embed/${extractYoutubeVideoId(data.VideoUrl)}?autoplay=1`;
                        } else if (linkType === 'YouTubeShorts') {
                            src = `https://www.youtube.com/embed/${extractShortsVideoId(data.VideoUrl)}?autoplay=1`;
                        } else if (linkType === 'iframe') {
                            console.log(
                                'extractSrcFromHtmlInput(data.VideoUrl)',
                                extractSrcFromHtmlInput(data.VideoUrl)
                            );
                            src = extractSrcFromHtmlInput(data.VideoUrl);
                        } else {
                            toast.error(
                                'The provided video URL is not supported or invalid!'
                            );
                        }
                    }

                    return (
                        <div className="group mt-2 w-full rounded-md" key={_}>
                            <VideoComponent data={data} src={src} />
                            <div>{data.Heading}</div>
                            <div className="flex flex-wrap items-center space-x-2">
                                <div className="flex items-center space-x-1">
                                    <div className="text-xs capitalize tracking-widest">
                                        Group
                                    </div>
                                    <IoMdArrowDropright className="text-gray-500" />
                                    <div className="flex w-fit items-center justify-center rounded-full border border-black bg-stone-100 px-1.5 py-0.5 text-xs lowercase tracking-widest text-gray-500">
                                        {data.Name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

const VideoComponent = ({ data, src }) => {
    const [showModal, setShowModal] = useState(false);
    const [isValidThumb, setIsValidThumb] = useState(false);
    // const [isValidVideo, setIsValidVideo] = useState(false);

    // checkValidVideoUrl(VideoThumbUrl + data.ThumbImg).then((isValid) => {
    //     setIsValidVideo(isValid);
    //     if (isValid) {
    //         console.log('Valid video URL');
    //         // Do something if the video URL is valid
    //     } else {
    //         console.log('Invalid video URL');
    //         // Handle the case where the video URL is invalid
    //     }
    // });
    let url =
        data.VideoType === 1 &&
        !data.ThumbImg &&
        IdentifyVideoService(data.VideoUrl) === 'YouTube'
            ? `https://img.youtube.com/vi/${extractYoutubeVideoId(data.VideoUrl)}/maxresdefault.jpg`
            : VideoThumbUrl + data.ThumbImg;

    let isValid = checkValidImageUrl(url).then((res) => {
        setIsValidThumb(res);
        // if (res) {
        //     console.log('Valid video URL for ', data.Name, res);
        //     // Do something if the video URL is valid
        // } else {
        //     console.log('Invalid video URL for ', data.Name, res);
        //     // Handle the case where the video URL is invalid
        // }
        return res;
    });

    console.log('valid value for , isValid', isValid, data.Name);
    return (
        <div className="relative mb-2 aspect-videos w-full rounded-md border">
            {url && isValidThumb ? (
                <Fragment>
                    <img
                        src={url}
                        alt="uploaded video"
                        className="h-full w-full rounded-md"
                    />
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <IoPlayOutline
                            className="mb-2 block h-10 w-10 fill-transparent text-white"
                            onClick={() => setShowModal(true)}
                        />
                    </div>
                </Fragment>
            ) : (
                <div className="flex h-full w-full items-center justify-center rounded-lg bg-zinc-100">
                    <div className="flex flex-col items-center justify-center space-x-2">
                        {/* <PiSmileySadLight className="mb-4 block h-8 w-8" /> */}
                        <FcRemoveImage className="mb-2 block h-8 w-8" />
                        <button
                            className="text-wrap text-[3vw] searchsm:text-xs"
                            onClick={() => setShowModal(true)}
                        >
                            play video
                        </button>
                    </div>
                </div>
            )}
            <VideoDisplayModal
                showModal={showModal}
                currentVideo={src}
                handleClose={() => setShowModal(false)}
            />
        </div>
    );
};

export const VideosLoader = ({}) => {
    return (
        <div>
            {dummyLoadingRows.map((el) => {
                return (
                    <div key={el} className="group mb-4 mt-2 rounded-md">
                        <div className="mb-2 aspect-videos animate-pulse rounded-lg bg-slate-200 leading-7">
                            <div className="mb-1 h-full w-full animate-pulse rounded-md bg-slate-200"></div>
                        </div>
                        <div className="mb-2 h-5 w-[45%] animate-pulse rounded-md bg-slate-200"></div>
                        <div className="flex items-center space-x-2">
                            <div className=" h-2.5 w-[10%] animate-pulse rounded-md bg-slate-200"></div>
                            <div className=" h-2.5 w-2.5 animate-pulse rounded-md bg-slate-200"></div>
                            <div className=" h-2.5 w-[10%] animate-pulse rounded-md bg-slate-200"></div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default VideosSearch;

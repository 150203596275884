import ApiClient from './ApiClient';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from '../Components/Loaders/toastify';
import { mutateSession } from '../lib/session';

class AuthenticationService {
	constructor(callback) {
		this.callback = callback;
	}

	async GetUsers(payload) {
		const response = await ApiClient.get(
			`Users?Username=${payload.Username}&Password=${payload.Password}`,
		)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async RegisterUser(payload) {
		const response = await ApiClient.post(`Users`, payload)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));

		return response;
	}

	async GetState(payload) {
		const response = await ApiClient.get(`Master`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetCountry(payload) {
		const response = await ApiClient.get(`Master?dummy=${7}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetUserProfile(payload) {
		const response = await ApiClient.get(`Users?Uid=${payload.id}&Dummy=1`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async RestrictMultiLogin(payload) {
		const response = await ApiClient.post(`Logins`,payload)
			.then((response) => {
				
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
	
	async CheckMultiLogin(payload) {
		const response = await ApiClient.get(`Logins?SecretKey=${payload.SecretKey}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
	
	async LogoutMultiUser(payload) {
		const response = await ApiClient.get(`Logins?SecretKey=${payload.SecretKey}&UserId=${payload.UserId}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
}

export default new AuthenticationService();

import React, { useEffect, useMemo, useState } from "react";
import "../Css/Mobile.css";
import ArticleIcon from "../../img/MobileImages/img/articles-icon.png";
import Slider from "react-slick";
import { formattedDate } from "../../Constants/FormattedDate";
import { htmlToPlainText } from "../../lib/helper";
import VideoIcon from "../../img/MobileImages/img/video-icon.png";
import ImagesIcon from "../../img/MobileImages/img/images-icon.png";
import BannersIcon from "../../img/MobileImages/img/banner-icon.png";
import EbooksIcon from "../../img/MobileImages/img/ebook-icon.png";
import DrugDatabaseIcon from "../../img/MobileImages/img/database-icon.png";
import ForumsIcon from "../../img/MobileImages/img/forums-icon.png";
import ShortsIcon from "../../img/MobileImages/img/shorts-icon.png";
import AskGuruIcon from "../../img/MobileImages/img/askguru-icon.png";
import ShortsPlayIcon from "../../img/MobileImages/img/shorts-play-icon.png";
import TopBanner from "../../Components/UserHome/TopBanner";
import { useNavigate } from "react-router-dom";
import { Carousel, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import trailImage from '../../img/MobileImages/img/trail.jpg';
import toast from "react-hot-toast";

import {
  CheckMultiLoginError,
  GetUserError,
  LogoutMultiUser,
} from "../../Redux/Authentication/action";
import { clearSession, getSession } from "../../lib/session";
import AnnouncementSlide from "../../Components/UserHome/AnnouncementSlide";
import {
  GetBannerData,
  GetNotificationStatus,
  GetSingleShort,
  GetSingleShortError,
  PushNotificationUpdate,
  UpdateShortStatus,
  UpdateShortStatusError,
} from "../../Redux/UserHome/action";
import { ArticleImgUrl } from "../../Constants/Config";
import MobileFooter from "../../Components/Footer/MobileFooter";
import SlideImgMobile from "../../img/slide-01-mobile.jpg";
import Poster from "../../img/MobileImages/img/Poster.png";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
import PrithviLogo from "../../img/the-prithvi-logo.png";
import { App } from "@capacitor/app";
import ShortsModal from "../shorts/ShortsModal";
import ToggleButton from "../../Components/Button/ToggleButton";
import { checkValidImageUrl } from "../../lib/helper";
function MobileHomeScreen(props) {
  const navigate = useNavigate();
  const session = getSession();
  const dispatch = useDispatch();
  const BannerData = useSelector((state) => state.UserHomeReducer.BannerData);
  const [validBanners, setValidBanners] = useState([]);
  const ShortsData = useSelector(
    (state) => state.UserHomeReducer.GetSingleShortData,
  );

  const [viewSidebar, setViewSidebar] = useState(false);

  useEffect(() => {
    dispatch(GetBannerData());
    dispatch(
      GetSingleShort({
        UserId: session?.id,
      }),
    );
    dispatch(
      GetNotificationStatus({
        UId: session.id,
      }),
    );
  }, []);

  const handleBackButtonPress = () => {
    if (viewSidebar) {
      setViewSidebar(false);
    } else {
      if (window.confirm("Are you sure you want to exit?")) {
        App.exitApp();
      }
    }
  };
  const settings = {
    dots: true,
    slidesToShow: 4,
    arrows: false,
    refresh: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };
  const [SelectedArticleData, setSelectedArticleData] = useState([]);

  const ArticleClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      navigate(`/Article/${Data.Id}`);
    } else {
      toast.error("Please purchase a package");
    }
  };
  useHardwareBackButton(0, handleBackButtonPress);
  const handleBarsBtnClick = () => {
    setViewSidebar((prevViewSidebar) => !prevViewSidebar);
  };
  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      dispatch(
        LogoutMultiUser({
          SecretKey: session?.SecretKey,
          UserId: session?.id,
        }),
      );
      dispatch(CheckMultiLoginError());
      dispatch(GetUserError());
      clearSession();
      navigate("/");
    }
  };

  // Shorts Modal
  const [showShortsModal, setShowShortsModal] = useState(false);

  const handleCloseModal = () => {
    dispatch(
      UpdateShortStatus({
        UserId: session?.id,
        ShortsId: ShortsData.Id,
      }),
    );

    dispatch(GetSingleShortError());
    setShowShortsModal(false);
  };

  const handleShowModal = () => setShowShortsModal(true);
  //Open Shorts if ShortData available
  useEffect(() => {
    if (ShortsData && Object.keys(ShortsData).length > 0) {
      // console.log('ShortsData',ShortsData);
      handleShowModal();
    }
  }, [ShortsData]);

  //Push Notification ON/OFF

  const [isForumNotificationOn, setIsForumNotificationOn] = useState(true);
  const [isGeneralNotificationOn, setIsGeneralNotificationOn] = useState(true);
  const NotificationStatus = useSelector(
    (state) => state.UserHomeReducer.GetNotificationStatus,
  );

  useEffect(() => {}, []);
  useEffect(() => {
    if (NotificationStatus) {
      const IsForumNotification =
        NotificationStatus[0].IsForumCommentOn == 0 ? false : true;
      const IsArticleNotification =
        NotificationStatus[0].IsArticleComments == 0 ? false : true;
      setIsForumNotificationOn(IsForumNotification);
      setIsGeneralNotificationOn(IsArticleNotification);
    }
  }, [NotificationStatus]);

  const handleForumNotificationToggle = () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to ${isForumNotificationOn ? "turn off" : "turn on"} forum notifications?`,
    );

    if (userConfirmed) {
      dispatch(
        PushNotificationUpdate({
          Type: 1,
          IsTurnOn: isForumNotificationOn ? 0 : 1,
          UserId: session.id,
        }),
      );
      setIsForumNotificationOn(!isForumNotificationOn);
    }
  };

  const handleGeneralNotificationToggle = () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to ${isGeneralNotificationOn ? "turn off" : "turn on"} general notifications?`,
    );

    if (userConfirmed) {
      dispatch(
        PushNotificationUpdate({
          Type: 2,
          IsTurnOn: isGeneralNotificationOn ? 0 : 1,
          UserId: session.id,
        }),
      );
      setIsGeneralNotificationOn(!isGeneralNotificationOn);
    }
  };

  const handleItemClick = (event) => {
    event.stopPropagation();
  };

  //   const  = useMemo(() => {
  //     return BannerData && BannerData.length > 0
  //       ? BannerData.filter(async (data, index) => {
  //           let response = await checkValidImageUrl(
  //             ArticleImgUrl + data.imageurl,
  //           ).then((res) => res);
  //           if (response) {
  //             setValidBanners((ref) => [...ref, data]);
  //           }
  //           return response;
  //         })
  //       : [];
  //   }, [BannerData]);
  useEffect(() => {
    if (BannerData && BannerData.length > 0) {
      const filterValidBanners = async () => {
        const validBanners = [];
        for (const data of BannerData) {
          const isValid = await checkValidImageUrl(
            ArticleImgUrl + data.imageurl,
          );
          if (isValid) {
            validBanners.push(data);
          }
        }
        setValidBanners(validBanners);
      };

      filterValidBanners();
    }
  }, [BannerData]);

  //   useEffect(() => {
  //     console.log("memoized data => ", BannerData, validBanners);
  //   }, [validBanners]);

  return (
    <>
      <ShortsModal
        showShortsModal={showShortsModal}
        handleCloseModal={handleCloseModal}
        ShortsData={ShortsData}
      />
      <body className={viewSidebar ? "viewsidebar" : ""}>
        <div className="sidebar-menu">
          <ul>
            <li>
              <a href="/Packages"> Get Premium</a>
            </li>
            <li>
              <a href="/legal/terms">Terms &amp; Conditions</a>
            </li>
            <li>
              <a href="/legal/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="headertop-wrapper">
          <div
            style={{
              height: "40px",
              width: "fit-content",
              float: "left",
            }}
          >
            <img
              src={PrithviLogo}
              alt="Prithvi Logo"
              style={{
                width: "auto", // Adjust width as needed
                height: "100%", // Maintain aspect ratio
              }}
            />
          </div>
          <button
            className="bars-btn"
            id="barsbtn"
            onClick={handleBarsBtnClick}
          >
            <i
              id="menuicon"
              className={viewSidebar ? "fas fa-times" : "fas fa-bars"}
            />
          </button>
          {/* <button className="bars-btn" id="barsbtn" onClick={()=>navigate('/notification')}>
        <i className="fa-solid fa-bell"></i>
        </button> */}
          <button
            className="bars-btn"
            id="barsbtn"
            onClick={() => navigate("/search")}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdownMenuButton"
              type="button"
              className="settings-btn"
            >
              {" "}
              <i className="fa-solid fa-user"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right">
              <Dropdown.Item href="/profile">Profile</Dropdown.Item>
              <Dropdown.Item href="/chat">AskGuru</Dropdown.Item>
              <Dropdown.Item onClick={handleItemClick}>
                <ToggleButton
                  isOn={isForumNotificationOn}
                  handleToggle={handleForumNotificationToggle}
                  label="Forum"
                />
              </Dropdown.Item>
              <Dropdown.Item onClick={handleItemClick}>
                <ToggleButton
                  isOn={isGeneralNotificationOn}
                  handleToggle={handleGeneralNotificationToggle}
                  label="General"
                />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <button className="settings-btn">
    <i className="fa-solid fa-user"></i>
    </button> */}
        </div>
        <div className="mobileapp-scroll">
          <div className="banner-wrapper-div">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <Carousel
                    id="mobileBanner"
                    className="banner-wrapper"
                    fade={true}
                    interval={7000}
                    controls={true}
                    indicators={false}
                    pause="hover"
                  >
                    {validBanners.map((data, index) => {
                      return (
                        <Carousel.Item
                          key={index}
                          className="banner-container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className="d-block banner-mobile"
                            src={ArticleImgUrl + data.imageurl}
                            alt="The Prithvi"
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <AnnouncementSlide />
          <div className="prithvibuttons">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="row">
                    <div className="col-4">
                      <button
                        className="color1"
                        onClick={() => navigate("/Type/1")}
                      >
                        <span>
                          <img src={ArticleIcon} alt="Articles" />
                        </span>
                        <h6>Articles</h6>
                      </button>
                    </div>
                    <div className="col-4">
                      <button
                        className="color2"
                        onClick={() => navigate("/Type/2")}
                      >
                        <span>
                          <img src={VideoIcon} alt="Articles" />
                        </span>
                        <h6>Videos</h6>
                      </button>
                    </div>
                    <div className="col-4">
                      <button
                        className="color8"
                        onClick={() => navigate("/Type/4")}
                      >
                        <span>
                          <img src={Poster} alt="posters" />
                        </span>
                        <h6>Posters</h6>
                      </button>
                    </div>
                    <div className="col-4" onClick={() => navigate("/Type/3")}>
                      <button className="color3">
                        <span>
                          <img src={ImagesIcon} alt="Images" />
                        </span>
                        <h6>Puzzles</h6>
                      </button>
                    </div>
                    <div className="col-4">
                      <button
                        className="color8"
                        onClick={() => navigate("/Type/8")}
                      >
                        <span>
                          <img src={ShortsIcon} alt="Audios" />
                        </span>
                        <h6>Audios</h6>
                      </button>
                    </div>

                    <div className="col-4">
                      <button
                        className="color4"
                        onClick={() => navigate("/Webinar")}
                      >
                        <span>
                          <img src={BannersIcon} alt="Banners" />
                        </span>
                        <h6>Webinar</h6>
                      </button>
                    </div>
                    <div className="col-4">
                      <button
                        className="color5"
                        onClick={() => navigate("/Type/6")}
                      >
                        <span>
                          <img src={EbooksIcon} alt="E-books" />
                        </span>
                        <h6>E-books</h6>
                      </button>
                    </div>
                    <div className="col-4">
                      <button
                        className="color6"
                        onClick={() => navigate("/drug")}
                      >
                        <span>
                          <img src={DrugDatabaseIcon} alt="Drug Database" />
                        </span>
                        <h6>Drug Database</h6>
                      </button>
                    </div>
                    {/* <div className="col-4">
                    <button
                      className="color7"
                      onClick={() => navigate("/forum")}
                    >
                      <span>
                        <img src={ForumsIcon} alt="Forums" />
                      </span>
                      <h6>Forums</h6>
                    </button>
                  </div> */}

                    <div className="col-4">
                      <button
                        className="color9"
                        onClick={() => navigate("/chat")}
                      >
                        <span>
                          <img src={AskGuruIcon} alt="Ask Guru" />
                        </span>
                        <h6>Ask Guru</h6>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* artcile */}
        <div className="container">
  <div className="row" style={{ clear: "both", display: "flex" }}>
    <div className="col-lg-10 col-md-8 col-sm-12">
      <h2 className="Group-Heading">Latest Articles</h2>
    </div>
    <div className="col-lg-2 col-md-4">
      {/* <button
        className="viewall-btn"
        onClick={() => navigate(`/Group/${props.GroupData.Id}/Type/1`)}
      >
        View All
      </button> */}
    </div>
  </div>

  {/* <Slider {...settings}>
    {props.ArticleData?.map((data, index) => (
      <div className="col-lg-3 article-items-parent" key={index}>
        <button
          className={`article-item ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
          onClick={() => ArticleClick(data)}
        >
          <span className="Article-span">
            <img
              src={ArticleImgUrl + data.ImageUrl}
              alt="article"
            />
            <font className="link-icon">
              <i className="fas fa-link"></i>
            </font>
            <h6>
              <font>{formattedDate(data.Date)}</font>
            </h6>
          </span>
          <div className="article-content">
            <h5>{data.Heading}</h5>
            <p className="line-clamp-5" style={{ textAlign: "justify" }}>
              {htmlToPlainText(data.Description)}
            </p>
          </div>
        </button>
      </div>
    ))}
  </Slider> */}


<div className="slider-frame
">
<Slider {...settings}>
            {/* Hardcoded article 1 */}
            <div className="col-lg-3 article-items-parent">
              <button className="article-item">
                <span className="Article-span">
                  <img
                    src={trailImage}
                    alt="Demo Article"
                  />
                  <font className="link-icon">
                    <i className="fas fa-link"></i>
                  </font>
                  <h6>
                    <font>Jan 01, 2023</font>
                  </h6>
                </span>
                <div className="article-content">
                  <h5>Demo Article Heading 1</h5>
                  <p className="line-clamp-5" style={{ textAlign: "justify" }}>
                    This is a demo description for the first article. It will
                    not be too long and will showcase how the UI looks with
                    dummy text.
                  </p>
                </div>
              </button>
            </div>

            {/* Hardcoded article 2 */}
            <div className="col-lg-3 article-items-parent">
              <button className="article-item premium-icon">
                <span className="Article-span">
                  <img
                    src={trailImage}
                    alt="Demo Article"
                  />
                  <font className="link-icon">
                    <i className="fas fa-link"></i>
                  </font>
                  <h6>
                    <font>Feb 01, 2023</font>
                  </h6>
                </span>
                <div className="article-content">
                  <h5>Demo Article Heading 2 (Premium)</h5>
                  <p className="line-clamp-5" style={{ textAlign: "justify" }}>
                    This is a demo description for the second article. It is
                    premium and contains some placeholder content.
                  </p>
                </div>
              </button>
            </div>

            {/* Hardcoded article 3 */}
            <div className="col-lg-3 article-items-parent">
              <button className="article-item copy-icon">
                <span className="Article-span">
                  <img
                    src={trailImage}
                    alt="Demo Article"
                  />
                  <font className="link-icon">
                    <i className="fas fa-link"></i>
                  </font>
                  <h6>
                    <font>Mar 01, 2023</font>
                  </h6>
                </span>
                <div className="article-content">
                  <h5>Demo Article Heading 3 (Copyright)</h5>
                  <p className="line-clamp-5" style={{ textAlign: "justify" }}>
                    Placeholder description for the third article, showcasing
                    how copyright icons are handled in the UI.
                  </p>
                </div>
              </button>
            </div>

            {/* Hardcoded article 4 */}
            <div className="col-lg-3 article-items-parent">
              <button className="article-item">
                <span className="Article-span">
                  <img
                    src={trailImage}
                    alt="Demo Article"
                  />
                  <font className="link-icon">
                    <i className="fas fa-link"></i>
                  </font>
                  <h6>
                    <font>Apr 01, 2023</font>
                  </h6>
                </span>
                <div className="article-content">
                  <h5>Demo Article Heading 4</h5>
                  <p className="line-clamp-5" style={{ textAlign: "justify" }}>
                    This is another example of article content. It's a placeholder for the actual article data.
                  </p>
                </div>
              </button>
            </div>
          </Slider>
          </div>
</div>

      

        <MobileFooter />
      </body>
    </>
  );
}

export default MobileHomeScreen;

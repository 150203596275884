import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { forgotPasswordSchema } from "../../lib/schema";
import { twMerge as tw } from "tailwind-merge";
import { MdOutlineErrorOutline } from "react-icons/md";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Axios } from "axios";
import toast, { ToastBar } from "react-hot-toast";
import { ApiUrl } from "../../Constants/Config";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const ForgotPassword = () => {
  useHardwareBackButton(1)  
  const [searchParams, setSearchParams] = useSearchParams();
  const [spinner, setSpinner] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: searchParams.get("email") ?? "",
    },
  });

  const emailInput = useWatch({
    control,
    name: "email",
  });
  const redirect = useNavigate();

  

  const onSubmit = async (data) => {
    try {
      const response = fetch(`${ApiUrl}ForgotPassWord?Email=${data.email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());


      const res = await response;
      console.log("res after await => ", res);
      if (res && res[0]?.Error===0 && res[0].Id) {
        toast.success(`Password has been sent to ${data.email}.`)
        redirect(`/?email=${emailInput}`);
      }else{
        toast.error(`Invalid Email!`)
      }
    } catch (error) {
      console.error("error from catch => ", error);
      toast.error(`Something went wrong...`);
    } finally {
      setSpinner(false);
    }
  };


  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div
        className="bg-card text-card-foreground m-auto h-fit w-full max-w-sm rounded-lg border shadow-sm"
        data-v0-t="card"
      >
        <div className="flex flex-col space-y-1 p-6">
          <h3 className="whitespace-nowrap text-2xl font-bold tracking-tight">
            Forgot Password
          </h3>
          <p className="text-muted-foreground text-sm">
            Enter your email below to reset your password
          </p>
        </div>
        <div className="p-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="space-y-2">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="email"
              >
                Email
              </label>
              <div>
                <div className="relative h-full">
                  <input
                    type="text"
                    id="email"
                    className={tw(
                      `titext lowercase`,
                      errors.email ? "titext-error" : "",
                    )}
                    placeholder="example@domain.com"
                    {...register("email")}
                  />
                  {errors.email && (
                    <MdOutlineErrorOutline
                      className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                    />
                  )}
                </div>
                {errors.email && (
                  <span className="text-sm font-medium text-red-500">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </div>
            <button
              className={tw(`default-btn`, "w-full text-center")}
              type="submit"
              onClick={() => setSpinner(true)}
            >
              {spinner ? "Processing..." : "Reset Password"}
            </button>
          </form>
          <div className="mt-4 text-center text-sm">
            Remembered your password?{" "}
            <a className="font-semibold tracking-wide underline" href="/">
              Back to Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import { all } from 'redux-saga/effects';
import Common from '../Common/commonSaga';
import UserHome from '../UserHome/saga';

import AuthenticationSaga from '../Authentication/saga';
import ChatSaga from '../Chat/saga';
import ShortsSaga from '../shorts/saga';

export default function* rootSaga() {
	yield all([
		UserHome(),
		AuthenticationSaga(),
		Common(),
		ChatSaga(),
		ShortsSaga(),
	]);
}

import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes, getShortsError, getShortsSuccess } from './action';
import { startSpinner, stopSpinner } from '../Common/spinner/action';
import ShortsService from '../../Services/ShortsService';

function* GetShorts({ payload }) {
	try {
		yield put(startSpinner());
		const data = yield call(ShortsService.GetShorts, payload);
		yield put(getShortsSuccess(data));
	} catch (error) {
		yield put(getShortsError(error));
		console.log(error);
	} finally {
		yield put(stopSpinner());
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actionTypes.GET_SHORTS, GetShorts)]);
}

import React, { memo } from "react";
import { IoMdArrowDropright } from "react-icons/io";

const AnnouncementSearch = memo(({ rows }) => {
  return (
    <div className="mb-4 ">
      {rows.map((data, _) => {
        return (
          <div className="group mb-4 mt-2 rounded-md" key={_}>
            <div className="mb-2 line-clamp-3 text-justify text-sm">
              {data.Announcement}
            </div>
            <div className="flex flex-wrap items-center space-x-2">
              <div className="flex items-center space-x-1">
                <div className="text-xs capitalize tracking-widest text-gray-500">
                  Group
                </div>
                <IoMdArrowDropright className="text-gray-500" />
                <div className="flex w-fit items-center justify-center rounded-full border border-black bg-stone-100 px-1.5 py-0.5 text-xs lowercase tracking-widest text-gray-500">
                  {data.Name}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default AnnouncementSearch;

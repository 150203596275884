import React from 'react';
import '../Style/SwitchButton.css';

const ToggleButton = ({ isOn, handleToggle, label }) => {
    const switchButtonStyle = {
        left: isOn ? 'calc(100% - 2px)' : '0',
        transform: isOn ? 'translateX(-100%)' : 'translateX(0)',
      };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <span style={{ marginRight: '10px' }}>{label} Notification</span>
      <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleToggle}>
        <label
          style={{ background: isOn ? "#2196F3" : 'grey' }}
          className="react-switch-label"
        >
          <span className="react-switch-button" style={switchButtonStyle}/>
        </label>
      </div>
    </div>
  );
};

export default ToggleButton;

import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { MdWorkspacePremium } from 'react-icons/md';
import Slider from 'react-slick';
import { EbookImgUrl} from '../../Constants/Config';
import {  useNavigate } from 'react-router-dom';
import { getSession } from '../../lib/session';
import toast from 'react-hot-toast';
function Ebooks(props) {
  const navigate=useNavigate()
  const session = getSession();
  const settings = {
    centerMode: false,
    centerPadding: '0px',
    slidesToShow: 4,
    arrows: false,
    dots: true,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
  };
  const [selectedPdf, setSelectedPdf] = useState(null);
  
  const handlePdfClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      navigate(`/Ebook/${Data.PdfSecretKey}`)
    } else {
      toast.error("Please purchase a package");
    }
    
  };

  return (
    <>
      {props.EbookData?.length > 0 && (
        <div className="trendingvideos" >
          <div className="container">
          <div className="row" style={{ clear: "both", display: "flex" }}>
              <div className="col-lg-10 col-md-8 col-sm-12" onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/6`)
                    }>
                <h2 className='Group-Heading'>{props.GroupData.Heading}</h2>
              </div>
              {props.EbookData?.length>4&&<div className="col-lg-2 col-md-4 ">
                <button className="viewall-btn" onClick={()=>navigate(`/Group/${props.GroupData.Id}/Type/6`)} >View All</button>
              </div>}
            </div>
            <Slider {...settings}>
              {props.EbookData?.map((data, index) => (
               <div className="video-item" key={index}>
                <button
                  className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                  style={{ position: 'relative' }}
                  onClick={() => handlePdfClick(data)}
                >
                  <span className="premium-icon-span">
                    <img
                      src={EbookImgUrl + data.ThumbImg}
                      alt="peacegate"
                      style={{height:'300px',width:'100%'}}
                    />
                  </span>
                  <div className="videocontent" style={{padding:'0px',transitionDuration:'0s',height:'80px'}}>
                      <h6>{data.Heading}</h6>
                    </div>
                </button>
                </div>
              ))}
            </Slider>
          </div>
        </div>

      )}
    </>
  );
}

export default Ebooks;

import { actionTypes } from './action';

export const initialState = {
	GetUserState: null,
	RegisterUser: null,
	GetState: null,
	GetCountry: null,
	UserProfile: null,
	MultiLoginData:null,
	CheckMultiLogin:null,
	LogoutMultiUser:null
};

export const AuthenticationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER:
			return { ...state, GetUserState: null };
		case actionTypes.GET_USER_SUCCESS:
			return { ...state, GetUserState: action.payload };
		case actionTypes.GET_USER_ERROR:
			return { ...state, GetUserState: undefined };

		case actionTypes.POST_USER:
			return { ...state, RegisterUser: null };
		case actionTypes.POST_USER_SUCCESS:
			return { ...state, RegisterUser: action.payload };
		case actionTypes.POST_USER_ERROR:
			return { ...state, RegisterUser: 'Error' };

		case actionTypes.GET_STATE:
			return { ...state, GetState: null };
		case actionTypes.GET_STATE_SUCCESS:
			return { ...state, GetState: action.payload };
		case actionTypes.GET_STATE_ERROR:
			return { ...state, GetState: 'Error' };

		case actionTypes.GET_COUNTRY:
			return { ...state, GetCountry: null };
		case actionTypes.GET_COUNTRY_SUCCESS:
			return { ...state, GetCountry: action.payload };
		case actionTypes.GET_COUNTRY_ERROR:
			return { ...state, GetCountry: 'Error' };

		case actionTypes.GET_USER_PROFILE:
			return { ...state, UserProfile: null };
		case actionTypes.GET_USER_PROFILE_SUCCESS:
			return { ...state, UserProfile: action.payload };
		case actionTypes.GET_USER_PROFILE_ERROR:
			return { ...state, UserProfile: 'Error' };

		case actionTypes.RESTRICT_MULTI_LOGIN:
			return { ...state, MultiLoginData: null };
		case actionTypes.RESTRICT_MULTI_LOGIN_SUCCESS:
			return { ...state, MultiLoginData: action.payload };
		case actionTypes.RESTRICT_MULTI_LOGIN_ERROR:
			return { ...state, MultiLoginData: 'Error' };

		case actionTypes.CHECK_MULTI_LOGIN:
			return { ...state, CheckMultiLogin: null };
		case actionTypes.CHECK_MULTI_LOGIN_SUCCESS:
			return { ...state, CheckMultiLogin: action.payload };
		case actionTypes.CHECK_MULTI_LOGIN_ERROR:
			return { ...state, CheckMultiLogin: 'Error' };

		case actionTypes.LOGOUT_MULTI_USER:
			return { ...state, LogoutMultiUser: null };
		case actionTypes.LOGOUT_MULTI_USER_SUCCESS:
			return { ...state, LogoutMultiUser: action.payload };
		case actionTypes.LOGOUT_MULTI_USER_ERROR:
			return { ...state, LogoutMultiUser: 'Error' };
		default:
			return state;
	}
};

import React, { memo, useState } from 'react';
import {
    checkValidImageUrl,
    checkValidVideoUrl,
    typeMapper
} from '../../lib/helper';
import { Link } from 'react-router-dom';
import { ArticleImgUrl } from '../../Constants/Config';
import { IoMdArrowDropright } from 'react-icons/io';
import { FcRemoveImage } from 'react-icons/fc';

const ImageSearch = memo(({ rows }) => {
    return (
        <div className="mb-4 flex flex-wrap space-x-2">
            {rows.map((data, _) => {
                return (
                    <div
                        className=" mb-2 mt-2 w-fit flex-1 rounded-md border p-3 shadow-sm hover:shadow-md"
                        key={_}
                    >
                        {/* <div className="mb-2 cursor-pointer text-3xl capitalize text-blue-500 underline"> */}

                        {/* </div> */}
                        <ImageComponent data={data} />
                        <div className="flex flex-wrap items-center space-x-2">
                            <div className="flex items-center space-x-1">
                                <div className="text-xs capitalize tracking-widest">
                                    Group
                                </div>
                                <IoMdArrowDropright className="text-gray-500" />
                                <div className="flex w-fit items-center justify-center rounded-full border border-black bg-stone-100 px-1.5 py-0.5 text-xs lowercase tracking-widest text-gray-500">
                                    {data.Name}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

const ImageComponent = ({ data }) => {
    let url = `${ArticleImgUrl}${data.ImageUrl}`;
    const [isValid, setIsValid] = useState(false);

    let isValidImage = checkValidImageUrl(url).then((res) => {
        setIsValid(res);
        return res;
    });
    return (
        <div className="mb-2">
            <div className="mb-2">
                {isValid ? (
                    <img
                        src={url}
                        alt={data.alt ?? 'something went wrong...'}
                        className="rounded-md"
                    />
                ) : (
                    <div className="flex h-full w-full min-w-[140px] items-center justify-center rounded-lg  bg-zinc-100 p-3.5">
                        <div className="flex flex-col items-center justify-center space-x-2">
                            <FcRemoveImage className="mb-3 block h-8 w-8" />
                            <span className="text-[3vw] searchsm:text-xs">
                                Aw, Snap!
                            </span>
                            <span className="text-wrap text-center text-[3vw] searchsm:text-xs">
                                This Image is not available
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <div className="px-1 text-sm capitalize">
                {data.Heading?.toLowerCase()}
            </div>
        </div>
    );
};

export default ImageSearch;

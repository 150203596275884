export const actionTypes = {
	GET_SHORTS: 'GET_SHORTS',
	GET_SHORTS_SUCCESS: 'GET_SHORTS_SUCCESS',
	GET_SHORTS_ERROR: 'GET_SHORTS_ERROR',
};

export const getShorts = (payload) => {
	return {
		type: actionTypes.GET_SHORTS,
		payload,
	};
};

export const getShortsSuccess = (payload) => {
	return {
		type: actionTypes.GET_SHORTS_SUCCESS,
		payload,
	};
};

export const getShortsError = (payload) => {
	return {
		type: actionTypes.GET_SHORTS_ERROR,
		payload,
	};
};

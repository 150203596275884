import React, { useEffect } from 'react';
import LoginForm from './LoginForm';
import logo from '../../img/the-prithvi-logo.png';
import bg from '../../img/askguru-bg.jpg';
import { Link } from 'react-router-dom';
import { getSession } from '../../lib/session';
import { useNavigate } from 'react-router-dom';
import useHardwareBackButton from '../../Constants/HardwareBackButtonPress';

function Login() {
	useHardwareBackButton(1)  
	const redirect = useNavigate();
	const session = getSession();

	useEffect(() => {
		if (session) {
			redirect('user-home');
		}
	}, []);

	return (
		<div className="flex h-screen w-screen">
			<div className="hidden h-full w-full flex-1 bg-logoBg lg:flex lg:items-center lg:justify-center ">
				<img src={logo} alt="logo" className="aspect-square " />
			</div>
			<div className="flex h-full w-full flex-1 flex-col justify-center shadow-lg lg:max-w-lg lg:flex-none">
				<LoginForm />
				<Link
					to={'register'}
					className={`w-full bg-brown p-3 text-center font-medium text-white hover:bg-darkBrown`}
				>
					Create An Account
				</Link>
			</div>
		</div>
	);
}

export default Login;

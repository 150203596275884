import { actionTypes } from './action';

export const initialState = {
	shorts: [],
};

export const ShortsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SHORTS:
			return { ...state, shorts: [] };
		case actionTypes.GET_SHORTS_SUCCESS:
			return { ...state, shorts: action.payload };
		case actionTypes.GET_SHORTS_ERROR:
			return { ...state, shorts: 'error' };

		default:
			return state;
	}
};

import React from 'react'
function DesktopFooter() {
  return (
    <div className="footer-wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-7">
        © 2021 The Prithvi. All rights reserved.
      </div>
      <div className="col-lg-6 col-md-6 col-sm-5">
        <span>
          Designed by{" "}
          <a href="https://www.initsolutions.in/" target="_blank">
            InIT Solutions
          </a>
        </span>
      </div>
    </div>
  </div>
</div>

  )
}

export default DesktopFooter
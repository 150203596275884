import React from 'react';

import { Button, Timeline } from 'flowbite-react';
import { HiArrowNarrowRight, HiCalendar } from 'react-icons/hi';
import { getTimeAgoString } from '../../Constants/FormattedDate';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MdComment } from 'react-icons/md';

export const Timelines = ({ CommentData }) => {
	return (
		<div className="p-4" dir="ltr">
			<ol className="relative order-none list-none !border-s">
				{CommentData?.map((data, _) => {
					return <Comments key={_} data={data} />;
				})}
			</ol>
		</div>
	);
};

const Comments = ({ data }) => {
	return (
		<li className="mb-10 ms-2 md:!ms-10">
			<span className="absolute -start-4 flex items-center justify-center rounded-full bg-blue-100 p-2 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
				<MdComment className="" />
			</span>
			<div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-600 dark:bg-gray-700">
				<div className="mb-3 items-center justify-between sm:flex">
					<time className="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
						{getTimeAgoString(data.SysTime)}
					</time>
					<div className="lex text-sm font-normal text-gray-500 dark:text-gray-300">
						{data.Name} commented{' '}
						<a
							href="#"
							className="font-semibold text-gray-900 hover:underline dark:text-white"
						>
							{}
						</a>
					</div>
				</div>
				<div className="rounded-lg border border-gray-200 bg-gray-50 p-3 text-xs font-normal">
					
					<Markdown remarkPlugins={[remarkGfm]}>{data.Comment}</Markdown>
				</div>
			</div>
		</li>
	);
};

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB2Tzg0qGYCn2V033EaPBjPAJEV8cryGS8",
  authDomain: "prithvi-app.firebaseapp.com",
  projectId: "prithvi-app",
  storageBucket: "prithvi-app.appspot.com",
  messagingSenderId: "252829688526",
  appId: "1:252829688526:web:cc961b9bb961a2dc5da69f",
  measurementId: "G-ZY5R7JTMLB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const genarateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log("permission", permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BOJUT0739ITCv8BQZNjeqYBqSdYtNZjP5E-FfwEHii_VlBrJ6jzA_3QVqHKlrkCWFUZl9lb6SOvFPSOpH7RlVn8",
    });
    return token;
  }
};

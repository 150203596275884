export const actionTypes = {
  GET_PRINCIPLE_GROUP: "GET_PRINCIPLE_GROUP",
  GET_PRINCIPLE_GROUP_SUCCESS: "GET_PRINCIPLE_GROUP_SUCCESS",
  GET_PRINCIPLE_GROUP_ERROR: "GET_PRINCIPLE_GROUP_ERROR",

  GET_ARTICLE: "GET_ARTICLE",
  GET_ARTICLE_SUCCESS: "GET_ARTICLE_SUCCESS",
  GET_ARTICLE_ERROR: "GET_ARTICLE_ERROR",

  GET_SINGLE_ARTICLE: "GET_SINGLE_ARTICLE",
  GET_SINGLE_ARTICLE_SUCCESS: "GET_SINGLE_ARTICLE_SUCCESS",
  GET_SINGLE_ARTICLE_ERROR: "GET_SINGLE_ARTICLE_ERROR",

  GET_ALL_FORUM: "GET_ALL_FORUM",
  GET_ALL_FORUM_SUCCESS: "GET_ALL_FORUM_SUCCESS",
  GET_ALL_FORUM_ERROR: "GET_ALL_FORUM_ERROR",

  GET_FORUM_MESSAGES: "GET_FORUM_MESSAGES",
  GET_FORUM_MESSAGES_SUCCESS: "GET_FORUM_MESSAGES_SUCCESS",
  GET_FORUM_MESSAGES_ERROR: "GET_FORUM_MESSAGES_ERROR",

  MESSAGE_INSERT_FORUM: "MESSAGE_INSERT_FORUM",
  MESSAGE_INSERT_FORUM_SUCCESS: "MESSAGE_INSERT_FORUM_SUCCESS",
  MESSAGE_INSERT_FORUM_ERROR: "MESSAGE_INSERT_FORUM_ERROR",

  CREATE_FORUM: "CREATE_FORUM",
  CREATE_FORUM_SUCCESS: "CREATE_FORUM_SUCCESS",
  CREATE_FORUM_ERROR: "CREATE_FORUM_ERROR",

  GET_WEBINAR: "GET_WEBINAR",
  GET_WEBINAR_SUCCESS: "GET_WEBINAR_SUCCESS",
  GET_WEBINAR_ERROR: "GET_WEBINAR_ERROR",

  GET_PACKAGES: "GET_PACKAGES",
  GET_PACKAGES_SUCCESS: "GET_PACKAGES_SUCCESS",
  GET_PACKAGES_ERROR: "GET_PACKAGES_ERROR",

  SEARCH_DRUG: "SEARCH_DRUG",
  SEARCH_DRUG_SUCCESS: "SEARCH_DRUG_SUCCESS",
  SEARCH_DRUG_ERROR: "SEARCH_DRUG_ERROR",

  GET_SINGLE_FORUM: "GET_SINGLE_FORUM",
  GET_SINGLE_FORUM_SUCCESS: "GET_SINGLE_FORUM_SUCCESS",
  GET_SINGLE_FORUM_ERROR: "GET_SINGLE_FORUM_ERROR",

  GET_TWO_FORUM: "GET_TWO_FORUM",
  GET_TWO_FORUM_SUCCESS: "GET_TWO_FORUM_SUCCESS",
  GET_TWO_FORUM_ERROR: "GET_TWO_FORUM_ERROR",

  GET_DRUG_WITH_ID: "GET_DRUG_WITH_ID",
  GET_DRUG_WITH_ID_SUCCESS: "GET_DRUG_WITH_ID_SUCCESS",
  GET_DRUG_WITH_ID_ERROR: "GET_DRUG_WITH_ID_ERROR",

  GET_ALL_DRUG: "GET_ALL_DRUG",
  GET_ALL_DRUG_SUCCESS: "GET_ALL_DRUG_SUCCESS",
  GET_ALL_DRUG_ERROR: "GET_ALL_DRUG_ERROR",

  PACKAGES_PURCHASE: "PACKAGES_PURCHASE",
  PACKAGES_PURCHASE_SUCCESS: "PACKAGES_PURCHASE_SUCCESS",
  PACKAGES_PURCHASE_ERROR: "PACKAGES_PURCHASE_ERROR",

  GET_GROUP_BY_TYPE: "GET_GROUP_BY_TYPE",
  GET_GROUP_BY_TYPE_SUCCESS: "GET_GROUP_BY_TYPE_SUCCESS",
  GET_GROUP_BY_TYPE_ERROR: "GET_GROUP_BY_TYPE_ERROR",

  GET_ANNOUNCEMENT: "GET_ANNOUNCEMENT",
  GET_ANNOUNCEMENT_SUCCESS: "GET_ANNOUNCEMENT_SUCCESS",
  GET_ANNOUNCEMENT_ERROR: "GET_ANNOUNCEMENT_ERROR",

  GET_BANNER_DATA: "GET_BANNER_DATA",
  GET_BANNER_DATA_SUCCESS: "GET_BANNER_DATA_SUCCESS",
  GET_BANNER_DATA_ERROR: "GET_BANNER_DATA_ERROR",

  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  PUSH_NOTIFICATION_SUCCESS: "PUSH_NOTIFICATION_SUCCESS",
  PUSH_NOTIFICATION_ERROR: "PUSH_NOTIFICATION_ERROR",

  GET_DATA_USING_GID: "GET_DATA_USING_GID",
  GET_DATA_USING_GID_SUCCESS: "GET_DATA_USING_GID_SUCCESS",
  GET_DATA_USING_GID_ERROR: "GET_DATA_USING_GID_ERROR",

    GET_NOTIFICATION: " GET_NOTIFICATION",
    GET_NOTIFICATION_SUCCESS: " GET_NOTIFICATION_SUCCESS",
    GET_NOTIFICATION_ERROR: " GET_NOTIFICATION_ERROR",

    READ_NOTIFICATION: " READ_NOTIFICATION",
    READ_NOTIFICATION_SUCCESS: " READ_NOTIFICATION_SUCCESS",
    READ_NOTIFICATION_ERROR: " READ_NOTIFICATION_ERROR",

    GETGROUPDATA_TYPEID: " GETGROUPDATA_TYPEID",
    GETGROUPDATA_TYPEID_SUCCESS: " GETGROUPDATA_TYPEID_SUCCESS",
    GETGROUPDATA_TYPEID_ERROR: " GETGROUPDATA_TYPEID_ERROR",

    GET_SINGLE_SHORTS: " GET_SINGLE_SHORTS",
    GET_SINGLE_SHORTS_SUCCESS: " GET_SINGLE_SHORTS_SUCCESS",
    GET_SINGLE_SHORTS_ERROR: " GET_SINGLE_SHORTS_ERROR",

    UPDATE_SHORTS_STATUS: " UPDATE_SHORTS_STATUS",
    UPDATE_SHORTS_STATUS_SUCCESS: " UPDATE_SHORTS_STATUS_SUCCESS",
    UPDATE_SHORTS_STATUS_ERROR: " UPDATE_SHORTS_STATUS_ERROR",

    PUSH_NOTIFICATION_UPDATE: " PUSH_NOTIFICATION_UPDATE",
    PUSH_NOTIFICATION_UPDATE_SUCCESS: " PUSH_NOTIFICATION_UPDATE_SUCCESS",
    PUSH_NOTIFICATION_UPDATE_ERROR: " PUSH_NOTIFICATION_UPDATE_ERROR",

    GET_PUSHNOTIFICATION_STATUS: " GET_PUSHNOTIFICATION_STATUS",
    GET_PUSHNOTIFICATION_STATUS_SUCCESS: " GET_PUSHNOTIFICATION_STATUS_SUCCESS",
    GET_PUSHNOTIFICATION_STATUS_ERROR: " GET_PUSHNOTIFICATION_STATUS_ERROR",
};
export const GetPrincipleGroup = (payload) => {
  return {
    type: actionTypes.GET_PRINCIPLE_GROUP,
    payload,
  };
};
export const GetPrincipleGroupSucces = (payload) => {
  return {
    type: actionTypes.GET_PRINCIPLE_GROUP_SUCCESS,
    payload,
  };
};
export const GetPrincipleGroupError = (payload) => {
  return {
    type: actionTypes.GET_PRINCIPLE_GROUP_ERROR,
    payload,
  };
};

export const GetArticle = (payload) => {
  return {
    type: actionTypes.GET_ARTICLE,
    payload,
  };
};
export const GetArticleSucces = (payload) => {
  return {
    type: actionTypes.GET_ARTICLE_SUCCESS,
    payload,
  };
};
export const GetArticleError = (payload) => {
  return {
    type: actionTypes.GET_ARTICLE_ERROR,
    payload,
  };
};

export const GetSingleArticle = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_ARTICLE,
    payload,
  };
};
export const GetSingleArticleSucces = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_ARTICLE_SUCCESS,
    payload,
  };
};
export const GetSingleArticleError = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_ARTICLE_ERROR,
    payload,
  };
};

export const GetAllForum = (payload) => {
  return {
    type: actionTypes.GET_ALL_FORUM,
    payload,
  };
};
export const GetAllForumSucces = (payload) => {
  return {
    type: actionTypes.GET_ALL_FORUM_SUCCESS,
    payload,
  };
};
export const GetAllForumError = (payload) => {
  return {
    type: actionTypes.GET_ALL_FORUM_ERROR,
    payload,
  };
};

export const GetForumMessages = (payload) => {
  return {
    type: actionTypes.GET_FORUM_MESSAGES,
    payload,
  };
};
export const GetForumMessagesSucces = (payload) => {
  return {
    type: actionTypes.GET_FORUM_MESSAGES_SUCCESS,
    payload,
  };
};
export const GetForumMessagesError = (payload) => {
  return {
    type: actionTypes.GET_FORUM_MESSAGES_ERROR,
    payload,
  };
};

export const InsertForumMessages = (payload) => {
  return {
    type: actionTypes.MESSAGE_INSERT_FORUM,
    payload,
  };
};
export const InsertForumMessagesSucces = (payload) => {
  return {
    type: actionTypes.MESSAGE_INSERT_FORUM_SUCCESS,
    payload,
  };
};
export const InsertForumMessagesError = (payload) => {
  return {
    type: actionTypes.MESSAGE_INSERT_FORUM_ERROR,
    payload,
  };
};

export const CreateForum = (payload) => {
  return {
    type: actionTypes.CREATE_FORUM,
    payload,
  };
};
export const CreateForumSucces = (payload) => {
  return {
    type: actionTypes.CREATE_FORUM_SUCCESS,
    payload,
  };
};
export const CreateForumError = (payload) => {
  return {
    type: actionTypes.CREATE_FORUM_ERROR,
    payload,
  };
};

export const GetWebinar = (payload) => {
  return {
    type: actionTypes.GET_WEBINAR,
    payload,
  };
};
export const GetWebinarSucces = (payload) => {
  return {
    type: actionTypes.GET_WEBINAR_SUCCESS,
    payload,
  };
};
export const GetWebinarError = (payload) => {
  return {
    type: actionTypes.GET_WEBINAR_ERROR,
    payload,
  };
};

export const GetAllPackgaes = (payload) => {
  return {
    type: actionTypes.GET_PACKAGES,
    payload,
  };
};
export const GetAllPackgaesSucces = (payload) => {
  return {
    type: actionTypes.GET_PACKAGES_SUCCESS,
    payload,
  };
};
export const GetAllPackgaesError = (payload) => {
  return {
    type: actionTypes.GET_PACKAGES_ERROR,
    payload,
  };
};

export const SearchDrugItem = (payload) => {
  return {
    type: actionTypes.SEARCH_DRUG,
    payload,
  };
};
export const SearchDrugItemSucces = (payload) => {
  return {
    type: actionTypes.SEARCH_DRUG_SUCCESS,
    payload,
  };
};
export const SearchDrugItemError = (payload) => {
  return {
    type: actionTypes.SEARCH_DRUG_ERROR,
    payload,
  };
};

export const GetSingleForum = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_FORUM,
    payload,
  };
};
export const GetSingleForumSucces = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_FORUM_SUCCESS,
    payload,
  };
};
export const GetSingleForumError = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_FORUM_ERROR,
    payload,
  };
};

export const GetTwoForum = (payload) => {
  return {
    type: actionTypes.GET_TWO_FORUM,
    payload,
  };
};
export const GetTwoForumSucces = (payload) => {
  return {
    type: actionTypes.GET_TWO_FORUM_SUCCESS,
    payload,
  };
};
export const GetTwoForumError = (payload) => {
  return {
    type: actionTypes.GET_TWO_FORUM_ERROR,
    payload,
  };
};

export const GetDrugWithId = (payload) => {
  return {
    type: actionTypes.GET_DRUG_WITH_ID,
    payload,
  };
};
export const GetDrugWithIdSucces = (payload) => {
  return {
    type: actionTypes.GET_DRUG_WITH_ID_SUCCESS,
    payload,
  };
};
export const GetDrugWithIdError = (payload) => {
  return {
    type: actionTypes.GET_DRUG_WITH_ID_ERROR,
    payload,
  };
};

export const GetAllDrug = (payload) => {
  return {
    type: actionTypes.GET_ALL_DRUG,
    payload,
  };
};
export const GetAllDrugSucces = (payload) => {
  return {
    type: actionTypes.GET_ALL_DRUG_SUCCESS,
    payload,
  };
};
export const GetAllDrugError = (payload) => {
  return {
    type: actionTypes.GET_ALL_DRUG_ERROR,
    payload,
  };
};

export const PackagesPurchase = (payload) => {
  return {
    type: actionTypes.PACKAGES_PURCHASE,
    payload,
  };
};
export const PackagesPurchaseSucces = (payload) => {
  return {
    type: actionTypes.PACKAGES_PURCHASE_SUCCESS,
    payload,
  };
};
export const PackagesPurchaseError = (payload) => {
  return {
    type: actionTypes.PACKAGES_PURCHASE_ERROR,
    payload,
  };
};

export const GetGroupByType = (payload) => {
  return {
    type: actionTypes.GET_GROUP_BY_TYPE,
    payload,
  };
};
export const GetGroupByTypeSucces = (payload) => {
  return {
    type: actionTypes.GET_GROUP_BY_TYPE_SUCCESS,
    payload,
  };
};
export const GetGroupByTypeError = (payload) => {
  return {
    type: actionTypes.GET_GROUP_BY_TYPE_ERROR,
    payload,
  };
};

export const GetAnnouncement = (payload) => {
  return {
    type: actionTypes.GET_ANNOUNCEMENT,
    payload,
  };
};
export const GetAnnouncementSucces = (payload) => {
  return {
    type: actionTypes.GET_ANNOUNCEMENT_SUCCESS,
    payload,
  };
};
export const GetAnnouncementError = (payload) => {
  return {
    type: actionTypes.GET_ANNOUNCEMENT_ERROR,
    payload,
  };
};

export const GetBannerData = (payload) => {
  return {
    type: actionTypes.GET_BANNER_DATA,
    payload,
  };
};
export const GetBannerDataSucces = (payload) => {
  return {
    type: actionTypes.GET_BANNER_DATA_SUCCESS,
    payload,
  };
};
export const GetBannerDataError = (payload) => {
  return {
    type: actionTypes.GET_BANNER_DATA_ERROR,
    payload,
  };
};

export const PushNotification = (payload) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION,
    payload,
  };
};
export const PushNotificationSucces = (payload) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_SUCCESS,
    payload,
  };
};
export const PushNotificationError = (payload) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_ERROR,
    payload,
  };
};

export const GetDataUsingGrpId = (payload) => {
  return {
    type: actionTypes.GET_DATA_USING_GID,
    payload,
  };
};
export const GetDataUsingGrpIdSucces = (payload) => {
  return {
    type: actionTypes.GET_DATA_USING_GID_SUCCESS,
    payload,
  };
};
export const GetDataUsingGrpIdError = (payload) => {
  return {
    type: actionTypes.GET_DATA_USING_GID_ERROR,
    payload,
  };
};

export const GetNotification = (payload) => {
  return {
    type: actionTypes.GET_NOTIFICATION,
    payload,
  };
};
export const GetNotificationSucces = (payload) => {
  return {
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    payload,
  };
};
export const GetNotificationError = (payload) => {
  return {
    type: actionTypes.GET_NOTIFICATION_ERROR,
    payload,
  };
};

export const ReadNotification = (payload) => {
  return {
    type: actionTypes.READ_NOTIFICATION,
    payload,
  };
};
export const ReadNotificationSucces = (payload) => {
  return {
    type: actionTypes.READ_NOTIFICATION_SUCCESS,
    payload,
  };
};
export const ReadNotificationError = (payload) => {
  return {
    type: actionTypes.READ_NOTIFICATION_ERROR,
    payload,
  };
};

export const GetGroupDataByType = (payload) => {
  return {
    type: actionTypes.GETGROUPDATA_TYPEID,
    payload,
  };
};
export const GetGroupDataByTypeSucces = (payload) => {
  return {
    type: actionTypes.GETGROUPDATA_TYPEID_SUCCESS,
    payload,
  };
};
export const GetGroupDataByTypeError = (payload) => {
  return {
    type: actionTypes.GETGROUPDATA_TYPEID_ERROR,
    payload,
  };
};

export const GetSingleShort = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_SHORTS,
    payload,
  };
};
export const GetSingleShortSucces = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_SHORTS_SUCCESS,
    payload,
  };
};
export const GetSingleShortError = (payload) => {
  return {
    type: actionTypes.GET_SINGLE_SHORTS_ERROR,
    payload,
  };
};

export const UpdateShortStatus = (payload) => {
  return {
    type: actionTypes.UPDATE_SHORTS_STATUS,
    payload,
  };
};
export const UpdateShortStatusSucces = (payload) => {
  return {
    type: actionTypes.UPDATE_SHORTS_STATUS_SUCCESS,
    payload,
  };
};
export const UpdateShortStatusError = (payload) => {
  return {
    type: actionTypes.UPDATE_SHORTS_STATUS_ERROR,
    payload,
  };
};

export const PushNotificationUpdate = (payload) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_UPDATE,
    payload,
  };
};
export const PushNotificationUpdateSucces = (payload) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_UPDATE_SUCCESS,
    payload,
  };
};
export const PushNotificationUpdateError = (payload) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_UPDATE_ERROR,
    payload,
  };
};

export const GetNotificationStatus = (payload) => {
  return {
    type: actionTypes.GET_PUSHNOTIFICATION_STATUS,
    payload,
  };
};
export const GetNotificationStatusSucces = (payload) => {
  return {
    type: actionTypes.GET_PUSHNOTIFICATION_STATUS_SUCCESS,
    payload,
  };
};
export const GetNotificationStatusError = (payload) => {
  return {
    type: actionTypes.GET_PUSHNOTIFICATION_STATUS_ERROR,
    payload,
  };
};


import React, { memo, useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { ArticleImgUrl } from "../../Constants/Config";
import { checkValidImageUrl } from "../../lib/helper";
import { FcRemoveImage } from "react-icons/fc";

const BannerSearch = memo(({ rows }) => {
  return (
    <div className="mb-4 ">
      {rows.map((data, _) => {
        return (
          <div className="group mb-4 mt-2 rounded-md" key={_}>
            {/* <div className="mb-2">
                            <img
                                src={`${ArticleImgUrl}${data.ImageUrl}`}
                                alt={data.Heading ?? 'something went wrong...'}
                                className="rounded-md"
                            />
                        </div>
                        <div className="mb-2 line-clamp-3 text-justify text-sm">
                            {data.Heading}
                        </div> */}
            <BannerComponent data={data} />
            <div className="flex items-center space-x-2">
              <div className="flex w-fit  flex-wrap  items-center space-x-1">
                <div className="text-xs capitalize tracking-widest text-gray-500">
                  Group
                </div>
                <IoMdArrowDropright className="text-gray-500" />
                <div className="flex w-fit items-center justify-center whitespace-nowrap rounded-full border border-black bg-stone-100 px-1.5 py-0.5 text-xs lowercase tracking-widest text-gray-500">
                  {data.Name}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

const BannerComponent = ({ data }) => {
  let url = `${ArticleImgUrl}${data.ImageUrl}`;
  const [isValid, setIsValid] = useState(false);

  let isValidImage = checkValidImageUrl(url).then((res) => {
    setIsValid(res);
    return res;
  });

  return (
    <div className="mb-2">
      <div className="mb-2">
        {isValid ? (
          <img
            src={url}
            alt={data.alt ?? "something went wrong..."}
            className="h-auto w-full max-w-[800px] rounded-md"
          />
        ) : (
          <div className="flex h-full w-full max-w-[800px] items-center justify-center rounded-lg bg-zinc-100 p-3.5 py-4">
            <div className="flex flex-col items-center justify-center space-x-2">
              <FcRemoveImage className="mb-3 block h-8 w-8" />
              <span className="text-[3vw] searchsm:text-xs">Aw, Snap!</span>
              <span className="text-wrap text-center text-[3vw] searchsm:text-xs">
                This Banner is not available
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="px-1 text-sm capitalize">
        {data.Heading?.toLowerCase()}
      </div>
    </div>
  );
};

export default BannerSearch;

import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";

function VideoDisplayModal({ showModal, handleClose, currentVideo }) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handlePlay = () => {
      const iframe = iframeRef.current;
      if (iframe && iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe && iframe.mozRequestFullScreen) { // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe && iframe.webkitRequestFullscreen) { // Chrome, Safari and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe && iframe.msRequestFullscreen) { // IE/Edge
        iframe.msRequestFullscreen();
      }
    };

    const iframe = iframeRef.current;

    if (iframe) {
      iframe.addEventListener("load", handlePlay); // Trigger fullscreen on video load
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handlePlay);
      }
    };
  }, []);

  return (
    <Modal show={showModal} centered>
      <Modal.Body style={{ padding: 0 }}>
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%", // 16:9 aspect ratio (height/width) 
            height: 0,
          }}
        >
          <iframe
            ref={iframeRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={currentVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <button
            style={{
              backgroundColor: "rgb(239 239 239)",
              border: "none",
              borderRadius: "50%",
              boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px",
              color: "rgb(0 0 0)",
              fontSize: "16px",
              height: "23px",
              margin: "0px",
              opacity: "1",
              outline: "0px",
              padding: "0px",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              width: "23px",
            }}
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default VideoDisplayModal;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CreateForum, GetAllForum } from "../../Redux/UserHome/action";
import { twMerge } from "tailwind-merge";
import { FaSearch,FaTimes } from "react-icons/fa";
import { useSearchParams, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import * as Dialog from "@radix-ui/react-dialog";
import { IoClose } from "react-icons/io5";
import { getSession } from "../../lib/session";
import toast from "react-hot-toast";
import { ImSpinner2 } from "react-icons/im";
import { FormattedDate2 } from "../../Constants/FormattedDate";
import { htmlToPlainText, stripHtmlTags } from "../../lib/helper";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import { Modal, Button } from 'react-bootstrap';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
const ForumPage = () => {
  const ForumData = useSelector((state) => state.UserHomeReducer.GetAllForum);
  const response = useSelector((state) => state.UserHomeReducer.CreateForum);
  const redirect = useNavigate();
  const handleBackButtonPress=()=>{
    if (open) {
      setOpen(false)
    } else {
      redirect('/user-home')
    }
   
  }
  useHardwareBackButton(0,handleBackButtonPress) 
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const session = getSession();
  useEffect(() => {
    dispatch(GetAllForum());
    setOpen(false);
  }, [response]);

  return (
    <div className="flex h-screen flex-col ">
      <div className="w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999]  flex h-[42px] w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          {/* <div className={"flex h-[42px]  items-center justify-center "}></div> */}
          <button className={"default-btn mr-0"} onClick={() => setOpen(true)}>
            Add Forum
          </button>
          <AddForum
            dispatch={dispatch}
            session={session}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
      <div className="text-center">
        <h2 className="color: [#003a66] min-h-[50px] w-full bg-[#e5ebef] text-center text-[18px] capitalize leading-[50px]">
          Forum
        </h2>
      </div>
      {/* <div className="flex w-full items-center justify-between">
				<Link to={'/user-home'}>
					<div className="flex items-center justify-center bg-brown p-3">
						<FaChevronLeft className="h-5 w-5 text-white" />
					</div>
				</Link>
				<AddForum
					dispatch={dispatch}
					session={session}
					open={open}
					setOpen={setOpen}
				/>
			</div> */}
      {/* <div className="Article-Modal home-slider ">
				<div className="page-header-con">
					<button className="backbtn">
						<i className="fas fa-chevron-left"></i>
					</button>
					<AddForum
						dispatch={dispatch}
						session={session}
						open={open}
						setOpen={setOpen}
					/>
				</div>
			</div> */}
      <div className="container mx-auto flex-1 px-4 pb-20 pt-3">
        <div className={``}>
          <div className="mt-3">
            <ForumForm />
          </div>

          <div className="mt-4">
            <ForumList forum={ForumData} />
          </div>
        </div>
      </div>
      <CombinedFooter className={""} />
    </div>
  );
};

// const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));

export const AddForum = ({ dispatch, session, open, setOpen }) => {
  const spinner = useSelector((state) => state.Common.spinner);
  const redirect = useNavigate();
  const [state, setState] = useState({
    Heading: "",
    Description: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    console.log("state => ", state, session);
    if (!session) {
      toast.error("You need to login to create a forum");
      return redirect("/");
    }

    if (!state.Description || !state.Heading) {
      toast.error("Please fill all the fields");
      e.preventDefault();
      return;
    }

    const payload = { ...state, CreatedBy: session.id };

    dispatch(CreateForum(payload));
    setOpen(false);
    setState({
      Heading: "",
      Description: "",
    });
    e.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add A Forum</Modal.Title>
        <Button variant="transparent" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'none', fontSize: '20px' }}>
          <FaTimes />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p>Enhance your forum experience by contributing your thoughts and sparking discussions in our dynamic community platform.</p>
        <form onSubmit={handleSubmit} style={{ marginTop: '10px' }}>
          <fieldset className="mb-[15px]">
            <label htmlFor="title">Forum Title<span className="text-red-500">*</span></label>
            <input
              className="form-control"
              id="title"
              placeholder="Title"
              name="Heading"
              value={state.Heading}
              onChange={handleChange}
            />
          </fieldset>
          <fieldset className="mb-[15px]">
            <label htmlFor="description">Description<span className="text-red-500">*</span></label>
            <textarea
              className="form-control"
              rows={5}
              name="Description"
              id="description"
              placeholder="Description"
              value={state.Description}
              onChange={handleChange}
            />
          </fieldset>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ background: '#b78751', border: 'none' }} onClick={handleSubmit} disabled={!state.Description || !state.Heading}>
          {spinner ? "Adding Forum" : "Add Forum"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ForumList = ({ forum }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  //   const value = searchParams.get("search");
  const redirect = useNavigate();
  if (!forum || forum.length === 0) {
    return (
      <p className="mt-5 text-center text-lg text-gray-500">
        No data available
      </p>
    );
  }

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 divide-y divide-gray-100 md:grid-cols-2"
    >
      {forum
        ?.filter((el) =>
          searchParams.get("search")
            ? el.Heading?.toLowerCase().includes(
                searchParams.get("search").toLowerCase(),
              )
            : el,
        )
        ?.map((item) => (
          <div
            onClick={() => redirect(`/forum/${item.Id}`)}
            key={item.Id}
            className="flex cursor-pointer flex-col overflow-hidden rounded-md bg-forumBg hover:text-zinc-900"
          >
            <div
              className={`flex items-start justify-between bg-forumBgSecondary px-2 py-3`}
            >
              <div className="mb-2 text-sm font-semibold text-zinc-900">
                {item.Heading}
              </div>
              <div className="flex items-center space-x-2">
                <div className="whitespace-nowrap px-2">
                  {FormattedDate2(item.SysTime)}
                </div>
              </div>
            </div>
            <div className="flex min-w-0 flex-1 gap-x-4 px-2 py-3">
              <div className="min-w-0 flex-auto">
                {/* <p className="mb-2 text-sm font-semibold text-zinc-900">
                  {item.Heading}
                </p> */}
                {/* <p className="my-0 mt-1 line-clamp-3 w-full max-w-full text-xs text-gray-500">
                  
                  {htmlToPlainText(item.Description)}
                </p> */}
                <Markdown remarkPlugins={[remarkGfm]}>{htmlToPlainText(item.Description)}</Markdown>
              </div>
            </div>
            <div
              className={`flex items-center justify-between bg-forumBgSecondary px-2 py-3`}
            >
              <p className="mb-2 text-sm font-medium text-zinc-900">
                {item.Name ?? "Guest User"}
              </p>
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <i className="fas fa-user" />
                  <div>{item.TotalUsers}</div>
                </div>
                <div className="flex items-center space-x-2">
                  <i className="far fa-comment" />
                  <div>{item.TotalComments}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </ul>
  );
};

const ForumForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(searchParams.get("search") || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchParams({ search: state });
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="relative">
        <input
          type={"text"}
          placeholder="Search a forum..."
          className={twMerge("titext", "!rounded-full px-3 py-2")}
          value={state}
          onChange={(e) => {
            setSearchParams({ search: e.target.value });
            setState(e.target.value);
          }}
        />

        <div className="absolute inset-y-0 right-0 mr-5 flex items-center justify-center space-x-2">
          <button
            type={"button"}
            className={`${state ? "translate-x-0 scale-100" : "translate-x-full scale-0"} transform transition-transform`}
            onClick={() => {
              setState("");
              setSearchParams({ search: "" });
            }}
          >
            <MdOutlineClose
              className={`h-5 w-5 transition-all ${state ? "opacity-100" : "opacity-0"}`}
            />
          </button>

          <button type={"submit"} className="">
            <FaSearch
              className={`h-4 w-4 transition-all ${state ? "text-zinc-800" : "text-zinc-500"}`}
            />
          </button>
        </div>
      </div>
    </form>
  );
};

export default ForumPage;

import React, { useState } from "react";
import toast from "react-hot-toast";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useNavigate } from "react-router-dom";
import { ArticleImgUrl } from "../../Constants/Config";
import { getSession } from "../../lib/session";

function ImageGallery(props) {
  const navigate = useNavigate();
  const session = getSession();
  const [index, setIndex] = useState(-1);

  const filteredArticles = session.isPremiumUser
    ? props.filteredArticles
    : props.filteredArticles?.filter((data) => data.IsPremium !== 1);

  const images = filteredArticles?.map((data) => ({
    src: ArticleImgUrl + data.ImageUrl,
    Id: data.Id,
    Heading: data.Heading,
    Description: data.Description,
  }));

  const handleClose = () => {
    props.setOpenImage(false);
    setIndex(-1);
  };

  const handleClick = (i) => {
    props.setOpenImage(true);
    console.log("index", i);
    setIndex(i);
  };

  const ImageClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      const imageIndex = images?.findIndex((image) => image.Id === Data.Id);
      handleClick(imageIndex);
    } else {
      toast.error("Please purchase a package");
    }
  };

  const ReplyButtonClick=()=>{
    navigate(`/Contact-Us?Purpose=${"Answer for Puzzle"}`)
  }
  return (
    <>
      <div className="row image-slider-wrap">
        {props.filteredArticles &&
          props.filteredArticles.length > 0 &&
          props.filteredArticles.map((data, i) => (
            <div
              key={i}
              className={`col-lg-3 col-md-3 col-sm-4 col-6 trendvideo-item ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
              style={{ padding: "5px", cursor: "pointer" }}
              onClick={() => ImageClick(data)}
            >
              <span className="premium-icon-span">
                <img
                  src={ArticleImgUrl + data.ImageUrl}
                  alt="prithvi"
                  style={{ height: "200px", width: "100%" }}
                />
                {data.Description && <h6>{data.Heading}</h6>}
              </span>
              <div className="videocontent">
                {data.Description ? (
                  <p className="line-clamp-5" style={{ textAlign: "justify" }}>
                    {data.Description}
                  </p>
                ) : (
                  <h5>{data.Heading}</h5>
                )}
              </div>
              <div className="reply-btn" onClick={ReplyButtonClick}>
                <button>Reply</button>
              </div>
            </div>
          ))}
      </div>

      {props.OpenImage && (
        <Lightbox
          mainSrc={images[index].src}
          nextSrc={images[(index + 1) % images.length].src}
          prevSrc={images[(index + images.length - 1) % images.length].src}
          onCloseRequest={handleClose}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 2000,
            },
            closeButton: {
              left: "10px",
              right: "initial",
            },
          }}
          imageTitle={images[index].Heading}
          imageCaption={images[index].Description}
        />
      )}
    </>
  );
}

export default ImageGallery;

import React from 'react';
import LoginForm from '../Login/LoginForm';
import RegisterForm from './RegisterForm';
import useHardwareBackButton from '../../Constants/HardwareBackButtonPress';

function Register() {
	useHardwareBackButton(1)  
	return (
		<div className="mx-auto flex h-full min-h-screen w-full flex-1 flex-col items-center justify-center md:max-w-7xl">
			<RegisterForm />
		</div>
	);
}

export default Register;

import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  actionTypes,
  GetArticleSucces,
  GetArticleError,
  GetPrincipleGroupError,
  GetPrincipleGroupSucces,
  GetAllForumSucces,
  GetAllForumError,
  GetForumMessagesSucces,
  GetForumMessagesError,
  InsertForumMessagesError,
  InsertForumMessagesSucces,
  GetWebinarSucces,
  GetWebinarError,
  GetAllPackgaesSucces,
  GetAllPackgaesError,
  SearchDrugItemSucces,
  SearchDrugItemError,
  GetSingleForumSucces,
  GetSingleForumError,
  CreateForumError,
  CreateForumSucces,
  GetTwoForumSucces,
  GetTwoForumError,
  GetDrugWithIdSucces,
  GetDrugWithIdError,
  GetAllDrugSucces,
  GetAllDrugError,
  PackagesPurchaseSucces,
  PackagesPurchaseError,
  GetGroupByTypeSucces,
  GetGroupByTypeError,
  GetAnnouncementSucces,
  GetAnnouncementError,
  PushNotificationSucces,
  PushNotificationError,
  GetSingleArticleSucces,
  GetSingleArticleError,
  GetDataUsingGrpIdSucces,
  GetDataUsingGrpIdError,
  GetBannerDataSucces,
  GetBannerDataError,
  GetNotificationSucces,
  GetNotificationError,
  ReadNotificationSucces,
  ReadNotificationError,
  GetGroupDataByTypeSucces,
  GetGroupDataByTypeError,
  GetSingleShortSucces,
  GetSingleShortError,
  UpdateShortStatusSucces,
  UpdateShortStatusError,
  PushNotificationUpdateSucces,
  PushNotificationUpdateError,
  GetNotificationStatusSucces,
  GetNotificationStatusError
} from "./action";
import { startLoader, stopLoader } from "../Common/Loader/action";
import UserHomeService from "../../Services/UserHomeService";
import { startSpinner, stopSpinner } from "../Common/spinner/action";
function* GetPrincipleGroup({ payload }) {
  try {
    const data = yield call(UserHomeService.GetPrincipleGroup, payload);
    yield put(GetPrincipleGroupSucces(data));
  } catch (error) {
    yield put(GetPrincipleGroupError(error));
    console.log(error);
  }
}
function* GetArticle({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetArticle, payload);
    yield put(GetArticleSucces(data));
  } catch (error) {
    yield put(GetArticleError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* GetSingleArticle({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetSingleArticle, payload);
    yield put(GetSingleArticleSucces(data));
  } catch (error) {
    yield put(GetSingleArticleError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* GetAllForum({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetFullForum, payload);
    yield put(GetAllForumSucces(data));
  } catch (error) {
    yield put(GetAllForumError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetForumMessages({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetForumMessages, payload);
    yield put(GetForumMessagesSucces(data));
  } catch (error) {
    yield put(GetForumMessagesError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* InsertForumMessages({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.InsertForumMessages, payload);
    yield put(InsertForumMessagesSucces(data));
  } catch (error) {
    yield put(InsertForumMessagesError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* CreateForum({ payload }) {
  try {
    yield put(startSpinner());
    const data = yield call(UserHomeService.CreateForum, payload);
    yield put(CreateForumSucces(data));
  } catch (error) {
    yield put(CreateForumError(error));
    console.log(error);
  } finally {
    yield put(stopSpinner());
  }
}

function* GetWebinar({ payload }) {
  try {
    const data = yield call(UserHomeService.GetWebinar, payload);
    yield put(GetWebinarSucces(data));
  } catch (error) {
    yield put(GetWebinarError(error));
    console.log(error);
  }
}

function* GetAllPackages({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetAllPackages, payload);
    yield put(GetAllPackgaesSucces(data));
  } catch (error) {
    yield put(GetAllPackgaesError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* SearchDrugItem({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.SearchDrugItem, payload);
    yield put(SearchDrugItemSucces(data));
  } catch (error) {
    yield put(SearchDrugItemError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetSingleForum({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetSingleForum, payload);
    yield put(GetSingleForumSucces(data));
  } catch (error) {
    yield put(GetSingleForumError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetTwoForum({ payload }) {
  try {
    const data = yield call(UserHomeService.GetTwoForum, payload);
    yield put(GetTwoForumSucces(data));
  } catch (error) {
    yield put(GetTwoForumError(error));
    console.log(error);
  }
}

function* GetDrugWithId({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetDrugWithId, payload);
    yield put(GetDrugWithIdSucces(data));
  } catch (error) {
    yield put(GetDrugWithIdError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetAllDrug({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetAllDrugs, payload);
    yield put(GetAllDrugSucces(data));
  } catch (error) {
    yield put(GetAllDrugError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* PackagePurchase({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.PackagePurchase, payload);
    yield put(PackagesPurchaseSucces(data));
  } catch (error) {
    yield put(PackagesPurchaseError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetGroupByType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetGroupByType, payload);
    yield put(GetGroupByTypeSucces(data));
  } catch (error) {
    yield put(GetGroupByTypeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetAnnouncement({ payload }) {
  try {
    const data = yield call(UserHomeService.GetAnnouncement, payload);
    yield put(GetAnnouncementSucces(data));
  } catch (error) {
    yield put(GetAnnouncementError(error));
    console.log(error);
  } 
}
function* GetBannerData({ payload }) {
  try {
    const data = yield call(UserHomeService.GetBannerData, payload);
    yield put(GetBannerDataSucces(data));
  } catch (error) {
    yield put(GetBannerDataError(error));
    console.log(error);
  } 
}

function* PushNotification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.PushNotification, payload);
    yield put(PushNotificationSucces(data));
  } catch (error) {
    yield put(PushNotificationError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GroupDataUsingGid({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GroupDataUsingGid, payload);
    yield put(GetDataUsingGrpIdSucces(data));
  } catch (error) {
    yield put(GetDataUsingGrpIdError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* GetNotifaction({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetNotification, payload);
    yield put(GetNotificationSucces(data));
  } catch (error) {
    yield put(GetNotificationError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* ReadNotification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.ReadNotification, payload);
    yield put(ReadNotificationSucces(data));
  } catch (error) {
    yield put(ReadNotificationError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* GetGroupDataByType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetGroupDataByType, payload);
    yield put(GetGroupDataByTypeSucces(data));
  } catch (error) {
    yield put(GetGroupDataByTypeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* GetSingleShortVideo({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetSingleShorts, payload);
    yield put(GetSingleShortSucces(data));
  } catch (error) {
    yield put(GetSingleShortError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* UpdateShortsStatus({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.UpdateShortsStatus, payload);
    yield put(UpdateShortStatusSucces(data));
  } catch (error) {
    yield put(UpdateShortStatusError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* PushNotificationUpdate({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.PushNotificationUpdate, payload);
    yield put(PushNotificationUpdateSucces(data));
  } catch (error) {
    yield put(PushNotificationUpdateError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* GetPushNotificationStatus({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(UserHomeService.GetNotificationStatus, payload);
    yield put(GetNotificationStatusSucces(data));
  } catch (error) {
    yield put(GetNotificationStatusError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.GET_PRINCIPLE_GROUP, GetPrincipleGroup)]);
  yield all([takeEvery(actionTypes.GET_PRINCIPLE_GROUP, GetArticle)]);
  yield all([takeEvery(actionTypes.GET_SINGLE_ARTICLE, GetSingleArticle)]);
  yield all([takeEvery(actionTypes.GET_ALL_FORUM, GetAllForum)]);
  yield all([takeEvery(actionTypes.GET_FORUM_MESSAGES, GetForumMessages)]);
  yield all([takeEvery(actionTypes.MESSAGE_INSERT_FORUM, InsertForumMessages)]);
  yield all([takeEvery(actionTypes.CREATE_FORUM, CreateForum)]);
  yield all([takeEvery(actionTypes.GET_WEBINAR, GetWebinar)]);
  yield all([takeEvery(actionTypes.GET_PACKAGES, GetAllPackages)]);
  yield all([takeEvery(actionTypes.SEARCH_DRUG, SearchDrugItem)]);
  yield all([takeEvery(actionTypes.GET_SINGLE_FORUM, GetSingleForum)]);
  yield all([takeEvery(actionTypes.GET_TWO_FORUM, GetTwoForum)]);
  yield all([takeEvery(actionTypes.GET_DRUG_WITH_ID, GetDrugWithId)]);
  yield all([takeEvery(actionTypes.GET_ALL_DRUG, GetAllDrug)]);
  yield all([takeEvery(actionTypes.PACKAGES_PURCHASE, PackagePurchase)]);
  yield all([takeEvery(actionTypes.GET_GROUP_BY_TYPE, GetGroupByType)]);
  yield all([takeEvery(actionTypes.GET_ANNOUNCEMENT, GetAnnouncement)]);
  yield all([takeEvery(actionTypes.GET_BANNER_DATA, GetBannerData)]);
  yield all([takeEvery(actionTypes.PUSH_NOTIFICATION, PushNotification)]);
  yield all([takeEvery(actionTypes.GET_DATA_USING_GID,GroupDataUsingGid)]);
  yield all([takeEvery(actionTypes.GET_NOTIFICATION,GetNotifaction)]);
  yield all([takeEvery(actionTypes.READ_NOTIFICATION,ReadNotification)]);
  yield all([takeEvery(actionTypes.GETGROUPDATA_TYPEID,GetGroupDataByType)]);
  yield all([takeEvery(actionTypes.GET_SINGLE_SHORTS,GetSingleShortVideo)]);
  yield all([takeEvery(actionTypes.UPDATE_SHORTS_STATUS,UpdateShortsStatus)]);
  yield all([takeEvery(actionTypes.PUSH_NOTIFICATION_UPDATE,PushNotificationUpdate)]);
  yield all([takeEvery(actionTypes.GET_PUSHNOTIFICATION_STATUS,GetPushNotificationStatus)]);
}

export const actionTypes = {
	GET_ASKGURU_MESSAGE: 'GET_ASKGURU_MESSAGE',
	GET_ASKGURU_MESSAGE_SUCCESS: 'GET_ASKGURU_MESSAGE_SUCCESS',
	GET_ASKGURU_MESSAGE_ERROR: 'GET_ASKGURU_MESSAGE_ERROR',

	GET_USER_MESSAGE: 'GET_USER_MESSAGE',
	GET_USER_MESSAGE_SUCCESS: 'GET_USER_MESSAGE_SUCCESS',
	GET_USER_MESSAGE_ERROR: 'GET_USER_MESSAGE_ERROR',

	CREATE_CHAT: 'CREATE_CHAT',
	CREATE_CHAT_SUCCESS: 'CREATE_CHAT_SUCCESS',
	CREATE_CHAT_ERROR: 'CREATE_CHAT_ERROR',

	GET_MESSAGES: 'GET_MESSAGES',
	GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
	GET_MESSAGES_ERROR: 'GET_MESSAGES_ERROR',

	SEND_MESSAGE: 'SEND_MESSAGE',
	SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
	SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',

	ASKGURU_USERS: 'ASKGURU_USERS',
	ASKGURU_USERS_SUCCESS: 'ASKGURU_USERS_SUCCESS',
	ASKGURU_USERS_ERROR: 'ASKGURU_USERS_ERROR',

	UPDATE_ISREAD: 'UPDATE_ISREAD',
	UPDATE_ISREAD_SUCCESS: 'UPDATE_ISREAD_SUCCESS',
	UPDATE_ISREAD_ERROR: 'UPDATE_ISREAD_ERROR',
};

export const getMessagesForAskguru = (payload) => {
	return {
		type: actionTypes.GET_ASKGURU_MESSAGE,
		payload,
	};
};

export const getMessagesForAskguruSuccess = (payload) => {
	return {
		type: actionTypes.GET_ASKGURU_MESSAGE_SUCCESS,
		payload,
	};
};

export const getMessagesForAskguruError = (payload) => {
	return {
		type: actionTypes.GET_ASKGURU_MESSAGE_ERROR,
		payload,
	};
};

export const getMessagesForUsers = (payload) => {
	return {
		type: actionTypes.GET_USER_MESSAGE,
		payload,
	};
};

export const getMessagesForUsersSuccess = (payload) => {
	return {
		type: actionTypes.GET_USER_MESSAGE_SUCCESS,
		payload,
	};
};

export const getMessagesForUsersError = (payload) => {
	return {
		type: actionTypes.GET_USER_MESSAGE_ERROR,
		payload,
	};
};

export const createChat = (payload) => {
	return {
		type: actionTypes.CREATE_CHAT,
		payload,
	};
};

export const createChatSuccess = (payload) => {
	return {
		type: actionTypes.CREATE_CHAT_SUCCESS,
		payload,
	};
};

export const createChatError = (payload) => {
	return {
		type: actionTypes.CREATE_CHAT_ERROR,
		payload,
	};
};

export const getMessages = (payload) => {
	return {
		type: actionTypes.GET_MESSAGES,
		payload,
	};
};

export const getMessagesSuccess = (payload) => {
	return {
		type: actionTypes.GET_MESSAGES_SUCCESS,
		payload,
	};
};

export const getMessagesError = (payload) => {
	return {
		type: actionTypes.GET_MESSAGES_ERROR,
		payload,
	};
};

export const sendMessage = (payload) => {
	return {
		type: actionTypes.SEND_MESSAGE,
		payload,
	};
};

export const sendMessageSuccess = (payload) => {
	return {
		type: actionTypes.SEND_MESSAGE_SUCCESS,
		payload,
	};
};

export const sendMessageError = (payload) => {
	return {
		type: actionTypes.SEND_MESSAGE_ERROR,
		payload,
	};
};

export const getAskguruUsers = (payload) => {
	return {
		type: actionTypes.ASKGURU_USERS,
		payload,
	};
};

export const getAskguruUsersSuccess = (payload) => {
	return {
		type: actionTypes.ASKGURU_USERS_SUCCESS,
		payload,
	};
};

export const getAskguruUsersError = (payload) => {
	return {
		type: actionTypes.ASKGURU_USERS_ERROR,
		payload,
	};
};

export const UpdateIsread = (payload) => {
	return {
		type: actionTypes.UPDATE_ISREAD,
		payload,
	};
};

export const UpdateIsreadSuccess = (payload) => {
	return {
		type: actionTypes.UPDATE_ISREAD_SUCCESS,
		payload,
	};
};

export const UpdateIsreadError = (payload) => {
	return {
		type: actionTypes.UPDATE_ISREAD_ERROR,
		payload,
	};
};

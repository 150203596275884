import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import MobileHomeScreen from "../UserHome/MobileHomeScreen";
import axios from "axios";
import Config from "../../Constants/Config";
import toast from "react-hot-toast";
import { searchTypes, typeMapper } from "../../lib/helper";
import { isPremiumUser } from "../../lib/session";
import ArticleSearch, { ArticleLoader } from "./ArticleSearch";
import ImageSearch from "./ImageSearch";
import { Document } from "react-pdf";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import ShortsSearch from "./ShortsSearch";
import VideosSearch, { VideosLoader } from "./VideosSearch";
import AudioSearch from "./AudioSearch";
import AnnouncementSearch from "./AnnouncementSearch";
import BannerSearch from "./BannerSearch";
import PrithviLogo from "../../img/the-prithvi-logo.png";
import { IoHome } from "react-icons/io5";
import { PiSmileySadLight } from "react-icons/pi";

const fetchSearchResults = async ({ queryKey }) => {
  const [_key, { query, isPremium }] = queryKey;

  const response = await axios.get(
    `${Config.ApiUrl}GeneralSearch?KeyWord=${query ? query : ""}&IsPremium=${Number(isPremium ?? false)}`,
  );
  return response.data;
};

const Search = () => {
  const redirect = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let isPremium = isPremiumUser();

  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  let [filter, setFilter] = useState({ type: 0, Name: "All" });

  const { data, status, error, isFetching } = useQuery({
    queryKey: ["searchResults", { query: debouncedQuery, isPremium }],
    queryFn: fetchSearchResults,
    // enabled: !!debouncedQuery
  });

  // Effect for debouncing the API call
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); // 2000ms = 2 seconds

    // Cleanup function to clear the timeout if query changes
    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuery(value);
    setSearchParams((ref) => ({ ...ref, [name]: value }));
  };

  const displayData = useMemo(() => {
    // Group the fetchResponse data by TypeId
    if (status === "success") {
      console.log("data => ", data);
      const groupedData = data.reduce((acc, item) => {
        // If the TypeId doesn't exist in the accumulator, initialize it with an empty array
        if (filter.type === 0) {
          if (item.TypeId != 6) {
            if (!acc[item.TypeId]) {
              acc[item.TypeId] = [];
            }
            // Push the item's data to the corresponding TypeId array
            acc[item.TypeId].push(...item.Data);
          }
        } else if (item.TypeId === filter.type) {
          if (!acc[item.TypeId]) {
            acc[item.TypeId] = [];
          }
          // Push the item's data to the corresponding TypeId array
          acc[item.TypeId].push(...item.Data);
        }
        return acc;
      }, {});

      return groupedData;
    }
    console.log("data => ", data);

    return {};
  }, [filter, data, status]);

  const memorizedDataArray = useMemo(() => {
    return Object.keys(displayData);
  }, [displayData]);

  const availableFilters = useMemo(() => {
    if (status === "success") {
      const groupedData = data.reduce((acc, item) => {
        if (item.TypeId != 6) {
          if (!acc[item.TypeId]) {
            acc[item.TypeId] = [];
          }

          acc[item.TypeId].push(...item.Data);
        }
        return acc;
      }, {});
      return Object.keys(groupedData);
    }

    return [];
  }, [data, status]);

  return (
    <div className="flex h-full min-h-screen w-full flex-col overflow-auto">
      <div className="flex h-[40px] items-center justify-between bg-brown px-2.5">
        <div
          style={{
            height: "40px",
            width: "fit-content",
            float: "left",
          }}
        >
          <img
            src={PrithviLogo}
            alt="Prithvi Logo"
            style={{
              width: "auto", // Adjust width as needed
              height: "100%", // Maintain aspect ratio
            }}
          />
        </div>
        <div className="">
          <IoHome
            className="h-7 w-7 text-white"
            onClick={() => {
              redirect("/user-home");
            }}
          />
        </div>
      </div>
      <div className="px-4 pb-3 pt-10">
        <div className="w-full">
          <input
            className="w-full rounded-full"
            type="text"
            name={"q"}
            value={query}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="mx-4 overflow-x-hidden pb-6">
        <div className="flex w-full items-center space-x-2.5 overflow-x-auto py-2 scrollbar-hide">
          {searchTypes.map((el) => {
            return (
              <div
                className={`flex w-fit cursor-pointer items-center justify-center rounded-full border border-black px-3 py-1 text-sm tracking-widest text-gray-500 ${filter.type === el.type ? "border-2 border-zinc-900 bg-stone-200 " : ""}`}
                onClick={() => {
                  setFilter(el);
                }}
              >
                {el.Name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mx-4 h-full flex-1">
        {status === "pending" ? (
          <LoadingSkeleton type={filter.type} />
        ) : memorizedDataArray.length ? (
          memorizedDataArray.map((key, _) => {
            return (
              <SearchResults
                rows={displayData[key]}
                type={key}
                filter={filter}
                key={_}
              />
            );
          })
        ) : (
          <NothingToShow setFilter={setFilter} filters={availableFilters} />
        )}
      </div>
    </div>
  );
};

export default Search;

const ShowAllComponents = memo(({ data, type }) => {
  switch (type) {
    case 1:
      return <ArticleSearch data={data} />;
    case 3:
      return <ImageSearch data={data} />;
    default:
      return (
        <div className="">
          <div>
            {data.Name} of type {type}
          </div>
          <div>Description of a larger length goes here.....</div>
        </div>
      );
  }
});

export const LoadingSkeleton = ({ type }) => {
  switch (type) {
    case "1":
      return <ArticleLoader />;
    case "2":
      return <VideosLoader />;
    case "6":
      return <div>loading...</div>;
    default:
      return <VideosLoader />;
  }
};

const SearchResults = ({ rows, type, filter }) => {
  switch (type) {
    case "1":
      return <ArticleSearch rows={rows} />;
    case "2":
      return <VideosSearch rows={rows} />;
    case "3":
      return <ImageSearch rows={rows} />;
    case "4":
      return <BannerSearch rows={rows} />;
    case "5":
      return <AnnouncementSearch rows={rows} />;
    case "7":
      return <ShortsSearch rows={rows} filter={filter} />;
    case "8":
      return <AudioSearch rows={rows} />;
    default:
      return <div>something is off...</div>;
  }
};

const NothingToShow = ({ filters, setFilter }) => {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <div className="mb-5 flex flex-col items-center justify-center">
        <PiSmileySadLight className="mb-2 h-9 w-9" />
        <div>Nothing Found!</div>
      </div>
      {/* <div className="flex items-center justify-center space-x-3">
                {filters.map((el) => {
                    return (
                        <div className="rounded-full bg-cyan-50 px-2 py-1 text-sm">
                            {typeMapper(el)}
                        </div>
                    );
                })}
            </div> */}
    </div>
  );
};

import React, { memo } from 'react';
import {
    dummyLoadingRows,
    htmlToPlainText,
    typeMapper
} from '../../lib/helper';
import { Link } from 'react-router-dom';
import { IoMdArrowDropright } from 'react-icons/io';

const ArticleSearch = memo(({ rows }) => {
    return (
        <div>
            {rows.map((data, _) => {
                let description = htmlToPlainText(data.Description);

                return (
                    <div className="group mb-4 mt-2 rounded-md" key={_}>
                        <Link
                            to={`/Article/${data.Id}`}
                            target="_blank"
                            className="text-zinc-800 no-underline hover:text-zinc-800"
                        >
                            <div className="mb-2 cursor-pointer text-lg capitalize text-blue-500 group-hover:underline">
                                {data.Name} - {data.Heading}
                            </div>
                            <div className="mb-2 line-clamp-3 text-justify text-sm">
                                {description}
                            </div>
                            <div className="flex flex-wrap items-center space-x-2">
                                <div className="flex items-center space-x-1">
                                    <div className="text-xs capitalize tracking-widest text-gray-500">
                                        Group
                                    </div>
                                    <IoMdArrowDropright className="text-gray-500" />
                                    <div className="flex w-fit items-center justify-center rounded-full border border-black bg-stone-100 px-1.5 py-0.5 text-xs lowercase tracking-widest text-gray-500">
                                        {data.Name}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
});

export const ArticleLoader = ({}) => {
    console.log('dummyLoadingRows => ', dummyLoadingRows);
    return (
        <div>
            {dummyLoadingRows.map((el) => {
                return (
                    <div
                        key={el}
                        className="group mb-4 mt-2 rounded-md p-4 px-2.5 shadow"
                    >
                        <div className="mb-4 h-5 w-[45%] animate-pulse rounded-md bg-slate-200"></div>
                        <div className="mb-2 leading-7">
                            <div className="mb-1 h-2.5 w-[90%] animate-pulse rounded-md bg-slate-200"></div>
                            <div className="mb-1 h-2.5 w-[80%] animate-pulse rounded-md bg-slate-200"></div>
                            <div className="mb-1 h-2.5 w-[95%] animate-pulse rounded-md bg-slate-200"></div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <div className=" h-2.5 w-[10%] animate-pulse rounded-md bg-slate-200"></div>
                            <div className=" h-2.5 w-2.5 animate-pulse rounded-md bg-slate-200"></div>
                            <div className=" h-2.5 w-[10%] animate-pulse rounded-md bg-slate-200"></div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ArticleSearch;

// {
//     "Id": 491,
//     "Name": "ARTICLES",
//     "Type": 1,
//     "Heading": "AR1",
//     "Description": "<div class=\"elementor-element explore the intricate channels of the mind and the nervous system.</p>\n<p style=\"box-sizing: border-box; margin-block: 0px 0.9rem; text-align: center;\"><a style=\"box-sizing: border-box; background-color: transparent; text-decoration-line: none; box-shadow: none; outline: none !important;\" href=\"https://liveayurprana.com/products/2596858\">Learn More</a></p>\n</div>\n</div>\n</div>\n</div>",
//     "ImageUrl": "491.jpg? timeStamp=May 22 2024 10:44AM",
//     "GroupId": 180,
//     "UserId": 10,
//     "IsPremium": 0,
//     "Status": 0,
//     "SysTime": "2024-05-21T17:27:18.21",
//     "Date": "2024-05-21T17:26:00",
//     "VideoUrl": "",
//     "LinkUrl": "https://ayurveda.com/",
//     "VideoType": 0,
//     "OrderNo": 0,
//     "LinkType": 2,
//     "Keyword": "qwerty, uiop",
//     "IsCopyRight": 0,
//     "ThumbImg": null,
//     "Announcement": "",
//     "PdfLink": null,
//     "PdfSecretKey": null,
//     "AudioSecretKey": ""
// }

import { actionTypes } from './action';

export const initialState = {
	askguruMessages: null,
	userMessages: null,
	createChat: null,
	getMessages: [],
	sendMessage: null,
	askguruUsers: [],
	UpdateIsRead:[]
};

export const ChatReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ASKGURU_MESSAGE:
			return { ...state, askguruMessages: null };
		case actionTypes.GET_ASKGURU_MESSAGE_SUCCESS:
			return { ...state, askguruMessages: action.payload };
		case actionTypes.GET_ASKGURU_MESSAGE_ERROR:
			return { ...state, askguruMessages: 'error' };

		case actionTypes.GET_USER_MESSAGE:
			return { ...state, userMessages: null };
		case actionTypes.GET_USER_MESSAGE_SUCCESS:
			return { ...state, userMessages: action.payload };
		case actionTypes.GET_USER_MESSAGE_ERROR:
			return { ...state, userMessages: 'error' };

		case actionTypes.CREATE_CHAT:
			return { ...state, createChat: null };
		case actionTypes.CREATE_CHAT_SUCCESS:
			return { ...state, createChat: action.payload };
		case actionTypes.CREATE_CHAT_ERROR:
			return { ...state, createChat: 'error' };

		case actionTypes.GET_MESSAGES:
			return { ...state, getMessages: [] };
		case actionTypes.GET_MESSAGES_SUCCESS:
			return { ...state, getMessages: action.payload };
		case actionTypes.GET_MESSAGES_ERROR:
			return { ...state, getMessages: 'error' };

		case actionTypes.SEND_MESSAGE:
			return { ...state, sendMessage: [] };
		case actionTypes.SEND_MESSAGE_SUCCESS:
			return { ...state, sendMessage: action.payload };
		case actionTypes.SEND_MESSAGE_ERROR:
			return { ...state, sendMessage: 'error' };

		case actionTypes.ASKGURU_USERS:
			return { ...state, askguruUsers: [] };
		case actionTypes.ASKGURU_USERS_SUCCESS:
			return { ...state, askguruUsers: action.payload };
		case actionTypes.ASKGURU_USERS_ERROR:
			return { ...state, askguruUsers: 'error' };

		case actionTypes.UPDATE_ISREAD:
			return { ...state, UpdateIsRead: [] };
		case actionTypes.UPDATE_ISREAD_SUCCESS:
			return { ...state, UpdateIsRead: action.payload };
		case actionTypes.UPDATE_ISREAD_ERROR:
			return { ...state, UpdateIsRead: 'error' };

		default:
			return state;
	}
};

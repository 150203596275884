import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatIcon from '@mui/icons-material/Chat';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const Package = ({ Amount, Name, Description, noofchats, Duration }) => {
  return (
    <Grid item xs={12} sm={6} md={4} style={{height: '100%',cursor:'pointer'}}>
      <Card sx={{ height: '100%', backgroundColor: '#f1dac1', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }} >
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" color="#4a4a4a" sx={{ fontWeight: 'bold' }}>
            {Name}
          </Typography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <CurrencyRupeeIcon />
            </Grid>
            <Grid item>
              <Typography color="#4a4a4a" variant="subtitle1">
                {Amount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <AccessTimeIcon />
            </Grid>
            <Grid item>
              <Typography color="#4a4a4a" variant="subtitle1">
                Duration: {Duration} days
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <ChatIcon />
            </Grid>
            <Grid item>
              <Typography color="#4a4a4a" variant="subtitle1">
                Chat Limit: {noofchats}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" component="div" sx={{ mt: 2, color: '#4a4a4a' }} dangerouslySetInnerHTML={{ __html: Description }} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Package;

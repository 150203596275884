import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import { getSession } from "../../lib/session";
import { GetAllPackgaes, PackagesPurchase } from "../../Redux/UserHome/action";
import "../Css/Packages.css";
import Package from "./Package";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
function Packages() {
  const navigate = useNavigate();
  const handleBackButtonPress=()=>{
      navigate('/user-home')
    }
    
    useHardwareBackButton(0,handleBackButtonPress) 
  const session = getSession();
  const PackagesData = useSelector(
    (state) => state.UserHomeReducer.GetAllPackages,
  );
  
  const dispatch = useDispatch();
  const BackbtnClick = () => {
    navigate('/user-home');
  };
  useEffect(() => {
    dispatch(GetAllPackgaes());
  }, []);

  // const colorClasses = ["l-bg-cherry", "l-bg-blue-dark", "l-bg-green-dark", "l-bg-orange-dark"];

  // const PackagePurchase = (data) => {
  //   if (!session) {
  //     toast.error("You need to login to purchase packages!");
  //     return;
  //   }
  //   const isConfirmed = window.confirm(`Do you want to purchase ${data.Name}?`);
  //   if (isConfirmed) {
  //     dispatch(
  //       PackagesPurchase({
  //         UserId: session.id,
  //         PackageId: data.Id,
  //         Amount: data.Amount,
  //         Duration: data.Duration,
  //       }),
  //     );
  //   }
  // };

  const PackagePurchase = (data) => {
    navigate(`/Contact-Us?Purpose=${"Purchase Premium"}`)
  };
  return (
    <>
    <div className="Article-Modal ContentDisplayPage">
      <div className="home-slider">
        <div className="page-header-con">
          <button className="backbtn" onClick={BackbtnClick}>
            <i className="fas fa-chevron-left"></i>
          </button>
          {/* <button className="menubtn">
                <i class="fa-solid fa-link"></i>
              </button> */}
          {/* <img src={PrithviLogo} alt="peacegate" className="Logo"/> */}
        </div>
      </div>
      <h2 className="page-heading">
        <i class="fa-solid fa-certificate"></i> Packages
      </h2>

      <div className="Packages container mt-3 " style={{marginBottom:'60px'}}>
        <div className="row mb-4">
          {PackagesData && PackagesData.length > 0 ? (
            PackagesData?.map((data, index) => (
              <div
                className="col-xl-6 col-lg-6 mt-3"
                key={index}
                onClick={() => PackagePurchase(data)}
              >
                <Package {...data}/>
              </div>
            ))
          ) : (
            <div className="col-12 text-center">
              <p
                style={{
                  textAlign: "center",
                  color: "gray",
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                No data available.
              </p>
            </div>
          )}
        </div>
      </div>
      
    </div>
    <CombinedFooter/>
    </>
  );
}

export default Packages;

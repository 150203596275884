export const actionTypes = {
	GET_USER: 'GET_USER',
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_ERROR: 'GET_USER_ERROR',

	POST_USER: 'POST_USER',
	POST_USER_SUCCESS: 'POST_USER_SUCCESS',
	POST_USER_ERROR: 'POST_USER_ERROR',

	GET_STATE: 'GET_STATE',
	GET_STATE_SUCCESS: 'GET_STATE_SUCCESS',
	GET_STATE_ERROR: 'GET_STATE_ERROR',

	GET_COUNTRY: 'GET_COUNTRY',
	GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
	GET_COUNTRY_ERROR: 'GET_COUNTRY_ERROR',

	GET_USER_PROFILE: 'GET_USER_PROFILE',
	GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
	GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',

	RESTRICT_MULTI_LOGIN: 'RESTRICT_MULTI_LOGIN',
	RESTRICT_MULTI_LOGIN_SUCCESS: 'RESTRICT_MULTI_LOGIN_SUCCESS',
	RESTRICT_MULTI_LOGIN_ERROR: 'RESTRICT_MULTI_LOGIN_ERROR',

	CHECK_MULTI_LOGIN: 'CHECK_MULTI_LOGIN',
	CHECK_MULTI_LOGIN_SUCCESS: 'CHECK_MULTI_LOGIN_SUCCESS',
	CHECK_MULTI_LOGIN_ERROR: 'CHECK_MULTI_LOGIN_ERROR',

	LOGOUT_MULTI_USER: 'LOGOUT_MULTI_USER',
	LOGOUT_MULTI_USER_SUCCESS: 'LOGOUT_MULTI_USER_SUCCESS',
	LOGOUT_MULTI_USER_ERROR: 'LOGOUT_MULTI_USER_ERROR',
};

// function createAction(type, ...argNames) {
// 	return function (...args) {
// 		const action = { type };
// 		argNames.forEach((arg, index) => {
// 			action[argNames[index]] = args[index];
// 		});
// 		return action;
// 	};
// }

// export const GetUser = createAction(GET_USER, 'Username', 'Password')

export const GetUser = (payload) => {
	return {
		type: actionTypes.GET_USER,
		payload,
	};
};

export const GetUserSuccess = (payload) => {
	return {
		type: actionTypes.GET_USER_SUCCESS,
		payload,
	};
};

export const GetUserError = (payload) => {
	return {
		type: actionTypes.GET_USER_ERROR,
		payload,
	};
};

export const RegisterUser = (payload) => {
	return {
		type: actionTypes.POST_USER,
		payload,
	};
};

export const RegisterUserSuccess = (payload) => {
	return {
		type: actionTypes.POST_USER_SUCCESS,
		payload,
	};
};

export const RegisterUserError = (payload) => {
	return {
		type: actionTypes.POST_USER_ERROR,
		payload,
	};
};

export const GetState = (payload) => {
	return {
		type: actionTypes.GET_STATE,
		payload,
	};
};

export const GetStateSuccess = (payload) => {
	return {
		type: actionTypes.GET_STATE_SUCCESS,
		payload,
	};
};

export const GetStateError = (payload) => {
	return {
		type: actionTypes.GET_STATE_ERROR,
		payload,
	};
};

export const GetCountry = (payload) => {
	return {
		type: actionTypes.GET_COUNTRY,
		payload,
	};
};

export const GetCountrySuccess = (payload) => {
	return {
		type: actionTypes.GET_COUNTRY_SUCCESS,
		payload,
	};
};

export const GetCountryError = (payload) => {
	return {
		type: actionTypes.GET_COUNTRY_ERROR,
		payload,
	};
};

export const GetUserProfile = (payload) => {
	return {
		type: actionTypes.GET_USER_PROFILE,
		payload,
	};
};

export const GetUserProfileSuccess = (payload) => {
	return {
		type: actionTypes.GET_USER_PROFILE_SUCCESS,
		payload,
	};
};

export const GetUserProfileError = (payload) => {
	return {
		type: actionTypes.GET_USER_PROFILE_ERROR,
		payload,
	};
};

export const RestrictMultiLogin = (payload) => {
	return {
		type: actionTypes.RESTRICT_MULTI_LOGIN,
		payload,
	};
};

export const RestrictMultiLoginSuccess = (payload) => {
	return {
		type: actionTypes.RESTRICT_MULTI_LOGIN_SUCCESS,
		payload,
	};
};

export const RestrictMultiLoginError = (payload) => {
	return {
		type: actionTypes.RESTRICT_MULTI_LOGIN_ERROR,
		payload,
	};
};

export const CheckMultiLogin = (payload) => {
	return {
		type: actionTypes.CHECK_MULTI_LOGIN,
		payload,
	};
};

export const CheckMultiLoginSuccess = (payload) => {
	return {
		type: actionTypes.CHECK_MULTI_LOGIN_SUCCESS,
		payload,
	};
};

export const CheckMultiLoginError = (payload) => {
	return {
		type: actionTypes.CHECK_MULTI_LOGIN_ERROR,
		payload,
	};
};

export const LogoutMultiUser = (payload) => {
	return {
		type: actionTypes.LOGOUT_MULTI_USER,
		payload,
	};
};

export const LogoutMultiUserSuccess = (payload) => {
	return {
		type: actionTypes.LOGOUT_MULTI_USER_SUCCESS,
		payload,
	};
};

export const LogoutMultiUserError = (payload) => {
	return {
		type: actionTypes.LOGOUT_MULTI_USER_ERROR,
		payload,
	};
};

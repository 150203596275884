import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter'
function CombinedFooter() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div>
    {isMobile ? (
      <MobileFooter/>
    ) : (
      <DesktopFooter/>
    )}
  </div>
  )
}

export default CombinedFooter
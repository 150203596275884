import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast'; // Import toast from react-hot-toast
import ShortsPlayIcon from '../../img/MobileImages/img/shorts-play-icon.png';
import { getShorts } from "../../Redux/shorts/action";

function MobileFooter() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentPath = window.location.pathname;

    useEffect(() => {
        dispatch(getShorts());
    }, []);

    const ShortsData = useSelector((state) => state.ShortsReducer.shorts) || [];

    const handleShortsClick = () => {
        if (!ShortsData || ShortsData.length === 0) {
            // Display a toast if ShortsData is null or empty
            toast.error('No shorts available!');
        } else {
            // Navigate to '/shorts' if ShortsData is available
            navigate('/shorts');
        }
    };

    return (
        <div className="footer-buttons-container">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="row">
                            <div style={{ backgroundColor: currentPath === '/user-home' ? 'rgb(216 171 121)' : '' }} className="col-3">
                                <button onClick={() => navigate('/user-home')}>
                                    <span>
                                        <i className="fas fa-home" />
                                    </span>
                                    <h6>Home</h6>
                                </button>
                            </div>
                            <div style={{ backgroundColor: currentPath.startsWith('/forum') ? 'rgb(216 171 121)' : '' }} className="col-3" onClick={() => navigate('/forum')}>
                                <button>
                                    <span>
                                        <i className="fa-solid fa-comments" />
                                    </span>
                                    <h6>Forum</h6>
                                </button>
                            </div>
                            <div style={{ backgroundColor: currentPath === '/shorts' ? 'rgb(216 171 121)' : '' }} className="col-3">
                                <button className="shorts-btn" onClick={handleShortsClick}>
                                    <span>
                                        <img src={ShortsPlayIcon} alt="" style={{ height: 'auto' }} />
                                    </span>
                                    <h6>Shorts</h6>
                                </button>
                            </div>
                            <div style={{ backgroundColor: currentPath === '/notification' ? 'rgb(216 171 121)' : '' }} className="col-3" onClick={() => navigate('/notification')}>
                                <button>
                                    <span>
                                        <i className="fa-solid fa-bell" />
                                    </span>
                                    <h6>Notification</h6>
                                </button>
                            </div>
                            <div style={{ backgroundColor: currentPath.startsWith('/drug') ? 'rgb(216 171 121)' : '' }} className="col-3" onClick={() => navigate('/drug')}>
                                <button>
                                    <span>
                                        <i className="fa-solid fa-database" />
                                    </span>
                                    <h6>Drugs</h6>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileFooter;

import { actionTypes } from "./action";
export const initialState = {
  GetPrincipleGroup: null,
  GetArticle: null,
  GetSigleArticle: null,
  GetAllForum: null,
  GetForumMessages: null,
  InsertForumMessages: null,
  CreateForum: null,
  GetWebinar: null,
  GetAllPackages: null,
  SearchDrugData: null,
  GetSingleForum: null,
  GetTwoForum: null,
  GetDrugWithId:null,
  GetAllDrug: null,
  PackagePurchase: null,
  GetAllGroup:null,
  GetAnnouncement: null,
  BannerData:null,
  PushNotification: null,
  GetGroupData:null,
  NotificationData:null,
  ReadNotification:null,
  GroupDataByType:null,
  GetSingleShortData:null,
  UpdatedShortsResponse:null,
  PushnotficationUpdate:null,
  GetNotificationStatus:null
};
const UserHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRINCIPLE_GROUP:
      return { ...state, GetPrincipleGroup: null };
    case actionTypes.GET_PRINCIPLE_GROUP_SUCCESS:
      return { ...state, GetPrincipleGroup: action.payload };
    case actionTypes.GET_PRINCIPLE_GROUP_ERROR:
      return { ...state, GetPrincipleGroup: "error" };

    case actionTypes.GET_ARTICLE:
      return { ...state, GetArticle: null };
    case actionTypes.GET_ARTICLE_SUCCESS:
      return { ...state, GetArticle: action.payload };
    case actionTypes.GET_ARTICLE_ERROR:
      return { ...state, GetArticle: "error" };

    case actionTypes.GET_SINGLE_ARTICLE:
      return { ...state, GetSigleArticle: null };
    case actionTypes.GET_SINGLE_ARTICLE_SUCCESS:
      return { ...state, GetSigleArticle: action.payload };
    case actionTypes.GET_SINGLE_ARTICLE_ERROR:
      return { ...state, GetSigleArticle: "error" };

    case actionTypes.GET_ALL_FORUM:
      return { ...state, GetAllForum: null };
    case actionTypes.GET_ALL_FORUM_SUCCESS:
      return { ...state, GetAllForum: action.payload };
    case actionTypes.GET_ALL_FORUM_ERROR:
      return { ...state, GetAllForum: "error" };

    case actionTypes.GET_FORUM_MESSAGES:
      return { ...state, GetForumMessages: null };
    case actionTypes.GET_FORUM_MESSAGES_SUCCESS:
      return { ...state, GetForumMessages: action.payload };
    case actionTypes.GET_FORUM_MESSAGES_ERROR:
      return { ...state, GetForumMessages: "error" };

    case actionTypes.MESSAGE_INSERT_FORUM:
      return { ...state, InsertForumMessages: null };
    case actionTypes.MESSAGE_INSERT_FORUM_SUCCESS:
      return { ...state, InsertForumMessages: action.payload };
    case actionTypes.MESSAGE_INSERT_FORUM_ERROR:
      return { ...state, InsertForumMessages: "error" };

    case actionTypes.CREATE_FORUM:
      return { ...state, CreateForum: null };
    case actionTypes.CREATE_FORUM_SUCCESS:
      return { ...state, CreateForum: action.payload };
    case actionTypes.CREATE_FORUM_ERROR:
      return { ...state, CreateForum: "error" };

    case actionTypes.GET_WEBINAR:
      return { ...state, GetWebinar: null };
    case actionTypes.GET_WEBINAR_SUCCESS:
      return { ...state, GetWebinar: action.payload };
    case actionTypes.GET_WEBINAR_ERROR:
      return { ...state, GetWebinar: "error" };

    case actionTypes.GET_PACKAGES:
      return { ...state, GetAllPackages: null };
    case actionTypes.GET_PACKAGES_SUCCESS:
      return { ...state, GetAllPackages: action.payload };
    case actionTypes.GET_PACKAGES_ERROR:
      return { ...state, GetAllPackages: "error" };

    case actionTypes.SEARCH_DRUG:
      return { ...state, SearchDrugData: null };
    case actionTypes.SEARCH_DRUG_SUCCESS:
      return { ...state, SearchDrugData: action.payload };
    case actionTypes.SEARCH_DRUG_ERROR:
      return { ...state, SearchDrugData: "error" };

    case actionTypes.GET_SINGLE_FORUM:
      return { ...state, GetSingleForum: null };
    case actionTypes.GET_SINGLE_FORUM_SUCCESS:
      return { ...state, GetSingleForum: action.payload };
    case actionTypes.GET_SINGLE_FORUM_ERROR:
      return { ...state, GetSingleForum: "error" };

    case actionTypes.GET_TWO_FORUM:
      return { ...state, GetTwoForum: null };
    case actionTypes.GET_TWO_FORUM_SUCCESS:
      return { ...state, GetTwoForum: action.payload };
    case actionTypes.GET_TWO_FORUM_ERROR:
      return { ...state, GetTwoForum: "error" };

    case actionTypes.GET_DRUG_WITH_ID:
      return { ...state, GetDrugWithId: null };
    case actionTypes.GET_DRUG_WITH_ID_SUCCESS:
      return { ...state, GetDrugWithId: action.payload };
    case actionTypes.GET_DRUG_WITH_ID_ERROR:
      return { ...state, GetDrugWithId: "error" };

    case actionTypes.GET_ALL_DRUG:
      return { ...state, GetAllDrug: null };
    case actionTypes.GET_ALL_DRUG_SUCCESS:
      return { ...state, GetAllDrug: action.payload };
    case actionTypes.GET_ALL_DRUG_ERROR:
      return { ...state, GetAllDrug: "error" };

    case actionTypes.PACKAGES_PURCHASE:
      return { ...state, PackagePurchase: null };
    case actionTypes.PACKAGES_PURCHASE_SUCCESS:
      return { ...state, PackagePurchase: action.payload };
    case actionTypes.PACKAGES_PURCHASE_ERROR:
      return { ...state, PackagePurchase: "error" };

    case actionTypes.GET_GROUP_BY_TYPE:
      return { ...state, GetAllGroup: null };
    case actionTypes.GET_GROUP_BY_TYPE_SUCCESS:
      return { ...state, GetAllGroup: action.payload };
    case actionTypes.GET_GROUP_BY_TYPE_ERROR:
      return { ...state, GetAllGroup: "error" };

    case actionTypes.GET_ANNOUNCEMENT:
      return { ...state, GetAnnouncement: null };
    case actionTypes.GET_ANNOUNCEMENT_SUCCESS:
      return { ...state, GetAnnouncement: action.payload };
    case actionTypes.GET_ANNOUNCEMENT_ERROR:
      return { ...state, GetAnnouncement: "error" };

    case actionTypes.GET_BANNER_DATA:
      return { ...state, BannerData: null };
    case actionTypes.GET_BANNER_DATA_SUCCESS:
      return { ...state, BannerData: action.payload };
    case actionTypes.GET_BANNER_DATA_ERROR:
      return { ...state, BannerData: "error" };

    case actionTypes.PUSH_NOTIFICATION:
      return { ...state, PushNotification: null };
    case actionTypes.PUSH_NOTIFICATION_SUCCESS:
      return { ...state, PushNotification: action.payload };
    case actionTypes.PUSH_NOTIFICATION_ERROR:
      return { ...state, PushNotification: "error" };

    case actionTypes.GET_DATA_USING_GID:
      return { ...state, GetGroupData: null };
    case actionTypes.GET_DATA_USING_GID_SUCCESS:
      return { ...state, GetGroupData: action.payload };
    case actionTypes.GET_DATA_USING_GID_ERROR:
      return { ...state, GetGroupData: "error" };

    case actionTypes.GET_NOTIFICATION:
      return { ...state, NotificationData: null };
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return { ...state, NotificationData: action.payload };
    case actionTypes.GET_NOTIFICATION_ERROR:
      return { ...state, NotificationData: "error" };

    case actionTypes.READ_NOTIFICATION:
      return { ...state, ReadNotification: null };
    case actionTypes.READ_NOTIFICATION_SUCCESS:
      return { ...state, ReadNotification: action.payload };
    case actionTypes.READ_NOTIFICATION_ERROR:
      return { ...state, ReadNotification: "error" };

    case actionTypes.GETGROUPDATA_TYPEID:
      return { ...state, GroupDataByType: null };
    case actionTypes.GETGROUPDATA_TYPEID_SUCCESS:
      return { ...state, GroupDataByType: action.payload };
    case actionTypes.GETGROUPDATA_TYPEID_ERROR:
      return { ...state, GroupDataByType: "error" };

    case actionTypes.GET_SINGLE_SHORTS:
      return { ...state, GetSingleShortData: null };
    case actionTypes.GET_SINGLE_SHORTS_SUCCESS:
      return { ...state, GetSingleShortData: action.payload };
    case actionTypes.GET_SINGLE_SHORTS_ERROR:
      return { ...state, GetSingleShortData: null };

    case actionTypes.UPDATE_SHORTS_STATUS:
      return { ...state, UpdatedShortsResponse: null };
    case actionTypes.UPDATE_SHORTS_STATUS_SUCCESS:
      return { ...state, UpdatedShortsResponse: action.payload };
    case actionTypes.UPDATE_SHORTS_STATUS_ERROR:
      return { ...state, UpdatedShortsResponse: "error" };

    case actionTypes.PUSH_NOTIFICATION_UPDATE:
      return { ...state, PushnotficationUpdate: null };
    case actionTypes.PUSH_NOTIFICATION_UPDATE_SUCCESS:
      return { ...state, PushnotficationUpdate: action.payload };
    case actionTypes.PUSH_NOTIFICATION_UPDATE_ERROR:
      return { ...state, PushnotficationUpdate: "error" };

    case actionTypes.GET_PUSHNOTIFICATION_STATUS:
      return { ...state, GetNotificationStatus: null };
    case actionTypes.GET_PUSHNOTIFICATION_STATUS_SUCCESS:
      return { ...state, GetNotificationStatus: action.payload };
    case actionTypes.GET_PUSHNOTIFICATION_STATUS_ERROR:
      return { ...state, GetNotificationStatus: "error" };

    default:
      return state;
  }
};

export default UserHomeReducer;

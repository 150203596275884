import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { GetAnnouncement } from '../../Redux/UserHome/action';

function AnnouncementSlide() {
  const dispatch= useDispatch()
  const AnouncementData = useSelector((state) => state.UserHomeReducer. GetAnnouncement);
  // console.log('AnouncementData',AnouncementData);
  useEffect(() => {
    dispatch(GetAnnouncement())
  }, [])
  
  return (
    <>
 { AnouncementData?.length>0&&<div className="container">
    <div className="news-wrap">
      <i className="fas fa-bullhorn" />
      <marquee behavior="" direction="">
        {AnouncementData?.map((data)=>(<span style={{marginLeft:"250px"}}>{data.Announcement}.</span> ))} 
      </marquee>
    </div>
  </div>}
  </>
  )
}

export default AnnouncementSlide
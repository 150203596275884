import ApiClient from './ApiClient';
import 'react-toastify/dist/ReactToastify.css';

class ChatService {
	constructor(callback) {
		this.callback = callback;
	}

	async GetShorts(payload) {
		const response = await ApiClient.get(`PrinciplesArticle?type=7`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
}

export default new ChatService();

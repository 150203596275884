import ApiClient from './ApiClient';
import 'react-toastify/dist/ReactToastify.css';

class ChatService {
	constructor(callback) {
		this.callback = callback;
	}

	async GetMessagesForAskguru(payload) {
		const response = await ApiClient.get(`Chat?guruid=${payload.guruid}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetMessagesForUsers(payload) {
		const response = await ApiClient.get(`Chat?userid=${payload.userid}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetAskguruUsers(payload) {
		const response = await ApiClient.get(`AskguruUser?Id=${payload.Id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async CreateChat(payload) {
		const response = await ApiClient.post(
			`Chat?uid=${payload.uid}&gid=${payload.gid}`,
		)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async SendMessage(payload) {
		const response = await ApiClient.post(
			`ChatMessage?chatid=${payload.chatid}&message=${payload.message}&sendby=${payload.sendby}`,
			payload,
		)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetMessages(payload) {
		const response = await ApiClient.get(
			`ChatMessage?cid=${payload.cid}&uid=${payload.uid}`,
		)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async UpdateIsRead(payload) {
		const response = await ApiClient.get(
			`ChatMessage?ChatMessageId=${payload.ChatMessageId}&&SenderId=${payload.SenderId}`
		)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
}

export default new ChatService();

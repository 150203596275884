import React from 'react'
import { useState } from 'react';
import toast from 'react-hot-toast';
import VideoDisplayModal from '../../Components/Modals/VideoDisplayModal';
import { VideoThumbUrl, VideoUrl } from '../../Constants/Config';
import { getSession } from '../../lib/session';
import { IdentifyVideoService, getVimeoVideoId, extractSrcFromHtmlInput, extractYoutubeVideoId, extractShortsVideoId } from '../../lib/helper';

function AllVideos(props) {
const session=getSession()
//Video Modal
const [currentVideo, setCurrentVideo] = useState(null);
const handleClose = () => props.setVideoModal(false);
const VideoClick = (data) => {
  const isPremiumUser = session.isPremiumUser;
  if (isPremiumUser || data.IsPremium !== 1) {
    let videoSrc;
    if (data.VideoType === 1) {
      const linkType = IdentifyVideoService(data.VideoUrl); 
      if (linkType === 'Vimeo') {
        videoSrc = `https://player.vimeo.com/video/${getVimeoVideoId(data.VideoUrl)}?autoplay=1&loop=1&autopause=0`;
      } else if (linkType === "YouTube") {
        videoSrc = `https://www.youtube.com/embed/${extractYoutubeVideoId(data.VideoUrl)}?autoplay=1`;
      }else if (linkType === "iframe") {
      } else if (linkType === "YouTubeShorts") {
        videoSrc = `https://www.youtube.com/embed/${extractShortsVideoId(data.VideoUrl)}?autoplay=1`;
      }else if (linkType === "iframe") {
        videoSrc = extractSrcFromHtmlInput(data.VideoUrl);
      } else {
        toast.error("The provided video URL is not supported or invalid!")
      }
    } else {
      videoSrc = VideoUrl + data.VideoUrl;
    }
    setCurrentVideo(videoSrc);
    props.setVideoModal(true);
  } else {
    toast.error("Please purchase a package");
  }
};

  return (
    <>  
    {currentVideo&&<VideoDisplayModal showModal={props.VideoModal} currentVideo={currentVideo} handleClose={handleClose}/>}
    <div className="row trendingvideos" >
    {props.filteredArticles?.map((data, index) => (
      <div
        key={index}
        className="col-lg-3 col-md-6 col-sm-12 video-item"
      >
                  <button
                    className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                    onClick={() => VideoClick(data)}
                    style={{ position: "relative" }}
                  >
                    <span className="Videospan premium-icon-span">
                    {(data.VideoType === 1 && !data.ThumbImg &&IdentifyVideoService(data.VideoUrl)==='YouTube')? (
                        <img
                          src={`https://img.youtube.com/vi/${extractYoutubeVideoId(data.VideoUrl)}/maxresdefault.jpg`}
                          alt="Prithvi"
                          style={{height:'200px',width:'100%'}}
                        />
                      ) : (
                        <img src={VideoThumbUrl+data.ThumbImg} alt="uploaded video" style={{width:'100%'}} />
                      )}
                    </span>
                    <div className="videocontent">
                      <font className="pulse-btn">
                        <i className="fas fa-play" />
                      </font>
                      <h6>{data.Heading}</h6>
                    </div>
                  </button>
                </div>
    ))}
  </div></>
  )
}

export default AllVideos
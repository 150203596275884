import { format, formatDistance, formatRelative, subDays } from 'date-fns';

// Trim Description
const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength) + '...'; // Truncate and add ellipsis
    }
};

export const stripHtmlTags = (html, maxLength) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const plainText = doc.body.textContent || '';
    return trimText(plainText, maxLength);
};

export const htmlToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

export const chatTime = (date) => format(new Date(date), `hh:mm aaa`);
// formatRelative(subDays(new Date(), date), new Date());

export const getVimeoVideoId = (url) => {
    // Regex pattern to match Vimeo URLs
    var regex =
        /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;

    // Match the URL against the regex pattern
    var match = url.match(regex);

    // If there's a match, return the video ID
    if (match && match[3]) {
        return match[3];
    } else {
        // Otherwise, return null
        return null;
    }
};
export function extractShortsVideoId(url) {
    if (!url) {
        return null;
    }

    var regExp =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/shorts\/|youtu\.be\/)([0-9A-Za-z_-]{11})/;

    // Execute the regular expression on the input URL
    var match = url.match(regExp);

    // If match is found, return the video ID, else return null
    return match ? match[1] : null;
}

export function extractYoutubeVideoId(url) {
    if (!url) {
        return null;
    }
    var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    let str = match && match[7].length === 11 ? match[7] : false;
    return str;
}

export const IdentifyVideoService = (url) => {
    // Regex patterns to match Vimeo and YouTube URLs
    const vimeoRegex =
        /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/(?:video\/)?|player\.vimeo\.com\/video\/)(\d+)/;
    const youtubeRegex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const iframeRegex = /<iframe.*?src=['"](.*?)['"]/;
    const youtubeShortsRegex =
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;

    // Match the URL against the regex patterns
    const vimeoMatch = url.match(vimeoRegex);
    const youtubeMatch = url.match(youtubeRegex);
    const iframeMatch = url.match(iframeRegex);
    const youtubeShortsMatch = url.match(youtubeShortsRegex);

    // Check if it's a Vimeo URL
    if (vimeoMatch && vimeoMatch[1]) {
        return 'Vimeo';
    }
    // Check if it's a YouTube URL
    else if (youtubeMatch && youtubeMatch[1]) {
        return 'YouTube';
    }
    // Check if it's a YouTube Shorts URL
    else if (youtubeShortsMatch && youtubeShortsMatch[1]) {
        return 'YouTubeShorts';
    }
    // Check if it contains an iframe
    else if (iframeMatch && iframeMatch[1]) {
        return 'iframe';
    } else {
        // Otherwise, return null
        return null;
    }
};

export const objectsAreEqual = (obj1, obj2) => {
    // Get the keys of both objects
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    // Check if number of keys is the same
    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }

    // Check if all keys and their corresponding values are equal
    for (let key of obj1Keys) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    // If all checks passed, objects are considered equal
    return true;
};

export function extractSrcFromHtmlInput(htmlInput) {
    // Create a temporary element to parse the HTML input
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlInput;

    // Find the iframe element
    const iframeElement = tempElement.querySelector('iframe');

    // Check if iframeElement exists and has src attribute
    if (iframeElement && iframeElement.src) {
        return iframeElement.src;
    } else {
        return null;
    }
}

export const typeMapper = (type) => {
    let mapper = {
        1: 'Article',
        2: 'Video',
        3: 'Image',
        4: 'Banner',
        5: 'Announcement',
        6: 'PDF',
        7: 'Shorts',
        8: 'Audio'
    };
    return mapper[type];
};

export const searchTypes = [
    { type: 0, Name: 'All' },
    { type: 1, Name: 'Articles' },
    { type: 2, Name: 'Videos' },
    { type: 3, Name: 'Images' },
    { type: 4, Name: 'Banner' },
    { type: 5, Name: 'Announcements' },
    // { type: 6, Name: 'PDF' },
    { type: 7, Name: 'Shorts' },
    { type: 8, Name: 'Audios' }
];

export const checkValidVideoUrl = async (url) => {
    return new Promise((resolve) => {
        const video = document.createElement('video');

        video.src = url;
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            resolve(true); // Video metadata loaded successfully
        };

        video.onerror = () => {
            resolve(false); // Error loading video
        };
    });
};

export const checkValidImageUrl = async (url) => {
    return new Promise((resolve) => {
        const img = new Image();

        img.src = url;
        img.onload = () => {
            resolve(true); // Image loaded successfully
        };

        img.onerror = () => {
            resolve(false); // Error loading image
        };
    });
};

export const dummyLoadingRows = Array.from({ length: 5 }, (v, _) => _ + 1);

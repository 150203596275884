import React from "react";
import { GetSingleArticle } from "../../Redux/UserHome/action";
import "../../Components/Style/ArticleModal.css";
import { ArticleImgUrl } from "../../Constants/Config";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import { getSession } from "../../lib/session";
import DesktopFooter from "../../Components/Footer/DesktopFooter";
function SingleArticle() {
  const { Id } = useParams();
  const session = getSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SingleArticleData = useSelector(
    (state) => state.UserHomeReducer.GetSigleArticle,
  );
  // Check if the user is premium or the article is not premium
  const isPremiumUser = session && session.isPremiumUser;
  const isPremiumArticle =
    SingleArticleData && SingleArticleData.IsPremium !== 1;

  useEffect(() => {
    if (Id) {
      dispatch(
        GetSingleArticle({
          Id: Id,
        }),
      );
      window.scrollTo(0, 0);
    }
  }, [Id]);
  const BackbtnClick = () => {
    navigate(-1);
  };

  const LinkClick = (linkToRedirect) => {
    // Open the link in a new tab
    window.open(linkToRedirect, "_blank");
  };
  return (
    <div className="EditModal " style={{ overflowX: "hidden" }}>
     {SingleArticleData&& (
      <>
     <div className="Article-Modal ContentDisplayPage">
        {session && session.id && (
          <div className="home-slider">
            <div className="page-header-con">
              <button className="backbtn" onClick={BackbtnClick}>
                <i className="fas fa-chevron-left"></i>
              </button>
            </div>
          </div>
        )}
        <h2 className="page-heading">{SingleArticleData?.Heading}</h2>
        <div className="DisplayImage container">
          <img
            className="d-block "
            src={ArticleImgUrl + SingleArticleData?.ImageUrl}
            alt="The Prithvi"
          />
        </div>

        {isPremiumUser || isPremiumArticle ? (
               SingleArticleData?.LinkUrl && (
                <div className="d-flex justify-content-center container mt-3">
                  <a
                    href={SingleArticleData.LinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: "10px 20px",
                      color: "#003a66",
                      textDecoration: "none",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Reference Link
                    <i className="fa-solid fa-link" style={{ marginLeft: "5px" }}></i>
                  </a>
                </div>
              )
        ) : (
            <div className="d-flex justify-content-center container mt-3">
              <button
                onClick={() => navigate("/")}
                style={{
                  padding: "10px 20px",
                  color: "#fff",
                  backgroundColor: "#b78751",
                  border: "1px solid #b78751",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
              >
                Login to view
              </button>
            </div>
          
        )}

        <div className="service-single container">
          {isPremiumUser || isPremiumArticle ? (
            <p
              className="service-single-ptag"
              dangerouslySetInnerHTML={{
                __html: SingleArticleData?.Description,
              }}
            />
          ) : (
            <p
              style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
              className="service-single-ptag"
            >
              Please login to view this premium article!
            </p>
          )}
        </div>
      </div>
      {session && session.id ? <CombinedFooter /> : <DesktopFooter />}
      </>
      )}
      
    </div>
  );
}

export default SingleArticle;

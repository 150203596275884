import React from 'react';
import { BsDot } from 'react-icons/bs';

function MessageComponent({
	message: { profilePic, username, id, created_at, message, email },
	prev,
	next,
}) {
	// const { data: session } = useSession();
	let session = {
		email: 'thek8pai@gmail.com',
	};
	let isUser = session?.email === email;
	let prevMessage = prev?.email === email;
	let nextMessage = next?.email === email;
	console.log('prev & curr ', email, prev?.email, prevMessage);
	return (
		<div
			className={`mt-1 flex w-full items-start ${
				isUser ? 'justify-end' : 'justify-start'
			} ${nextMessage ? '' : ' mb-3'}`}
		>
			<div
				className={`hidden md:block ${isUser ? 'order-1' : ''} ${
					prevMessage ? 'h-10 w-10' : 'mt-6'
				}`}
			>
				<img
					src={`${profilePic}`}
					alt={username}
					height={10}
					width={30}
					className={`aspect-square h-10 w-10 rounded-full  ${
						prevMessage ? 'hidden' : ''
					}`}
				/>
			</div>
			<div
				className={`relative w-full md:min-w-[300px] ${
					prevMessage ? '' : 'mb-0.5'
				}`}
			>
				<div
					className={`flex w-full items-center space-x-1 whitespace-nowrap text-xs ${
						isUser
							? 'justify-end md:pr-4'
							: '-mt-1 text-left md:ml-3.5'
					} ${prevMessage ? 'hidden' : 'mb-1'}`}
				>
					<span>{username}</span>
					<BsDot className={'h-5 w-5 text-black'} />
					<span className="uppercase">
						{new Date(created_at).toLocaleTimeString([], {
							hour: 'numeric',
							minute: 'numeric',
							hour12: true,
						})}
					</span>
				</div>
				<div
					className={`relative w-fit rounded-md bg-neutral-200 md:max-w-md ${
						isUser ? 'float-right mt-0 md:mr-3' : 'mt-0 md:ml-3'
					}`}
				>
					<div className={`px-3.5 py-1.5 ${isUser ? '' : ''}`}>
						<p className="m-0 px-1 py-1.5">{message}</p>
					</div>
					{/* <div
						className={`absolute mr-1 whitespace-nowrap text-xs uppercase ${
							isUser ? 'left-0' : 'left-full -translate-x-full'
						}  ${nextMessage ? 'mt-0 hidden' : ' mt-1'}`}
					>
						{new Date(created_at).toLocaleTimeString([], {
							hour: 'numeric',
							minute: 'numeric',
							hour12: true,
						})}
					</div> */}
				</div>
			</div>
		</div>
	);
}

export default MessageComponent;

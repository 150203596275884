import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import toast from "react-hot-toast";
import { clearSession, getSession } from "../../lib/session";
import { useNavigate } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import { ApiUrl } from "../../Constants/Config";
import axios from "axios";

const DeleteProfile = () => {
  const [state, setState] = useState();
  const session = getSession();
  const redirect = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    const numericRegex = /^[0-9]+$/;

    if (!state) {
      toast.error("Please enter the OTP.");
      return;
    }
    
    if (!numericRegex.test(state)) {
      toast.error("Invalid OTP format. Please enter a numeric OTP.");
      return; 
    }
    try {
      axios
        .get(`${ApiUrl}Users?uid=${session.id}&Otp=${state}`)
        .then((response) => {
          if (response.data && response.data.Result[0]?.Error===0) {
            toast.success("Your account has been deleted successfully")
          } else {
            if (response.data && response.data.Result[0]?.Error===1) {
              toast.error("The OTP you entered is incorrect. Please try again!");
            } else {
              toast.error("Something went wrong!")
            }       
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Something went wrong...");
    } finally {
      // setOpen(false);
    }
  };

  return (
    <div className="flex h-full min-h-screen flex-col">
      <div className="w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/profile")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="flex flex-1 items-center justify-center ">
        <form
          onSubmit={onSubmit}
          className="h-fit w-fit rounded-md p-5 shadow-md"
        >
          <div className="mb-5">
            <h1 className="mb-2 text-center text-4xl font-semibold">
              Delete My Account
            </h1>
            <p className="text-sm ">
              This account will no longer be available once you confirm to
              delete it. Do you want to proceed?
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <input
              type="text"
              placeholder="12345"
              value={state}
              onChange={(e) => setState(e.target.value)}
              maxLength={5}
              className={twMerge(
                `titext`,
                "mb-5 w-[215px] px-4 py-3 text-2xl font-semibold tracking-[20px]",
              )}
            />
            <button
              type={"submit"}
              className="rounded-md bg-red-600 px-4 py-2 text-lg font-semibold tracking-wider text-white"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
      <CombinedFooter />
    </div>
  );
};

export default DeleteProfile;

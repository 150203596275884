import React, { memo, useState } from 'react';
import Config from '../../Constants/Config';
import AudioPlayModal from '../../Components/Modals/AudioPlayModal';
import { FaPlay } from 'react-icons/fa';

const AudioSearch = memo(({ rows }) => {
    return (
        <div className="mb-4 ">
            {rows.map((el) => {
                let src = `${Config.AudioUrl}${el.ImageUrl}`;
                return (
                    <div className="mb-3 w-full rounded-md border p-2 ">
                        <AudioPlayer data={el} />
                    </div>
                );
            })}
        </div>
    );
});

const AudioPlayer = ({ data }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="flex w-full items-center justify-between ">
            <AudioPlayModal
                showModal={open}
                handleCloseModal={(e) => {
                    setOpen(false);
                }}
                AudioData={data}
            />
            <div className="line-clamp-1 lowercase">{data.Heading}</div>
            <div>
                <FaPlay onClick={() => setOpen(true)} />
            </div>
        </div>
    );
};

export default AudioSearch;

// {
//     "Id": 459,
//     "Name": "AYURVEDIC AUDIO",
//     "Type": 8,
//     "Heading": "AUD1",
//     "Description": "",
//     "ImageUrl": "459.jpg? timeStamp=May 23 2024 10:41AM",
//     "GroupId": 169,
//     "UserId": 10,
//     "IsPremium": 0,
//     "Status": 0,
//     "SysTime": "2024-05-04T11:39:48.973",
//     "Date": "2024-05-04T00:00:00",
//     "VideoUrl": "qwertyui",
//     "LinkUrl": "",
//     "VideoType": 0,
//     "OrderNo": 0,
//     "LinkType": 1,
//     "Keyword": "",
//     "IsCopyRight": 0,
//     "ThumbImg": "459.jpg? timeStamp=May 23 2024 10:41AM",
//     "Announcement": "",
//     "PdfLink": "",
//     "PdfSecretKey": "",
//     "AudioSecretKey": "msJsz.Mp3"
// }

import React from "react";
import { Modal } from "react-bootstrap";
import { AudioUrl } from "../../Constants/Config";

function AudioPlayModal({ showModal, handleCloseModal, AudioData }) {
  // Ensure AudioData exists and is valid
  const isDriveData =
    AudioData && AudioData.AudioSecretKey?.startsWith("https://workdrive.zohoexternal.com");

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            backgroundColor: "rgb(239 239 239)",
            border: "none",
            borderRadius: "50%",
            boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px",
            color: "rgb(0 0 0)",
            fontSize: "16px",
            height: "23px",
            margin: "0px",
            opacity: "1",
            outline: "0px",
            padding: "0px",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            width: "23px",
          }}
          onClick={handleCloseModal}
        >
          <i className="fas fa-times"></i>
        </button>
        
        {AudioData && AudioData.AudioSecretKey ? (
          <audio
            src={
              isDriveData
                ? "https://workdrive.zohoexternal.com/embed/0u1b341586d8654074702965863484d870d4e?toolbar=false&appearance=light&themecolor=green"
                : AudioUrl + AudioData.AudioSecretKey
            }
            controls
            autoPlay
            controlsList="nodownload"
            style={{ width: "100%" }}
          />
        ) : (
          <p>No audio available.</p> // Fallback for null/invalid AudioData
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AudioPlayModal;

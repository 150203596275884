// Jan 18,2024
export const formattedDate = (inputDate) => {
	// Convert the input date string to a JavaScript Date object
	const date = new Date(inputDate);

	// Format the date as "MMM dd, yyyy"
	const formattedDate = date.toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});

	return formattedDate;
};

// dd-mm-yyyy

export const FormattedDate2 = (inputData) => {
	const inputDate = new Date(inputData);
	const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

	const formattedDate = inputDate.toLocaleDateString('en-GB', options);

	// Replace slashes with dashes
	return formattedDate.replace(/\//g, '-');
};

// 12 hr ago
export const getTimeAgoString = (date) => {
	if (!date) {
        return date;
    }

	const currentDate = new Date();
	const timestamp = new Date(date);

	const timeDifference = currentDate - timestamp;
	const seconds = Math.floor(timeDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30.44); // Approximate number of days in a month
	const years = Math.floor(days / 365);

	if (seconds < 60) {
		return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
	} else if (minutes < 60) {
		return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
	} else if (hours < 24) {
		return `${hours} hour${hours === 1 ? '' : 's'} ago`;
	} else if (days < 7) {
		return `${days} day${days === 1 ? '' : 's'} ago`;
	} else if (weeks < 4) {
		return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
	} else if (months < 12) {
		return `${months} month${months === 1 ? '' : 's'} ago`;
	} else {
		return `${years} year${years === 1 ? '' : 's'} ago`;
	}
};

const { object, string, number } = require("zod");

export const loginSchema = object({
  username: string().refine((value) => value, {
    message: "Username should not be an empty field",
  }),
  password: string().refine((value) => value, {
    message: "password should not be an empty field",
  }),
});

export const registerSchema = object({
  Name: string().refine((value) => value, {
    message: "Username should not be an empty field",
  }),
  Mobile: string().refine((value) => value.trim().length > 0, {
    message: "Phone number should not be an empty field",
  }).refine((value) => /^\d+$/.test(value), {
    message: "Phone number should contain only numerical digits",
  }),
  Email: string().email({
    message: "Email should be a valid email!",
  }),
  Institution: string().refine((value) => value, {
    message: "Instituition should not be an empty field",
  }),
  RegistrationNumber: string().refine((value) => /\d/.test(value), {
    message: "Registration Number should not be an empty field",
  }),
  DrOrStudent: string().refine((value) => value, {
    message: "User Type should not be an empty field",
  }),
  Country: string().refine((value) => value, {
    message: "Country should not be an empty field",
  }),
  State: string().refine((value) => value, {
    message: "State should not be an empty field",
  }),
});

export const forgotPasswordSchema = object({
  email: string().email({
    message: "Email should be a valid email!",
  }),
});

export const updateProfileSchema = object({
  Name: string().refine(value => value.length > 2, {
    message: "Name should not be less than 2 Characters!",
  }),
  Mobile: string().refine((value) => /\d{10}/.test(value), {
    message: "Phone number should be a 10 digit number",
  }),
  Password: string().refine((value) => value.length > 4, {
    message: "Password should be atleast 5 characters.",
  }),
});

export const contactFormSchema = object({
  name: string().refine((value) => value.length, {
    message: "Name should be at least 1 character long.",
  }),
  email: string().email({
    message: "Email should be a valid email address.",
  }),
  message: string().refine((value) => value.length >= 10, {
    message: "Message should be at least 10 characters long.",
  }),
  purpose: string().refine(value =>  value, {
    message: 'something went wrong fromn schema'
  }),
});
